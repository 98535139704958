﻿.multi-select {
	position: relative;
	z-index: 1;
	> ul {
		display: none;
		@include navigation-list('vertical');
		position: absolute;
		top: 100%;
		min-width: 100%;
		background: $white;
		border-radius: $radius;
		color: $black;
		text-align: left;
		padding: 15px;
		margin-top: 8px;
		&.show {
			display: block;
		}
		&:before {
			content: " ";
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 5px 5px 5px;
			border-color: transparent transparent rgba($white, 0.9) transparent;
			top: -5px;
			left: 73px;
			margin: auto;
		}

		&.multiple {
			right: 0;
			width: 420px;
			@include clearfix;
			&:before {
				left: auto;
				right: 73px;
			}
			.multi-select-group {
				float: left;
				width: 50%;
			}
		}

		li {
			font-size: 12px;
			color: $dark-grey;
			p {
				font-size: 1em;
				font-weight: 500;
                color: $dark-grey !important;
				margin: 0;
				&.large {
					font-size: 1.3em;
				}
			}

			.incrementor {
				font-size: 0.6rem;
				margin-bottom: 2.5em;
			}

			.incrementor-label {
				margin-bottom: 10px;
			}

			.button-group {
				font-size: 0.8rem;
			}
		}
	}
}