button, .button {
    @include transition('color .4s ease-in-out, background .4s ease-in-out');
    display: inline-block;
    width: auto;
    height: auto;
    background: transparent;
    color: $white;
    border: 2px solid $white;
    border-radius: 5em;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.3;
    text-decoration: none;
    padding: 0.5em 1.8em;
    margin: 0.5em 0;
    outline: none;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    .no-touch &:hover {
        background: $white;
        color: $black;
    }

    @include large-up {
        font-size: 1rem;
    }

    &.black {
        color: $black;
        border-color: $black;

        &:large {
            padding: 0.55em 1.5em;
            font-size: rem-calc(18);
        }

        &:hover {
            color: $white;
            background: $black;
        }
    }

    &.left {
        float: left;
    }

    &.cta {
        position: relative;
        @include transition('color 0.2s ease-in-out, opacity 0.2s ease-in-out');
        @include gradient($green, 'linear');
        @include header-font;
        font-size: rem-calc(18);
        border: none;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.15em;
        box-shadow: 0 2px darken(nth($green, 2), 10%);
        -webkit-background-clip: border-box;
        border: none !important;
        color: white !important;
        padding: 0.55em 1.5em;
        text-shadow: 0 1px 1px rgba($black, 0.3);
        @include vendor-prefix(background-clip, padding);
        opacity: 1;


        .no-touch:not(.dynamicliteraleditmode) &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($black, 0.0);
            border-radius: 5em;
            pointer-event: none;
            @include transition(background 0.2s ease-in-out);
        }

        &.disabled {
            @include gradient(($light-grey, $grey), 'linear');
            color: $white;
            box-shadow: 0 2px darken($grey, 10%);
            opacity: 0.4;
        }

        .no-touch:not(.dynamicliteraleditmode) &:not(.disabled):hover {
            @include gradient($green, 'linear');
            color: $white;

            &:after {
                background: rgba($black, 0.2);
            }
        }
    }

    &.cta-large {
        @extend .cta;
        font-size: rem-calc(20);
        padding: 0.35em 1em;
        margin-left: em-calc(10);
        margin-right: em-calc(10);

        @include medium-only {
            font-size: rem-calc(20);
        }

        @include large-up {
            font-size: rem-calc(26);
        }
    }

    &.full {
        margin: 0;
        min-width: 100%;
        padding: 0.35em 0.7em;
        white-space: nowrap;
    }

    &.large {
        padding: 1em 1.8em;
    }

    &.small {
        font-size: 0.85em;
        font-weight: 700;
        padding: 0.4em 1.5em;
    }

    &.no-margin {
        margin: 0;
    }

    &.select {
        @include transition('color .3s ease-in-out');
        @extend .cta-large;

        &:before {
            @extend %icon-font;
            content: "";
            position: relative;
            left: -0.6em;
            top: -0.1em;
            padding-left: 0.2em;
            display: inline-block;
            font-size: 0.7em;
            background: white;
            color: black;
            width: 1.4em;
            height: 1.4em;
            text-shadow: none;
            line-height: 1.5em;
            border-radius: 3em;
            vertical-align: middle;
        }

        &.selected {
            @extend .icon-check;
        }

        &.inactive {
            @include gradient((lighten($dark-grey, 30%), lighten($dark-grey, 15%)),'linear');
            color: $light-grey;
            text-shadow: none;
            box-shadow: 0 2px $dark-grey;

            .no-touch:not(.dynamicliteraleditmode) &:not(.disabled):hover {
                @include gradient((lighten($dark-grey, 30%), lighten($dark-grey, 15%)),'linear');
            }
        }
    }

    &:focus {
        outline-color: transparent;
        outline-style: none;
    }

    &[type=submit] {
        .searchform & {
            &:before {
                content: "\E030" !important;
            }
        }
    }
}

.button-group {
    @extend .button.cta;
    display: inline-block;
    border-radius: 1em;
    padding: 0;

    @include medium-up {
        min-width: em-calc(80);
    }

    .no-touch &:after {
        display: none;
    }

    span {
        position: relative;
        top: -0.06em;
    }

    .button, button {
        padding: 0 0.5em;
        margin: 0.25em 0px;
        border: 0;
        border-radius: 0;
        @include header-font;
        font-size: 1.7em;
        line-height: 0.7em;
        font-weight: 600;
        text-shadow: 1px 1px 1px rgba($black,0.3);

        @include medium-up {
            border-left: 1px solid $white;
        }

        .no-touch &:hover {
            background: transparent;
            color: $light-grey;
        }



        &:first-child {
            border: 0;
        }
    }

    &.grey {
        @include gradient(($white, darken($booking-grey, 3%)), 'linear');
        box-shadow: 0 2px $grey;

        .no-touch:not(.dynamicliteraleditmode) &:not(.disabled):hover {
            @include gradient(($white, darken($booking-grey, 3%)), 'linear');

            &:after {
                background: rgba($black, 0.2);
            }
        }

        .button, button {
            font-weight: 300;
            text-shadow: none;
            color: $dark-grey;
            border-color: $grey;
        }

       @include small-only {
           min-width: 70px;
           button {
                padding: 0 0.35em;
           }
       }
    }
}

.btn-text {
    font-size: rem-calc(20);
    display: inline;
    padding: 0;
    position: relative;

    @include medium-only {
        font-size: rem-calc(16);
        padding: rem-calc(12);
    }
}

.cancelButton {
    font-size: 0.85rem;
    font-weight: 400;
    color: $grey;

    .no-touch &:hover {
        color: $black;
    }

    display: block;
    margin: $column-gutter/2 0;

    i {
        font-size: 0.4em;
        padding-right: 8px;
        vertical-align: middle;
    }
}

.link-with-large-button {
    font-size: rem-calc(18);
    font-size: 14px;
    //font-weight: 300;
    font-weight: inherit;

    @include large-up {
        font-size: rem-calc(24);
        font-size: 18px;
    }

    &:before {
        @extend %icon-font;
        @extend .icon-link-right;
        margin-right: rem-calc(5);
        position: relative;
        top: rem-calc(3);
    }

     &:hover {
        text-decoration:none !important;
    }
}

.link-with-small-button {
   font-size: rem-calc(16);
   //font-weight: 400;
   font-weight: inherit;


    &:before {
        @extend %icon-font;
        @extend .icon-link-right;
        margin-right: rem-calc(5);
        position: relative;
        top: rem-calc(3);
    }

     &:hover {
        text-decoration:none !important;
    }
}

.button-inc-dec {
    display: flex;
    justify-content: center;
    max-width: 140px;

    .button-group {
        @extend .button.cta;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 1.4em;
        padding: 0;

        .button, button {
            border-left: none;
            text-shadow: none;
            font-weight: 400;

            @include medium-up {
                border-left: none;
            }

            span {
                text-shadow: none;
            }
        }

        .guest-group-amount {
            height: calc(100% - 10px);
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            color: black;
            border-radius: 5px;
            padding: 0 14px;
            margin: 5px 0px 0px;
            font-size: 16px;
            line-height: 23px;
            font-weight: 300;
            font-family: 'Roboto', sans-serif;
        }
    }
}
