header {
    @include clearfix;
    width: 100%;
    position: relative;
    @include medium-only {
        .icon-arrow-down {
            display: none;
        }
    }

    > hgroup {
        @include small-only {
            display: none;
        }
    }
}

hgroup {
    padding: 6px 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 105px;
    z-index: 2;

    a {
        padding: 0 !important;
    }

    > img {
        width: 100%;
    }


    @include medium-up {
        top: 40px;
        width: 180px;
        padding: $column-gutter;
    }

    @include large-up {
        width: 239px;
        top: 60px;
    }
}

.logoheader1 {
    width: 235px;


    @include medium-up {
        width: 239px;
    }

    @include large-up {
        width: 235px;
    }
}


//fix for logo in ie
.logo-ie-fix {
    padding-top: 0px;
}
