﻿.sub-nav {
    p, li {
        text-align: left;
    }

    @include medium-up {
        text-align: left;
    }

    .close {
        float: right;
        cursor: pointer;
    }

    .vtabs-nav {
        cursor: pointer;
        background-color: $side-navigation;

        @include small-only {
            margin-top: $column-gutter;
        }

        ul {
            padding-left: $column-gutter/2;
            padding-right: $column-gutter/2;

            @include large-up {
                padding-left: $column-gutter;
                padding-right: $column-gutter;
            }
        }

        li {
            text-transform: uppercase;
            list-style: none;
            @include header-font;
            font-size: rem-calc(14);
            font-weight: 600;
            line-height: 2em;

            @include medium-only {
                font-size: rem-calc(12);
            }

            &.active {
                font-weight: 900;
            }
        }
    }

    .vtabs-wrapper {
        @include transform(translateX(-0%), 0.6s ease-in-out);

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .panel {
        text-align: left;
    }

    .vtabs-content {
        position: absolute;
        right: -100%;
        width: 100%;
        height: 100%;
        overflow: hidden;


        @include small-only {
            @include transform(translateX(-0%), 0.6s ease-in-out);
        }

        .content-element {
            position: absolute;
            color: $black;
            top: 0;
            @include transition('opacity 0.3s ease-in-out');
            display: none;
            width: 100%;
            opacity: 0;

            @include small-only {
                 position: static;
            }

            &.active {
                display: block;
            }

            &.show {
                opacity: 1;
            }

            .panel {
                padding: $column-gutter;

                &:before {
                    content: none;
                }


                @include small;
            }

            &__fullwidth {
                margin-top: 2rem;
            }
        }
    }

    .main-content {
        @include small-only {
            @include transform(translateX(-0%), '0.6s ease-in-out, opacity 0.3s ease-in-out, min-height 0.6s ease-in-out');
        }

        @include transition('opacity 0.3s ease-in-out, min-height 0.6s ease-in-out');
        min-height: 100px;
        opacity: 1;
    }

    &.open-nav {
        .vtabs-wrapper {
            @include medium-up {
                @include transform('translateX(-75%) translateX(-12px)', null);
            }
        }

        .vtabs-content {
            @include small-only {
                @include transform(translateX(-100%), null);
            }
        }

        .main-content {
            @include small-only {
                @include transform(translateX(-100%), null);
            }

            opacity: 0;
        }
    }
}
