﻿.booking-flow {
    .sub-header {
        @extend .large;
        line-height: 1.1;
        font-weight: 300;
        margin-bottom: 0.8em;
        color: $black;
        padding: 0 20px;
    }

    .choose-center {
        height: calc(100vh - 50px);
        height: calc(var(--vh, 1vh) * 100 - 50px);
        min-height: calc(100vh - 50px) !important;
        min-height: calc(var(--vh, 1vh) * 100 - 50px) !important;
        background-color: $light-booking-grey;

        @include medium-up {
            height: calc(100vh - 60px);
            height: calc(var(--vh, 1vh) * 100 - 60px);
            min-height: calc(100vh - 60px) !important;
            min-height: calc(var(--vh, 1vh) * 100 - 60px) !important;
            margin-top: 50px;
        }

        @include large-up {
            margin-top: 60px;
        }

        .row {
            height: 100%;
            max-width: none;
        }

        .column {
            height: 100%;
            padding: 0 !important;
            position: relative;
        }

        .lalandia-logo {
            position: absolute;
            right: 20px;

            @include large-up {
                right: 40px;
            }

            img {
                width: 100px;

                @include large-up {
                    width: 120px;
                }
            }
        }

        .sub-header {
            margin-bottom: 1em;
        }

        .center {
            #map-container {
                /* IE10+ */
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    width: 100%;
                    height: 55vh;
                }
            }

            #map-container svg {
                max-height: calc(60vh - 100px);

                @include medium-up {
                    max-width: 80vw !important;
                    max-height: 55vh !important;
                    width: 100%;
                    height: 100%;
                }

                #map path {
                    fill: $white;
                }
            }
        }

        .center-select {
            font-size: 0;
            height: 160px;
            width: 100%;

            @include medium-up {
                height: 120px;
            }

            button {
                @include gradient((lighten($dark-grey, 30%), lighten($dark-grey, 15%)),'linear');
                font-size: 20px;
                padding-left: 40px;

                &.button--sondervig {
                    width: auto;
                }

                @include small-only {
                    font-size: 16px;
                    width: 143px;
                }

                @include medium-up {
                    width: 165px;

                    &.button--sondervig {
                        width: auto;
                    }
                }

                &:nth-child(1) {
                    margin-left: 0;
                    margin-top: 30px;

                    @include small-only {
                        margin-right: 0;
                        margin-top: 22px;
                    }

                    @media only screen and (max-width: 700px) and (orientation: landscape) {
                        margin-top: 0px;
                        margin-bottom: 0;
                    }
                }

                &:nth-child(2) {
                    margin-bottom: 30px;
                    margin-right: 0;
                }

                &.select {
                    &:before {
                        position: absolute;
                        left: 10px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }

            button.selected {
                @include gradient($green, 'linear');
            }

            @include small-only {
                width: calc(100% - 20px);

                button {
                    width: calc(100% - 60px) !important;
                    margin-left: 0 !important;
                    margin-bottom: 10px !important;
                    margin-right: 0 !important;
                    padding: 0.6em 1.5em 0.5em;

                    &:before {
                        font-size: 0.9em;
                    }
                }
            }
        }
    }
}
