﻿
.mainslider {
	position: relative;
	overflow: auto;
	width: 80%;
	margin: 1em auto;
}
.mainjs .mainslider {
	overflow: hidden;
}
.mainslider > ul {
	position: relative;
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 0;
	word-spacing: -.3em;
	white-space: nowrap;
}

.mainslider > ul > li {
	position: relative;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	vertical-align: top;
	font-size: 14px;
	word-spacing: 0;
	white-space: normal;
}

.mainslider > ul > .next:first-child {
	left: 100%;
}
.mainslider > ul > .prev.last-child {
	right: 100%;
}
.mainslider > ul > .target:first-child,
.mainslider > ul > .target.last-child {
	left: 0;
	right: 0;
}

.mainslider > ul > li > img {
	display: block;
	width: 100%;
}

.slider .nav {
	z-index: 1;
	background: #fff;
	text-align: center;
	transition: opacity 2s;
	opacity: 0;
}
.slider.ready .nav {
	opacity: 1;
}
.slider .nav a {
	display: inline-block;
	margin: 1em .5em;
	padding: 0;
	border: none;
	width: 2em;
	line-height: 2em;
	background: #999;
	color: #fff;
	text-decoration: none;
	text-align: center;
	border-radius: 999px;
	cursor: pointer;
	-webkit-transition: all .2s;
	transition: all .2s;
}
.slider .nav a:hover {
	background: #666;
}
.slider .nav a.active {
	background: #000;
}

/* position nav items above slideshow with arrows on left/right side */
.slider .nav > span {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}
.slider .nav > a {
  position: absolute;
  top: 50%;
  margin-top: -21px;
  font-size: 1.5em;
}
.slider .nav > .back {
  left: 20px;
}
.slider .nav > .forth {
  right: 20px;
}