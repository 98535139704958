﻿$orange: (#D4892E, #D43D08);
$light-orange: (#FFC608, #F0892E);
$yellow: (#FFE24A, #FFBD0F);
$purple: (#A53CCB, #7A1C9C);
$blue: (#3C99D8, #006EB9);
$dark-blue: (#5748D8, #0F1876);
$turkis: (#3CC3D5, #028C98);
$green: (#B9DF23, #76A70C);
$dark-green: (#239B23, #185C36);
$black-gradiant: (#575757, #202020 );
$grey-gradient: (#AAAAAA, #676767);
$coffee: (#89452A, #3E272E);
$relax: (#F0DCD1, #C5AC7D);
$information: (#bdd4e4, #89a5b9);

$light-gradient: (#ffffff, #f5f5f5);
$medium-gradient: (#f5f5f5, #d2d2d2);
$dark-gradient: (#afafaf, #5c5c5c);

$black: #202020;
$light-green: #bdcf84;
$white: #ffffff;
$light-blue: #b6cdea;
$bg-grey: #f0f0f0;
$light-grey: #d2d2d2;
$lightest-grey: #eff1f3;
$light-booking-grey: #ececec;
$grey: #919191;
$dark-grey: #363636;
$tab-grey: #616161;
$bullet-green: #a3cd1b;
$shadow-orange: #f6a000;
$brown: #b37d03;
$yellow_hero: #ffc014;
$info-text: #768fa1;

$youtube: (#ed413b, #ce2120);
$newsletter: (#b1ddfd, #71a6cd);
$facebook: (#6c82b4, #3e5c99);
$plus45: #767676;

$nav-lightgrey: #f4f4f4;
$nav-grey: #e5e5e5;
$nav-darkgrey: #777777;
$nav-font: #5c5c5c;
$nav-blue: blue;

$side-navigation: #002137;
$aktivitetskalender: #4a4b4b;

$ak_orange:#963e15;
$ak_purple:#501266;
$ak_turkis:#05626a;
$ak_blue:#074a78;
$ak_green:#537607;
$ak_yellow:#c18d0c;
$ak_grey:#363636;

$bg_orange: #D45B17;
$bg_light-orange: #F5A01F;
$bg_blue: #167ec4;
$bg_dark-blue: #2E2DA0;
$bg_green: #8DBA14;
$bg_dark-green: #1C7230;
$bg_purple: #8B28AE;
$bg_turkis: #19A1B0;
$bg_yellow: #FFCC27;
$bg_black: #343434;
$bg_grey: #808080;
$bg_coffee: #65372c;
$bg_relax: #D6BF9E;


$bg-page-black: #000000;
$bg-page-aubergine: #311C1A;
$bg-page-grey: #DADADA;
$bg-page-winter: #98A3B7;
$bg-page-pool: #97D3EC;
$bg-page-sky: #DDF1F9;
$bg-page-melon: #FDF1D2;

$booking-grey: #f0f0f0;
$booking-light-grey: #f5f5f5;
$booking-light-disable: #f9f9f9;
$booking-grey-disable: #dddddd;

$bookingflow-new-btn-grey-gradient: linear-gradient(180deg, #F9F9F9 0%, #DDDDDD 99%);
$bookingflow-new-btn-orange-gradient: linear-gradient(180deg, #FFC608 0%, #F0892E 100%);
