﻿
.special-offer-block {
    @include medium-up {
        max-width: none;
    }
    .slider-inner {

        @include medium-up {
            width: 100%;
            overflow: hidden;
        }
    }

    .slider {
        margin-bottom: 25px;

        li {
            margin: 0 10px;
            height: auto;

            @include medium-up {
                margin: 0;
                opacity: 0 !important;
            }

            &.active {
                opacity: 1 !important;
            }
        }
    }


    .bullet-wrapper {
        z-index: 2;

        @include small-only {
            display: none;
        }
    }
}

.specialoffer {
    padding-top: rem-calc(40);

    @include medium-up {
        padding-bottom: rem-calc(80);
    }

    @include large-up {
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
        padding-bottom: rem-calc(-50);
    }

    @include xlarge-up {
        padding-bottom: rem-calc(-70);
    }

    ul {
        list-style-type: none;
        padding: 0;
    }
}

.specialoffer-desc {
    background: rgba($black, 0.4);
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(20);
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    margin-top: rem-calc(80);
    margin-bottom: rem-calc(80);
    overflow: visible;
}

.specialoffer-icon {
    background: transparent;
    color: $white;
    position: absolute;
    left: rem-calc(40);
}

.specialoffer-text {
    padding-bottom: rem-calc(10);

    p {
        @extend .manchet;
    }
}

.special-offer-inner {
    &.panel {
        max-width: 550px;
        margin: 0 auto !important;
        padding: 0 !important;
        height: 100%;

        @include xlarge-up {
            max-width: 630px;
            min-height: 340px;
        }
    }

    &:before {
        content: none;
    }
}

//TODO: ADD -webkit prefix for IOS 10 flexbox fix
.specialoffer-panel {
    padding-bottom: 0;
    padding: 45px 20px !important;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ios-10 &,
    .ios-9 & {
        display: -webkit-flex;
        position: relative !important;
    }

    @include small-only {
        justify-content: center !important;

        @media (min-width: 375px) {
            padding-right: rem-calc(30) !important;
            padding-left: rem-calc(30) !important;
        }

        @media screen and (orientation : landscape) {
            padding-bottom: 32.5px !important;
        }
    }

    @include medium-up {
        padding-right: rem-calc(40) !important;
        padding-left: rem-calc(40) !important;
    }


    @include large-up {
        padding-right: rem-calc(50) !important;
        padding-left: rem-calc(50) !important;
    }

    @include xlarge-up {
        padding-right: rem-calc(60) !important;
        padding-left: rem-calc(60) !important;
    }

    .thumbs-up {
        top: -25px;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);

        .ios-9 &,
        .ios-10 & {
            height: 50px;
        }

        @include medium-up {
            top: auto;
            bottom: 7%;
            left: auto;
            right: 2%;
            transform: none;
        }

        img {
            right: 0;
            bottom: 0;
            transform: none;

            @include medium-up {
                width: 75px;
                height: 75px
            }

            @include xlarge-up {
                width: 100px;
                height: 100px
            }
        }
    }

    &.panel-content {
        h3 {
            font-size: 30px;
            line-height: 1;
            word-break: break-word;
            margin: 0;
            margin-bottom: rem-calc(10);

            @include small-only {
                @media (max-width: 374px) {
                    font-size: 35px;
                }

                @media screen and (orientation : landscape) {
                    margin-bottom: rem-calc(5);
                    font-size: 35px;
                }
            }

            @include medium-up {
                font-size: 50px;
            }

            @include xxlarge-up {
                font-size: 55px;
            }

            &.minimize-header {
                font-size: 24px;
                line-height: 1.1;

                @include medium-up {
                    font-size: 40px;
                }
            }
        }

        p {
            @extend .manchet;
            font-weight: 400;
            margin-bottom: rem-calc(5);

            @include medium-up {
                margin-bottom: rem-calc(10);
            }

            .panel.yellow & {
                color: #E87F35 !important;
            }

            .panel.black &,
            .panel.purple &,
            .panel.green &,
            .panel.blue &,
            .panel.turkis &,
            .panel.orange &,
            .panel.relax & {
                color: $white !important;
            }
        }

        .offer__priceText {
            @extend .manchet;
            font-weight: 700;
        }

        .offer__price {
            @include small-only {
                font-size: rem-calc(30) !important;
            }
        }

        .legal-text p {
            font-size: 10px;
            line-height: 1.1;
            margin-top: 10px;

            @include medium-up {
                margin-bottom: 0;
            }
        }

        i {
            padding-right: rem-calc(10);
            margin-top: 2px;
        }

        .button {
            position: absolute;
            bottom: -25px;
            align-self: center;

            .single-offer-block &,
            .multi-offer-block & {

                @include medium-up {
                    position: relative;
                    bottom: 0;
                }
            }

            .ios-9 &,
            .ios-10 & {
                position: relative;
                display: block;
                margin: 0 auto;
                margin-bottom: -35px;
            }
        }
    }
}
.special-offer__image-circle {
    display: none;
    width: 175px;
    height: 175px;
    position: absolute;
    top: 25%;
    right: -20%;
    border: 5px solid $white;
    border-radius: 50%;
    background-color: $black;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;

    @include medium-up {
        display: block;
    }


    @include large-up {
        width: 200px;
        height: 200px;
        top: 20%;
        right: -140px;
    }

    @include xlarge-up {
        width: 220px;
        height: 220px;
        right: -150px;
    }
}

.special-offer__all-offers-link {
    z-index: 2;

    @include small-only {
        margin-bottom: rem-calc(10);

        @media screen and (orientation : landscape) {
            position: absolute;
            bottom: -15%;
            right: 10%;
        }
    }

    @include medium-up {
        position: absolute;
        bottom: 10px;
        right: calc(50% - 250px);
    }

    a {
        @extend .manchet;

        &:before {
            font-size: 18px;
            margin-right: 10px;
        }
    }
}

.corners {
    .rounded {
        @include small-only {
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            -o-border-radius: 0px;
            border-radius: 0px;
            border: 0px;
        }
    }
}

.specialoffer-accordion {

    @include medium-up {
        position: absolute;
        width: 100% !important;
        top: -90px;
        left: 51%;
        transform: translate(-50%, 0%);
        z-index: 1;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }
}

.specialoffer-banner-left {
    padding: 0;
    margin: 0;

    .special-offer-block & {
        height: 100%;
    }
}

//TODO: ADD -webkit prefix for IOS 10 flexbox fix
.special-offer-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 300px;
    height: 100%;

    .ios-10 &,
    .ios-9 & {
        display: block;
    }

    @include medium-up {
        min-height: 300px;
        max-height: none;
    }

    @include xxlarge-up {
        min-height: 340px;
    }
}

.specialoffer-banner-right {
    padding: 0;
    margin: 0;
    background-repeat: no-repeat !important;
    height: 200px;
    background-position: center center !important;

    .temp-bg {
        background-repeat: no-repeat !important;
        background-position: center center !important;
    }

    @include large-up {
        background-size: auto 100% !important;
        background-repeat: no-repeat !important;
        background-position: right center !important;
        height: 465px;

        .temp-bg {
            background-size: auto 100% !important;
            background-repeat: no-repeat !important;
            background-position: right center !important;
        }
    }
}

.acc-foldout {
    background: $white;
    width: 100%;
    margin: -80px 0 0;
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    padding-bottom: rem-calc(40);
    padding-top: rem-calc(40);
    overflow: hidden;
    z-index: -1;

    @include medium-up {
        padding-top: rem-calc(150);
        padding-bottom: rem-calc(40);
        margin-top: -95px;
    }

    @include large-up {
        padding-top: rem-calc(150);
        padding-bottom: rem-calc(40);
        margin-top: -95px;
    }

    @include xlarge-up {
        padding-top: rem-calc(150);
        padding-bottom: rem-calc(40);
        margin-top: -95px;
    }
}

.specialoffer-title {

    @include large-up {
        padding-left: rem-calc(30);
        padding-right: rem-calc(30);
    }
}

.specialoffer-icon-big {
    width: 80px;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.specialoffer-icon-small {
    font-size: rem-calc(10);
    height: rem-calc(30);
}

.specialoffer-icon-middle {
    width: 80px;
}

.specialoffer-head-left {
    padding-left: 0;

    @include large-up {
        padding-left: rem-calc(80px);
    }
}

.specialoffer-head-right {
    padding-right: 0;

    @include large-up {
        padding-right: rem-calc(80px);
    }
}

/*Accordian*/

.specialoffer-accordion-column {
    position: static;
    margin-top: rem-calc(-100);
}



.specialoffer-accordion-action {
    padding-top: rem-calc(40);
    position: relative;

    @include small-only {
        padding: 0;
        margin: 0;
    }

    &:last-of-type {
        border-bottom: 0;
    }

    .specialoffer-accordion-head {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        z-index: 10;
        padding-top: 0.7em;
        padding-bottom: 0.7em;
        font-size: rem-calc(20);

        @include small-only {
            padding-top: 0;
            padding-bottom: 0;
        }

        @include medium-up {
            padding-top: rem-calc(30);
        }

        @include xlarge-up {
            padding-top: 0.7em;
        }




        h3 {
            font-size: rem-calc(20);
            margin-top: rem-calc(-12);

            @include medium-up {
                font-size: rem-calc(22);
            }

            @include xlarge-up {
                font-size: rem-calc(30);
            }
        }
    }

    .last {
        border: none;
    }

    .specialoffer-accordion-body {
        height: auto;
        display: none;
        position: absolute;
        left: 0;


        @include small-only {
            position: static;
            overflow: hidden;
        }
    }
}

.specialoffer-accordion-title {
    padding-left: rem-calc(35);
    padding-right: rem-calc(35);
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(20);
    background: $white;
}

.specialoffer-accordion-text {
    margin: 0;
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
}

.specialoffer-link-text{
    @include prim-font;
    font-size: rem-calc(20) !important;

}

.specialoffer-arrow {
    @extend %icon-font;
    @extend .icon-link-right;
    margin-top: 20px;


    @include small-only {
        float: right;
        margin-top: rem-calc(-30);
    }

    .open & {
        @extend .icon-close;

        &:after {
            content: "Luk";
            @include prim-font;
            font-size: rem-calc(20);

            @include small-only {
                content: " ";
            }
        }
    }

    &:before {
        color: $white !important;
    }


}

.opening_hours {
    background: rgba($black, 0.6);
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    margin-top: rem-calc(20) !important;


    @include medium-up {
        padding: rem-calc(30) rem-calc(20);
    }
}

.opening_hours-text {
    p {
        @include small-only {
            padding-top: rem-calc(5);
        }
    }
}

.opening_hours_column_space {
    @include small-only {
        margin-bottom: rem-calc(10);
    }
}

.oh-accordion-head {
    @include small-only {
        padding: 0 !important;
    }
}

.opening_hours_wrapper {
    padding-bottom: rem-calc(40);
}

.opening_hours_left {
    p {
        float: left;
    }
}

.opening_hours_right {
    p {
        float: right;
    }
}

.opening_hours_column_wrapper {
    padding-bottom: rem-calc(20);
}

.opening_hours_column {
    padding-left: 0 !important;
}

.opening_hours_title {
    padding-bottom: rem-calc(30);
}

.opening_hours_column_title {
    padding-bottom: rem-calc(20);
}

.opening_hours_bg {
    background: rgba($black, 0.6);
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    width: 100%;

    @include small-only {
        overflow: hidden;
        margin-top: 0;
    }
}

.openinghours-block {
    .row {
        @include large-up {
            padding-top: rem-calc(20) !important;
            padding-bottom: rem-calc(0) !important;
        }
    }

    p {
        @include small-only {
            font-size: 12px;
        }
    }


    .openinghours-block-border {
        border-top: solid 1px $white;
        margin-top: rem-calc(20);

        &.dark {
            border-top: solid 1px $black;
        }
    }
}

.oh_right {
    padding-right: 0;
}

.oh_rules {
    padding-top: rem-calc(40);
}



.offer_link {
    li {
        float: left;
        margin-right: rem-calc(20);
        list-style-type: none;
    }
}

.offer_open {
    & .icon {
    }
}

.offer_content {

    & > div {
        display: none;
        float: none;

        &:before {
            content: '\a';
            white-space: pre;
        }

        &.selected {
            display: block;
        }
    }
}

.calendar-offer {
    height: rem-calc(465);
}

.specialofferSection {
    position: relative;

    .hero-content-wrapper {
        .rich-text-link p {
            @extend .manchet;
        }
    }
    .tool-tip {
        display: block;
        position: absolute;
        opacity: 1;
    }
    #tabs_container {
        border-radius: 8px;
        background: rgb(0,110,185);
        background: linear-gradient(0deg, rgba(0,110,185,1) 0%, rgba(60,153,216,1) 100%);

        .teaser-wrapper__headline {
            display: none;
        }

        @media only screen and (max-width: 1024px) {
            > h3 {
                padding-top: 15px;
                font-family: 'roboto', sans-serif;
                font-weight: 500;
                text-transform: unset;
            }
            a {
                h3 {
                    font-family: 'roboto', sans-serif;
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 500;
                }
            }
        }
    }
    .teaser-wrapper__headline {
        display: block;

    }
    .specialofferSection__offers {
        display: flex;
    }
    .specialofferSection__offer {
        position: relative;
        width: 33.3%;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: white;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        a {
            display: block;
            padding: 25px 15px 23px;

            @include small-only {
                padding: 5px 5px 15px;
            }
        }
        h3 {
            margin-bottom: 25px;
            font-size: 24px;
            line-height: 26px;

            @include small-only {
                margin-bottom: 0;
            }
        }
        p {
            &.specialoffer-link-text {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 19px !important;
                font-weight: 300;

                @include small-only {
                    margin-top: 5px;
                }

                .arrow-icon {
                    margin: 5px 5px 0 0;
                }
            }
            .specialoffer-arrow {
                margin: 2px 5px 0 0;
            }
        }
    }
}

.opening-hours-events {
    .small-next,
    .small-prev {
        top: 1px;

        i {
            padding: 10px;
            font-size: 10px;
        }
    }
    .oh_date {
        clear: both;
    }

    .opening_hours_title p {
        text-align: center;
    }
    .opening_hours_column_title {
        height: 60px;
        line-height: 30px;
        padding-top: 3px;

        p {
            text-align: center;
            font-weight: 400;
        }
    }
    .opening_hours_column_wrapper {
        padding-right: 0 !important;

        .opening_hours_column {
            width: 66.666667%;

            &.opening_hours_right {
                width: 33.333333%;
                padding-right: 0;
            }
        }
    }

    @include large-up {
        .small-next,
        .small-prev {
            display: none;
        }

        .opening_hours_column_title {
            height: auto;
            padding-top: 0;

            p {
                text-align: left;
                font-weight: 700;
            }
        }
        .opening_hours_title p {
            text-align: left;
        }
        .opening_hours_column_wrapper {
            padding-right: 0.9375rem !important;

            .opening_hours_column {
                padding-right: 0.9375rem !important;
            }
        }
        .slider-inner {
            position: inherit;
        }
        .slider {
            display: block;
            overflow: visible;
            height: auto;
            padding: 0;
            background: transparent;
            width: auto !important;
            transform: translateX(0) !important;

            li {
                transition: none;
                width: 33.333333%;
                opacity: 1;
            }
        }
    }
}