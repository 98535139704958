﻿ .information-menu {
    margin-top: -100px;
    position: relative;

    @include small-only {
        @media screen and (orientation : landscape) {
        }
    }

    @include medium-up {
        margin-top: -75px;
    }

    @include large-up {
        padding: 0;
    }

    @include xxlarge-up {
        margin-top: -100px;
    }

    > .row {
        padding-top: 0;

        > .columns {
            padding: 0;
        }
    }
}

.information-menu__wrapper {
    background-color: $white;
    color: $black;
    padding: 20px 25px;
    text-align: left;
    min-height: 150px;


    @include medium-up {
        padding: 30px 40px;
    }
    .has-modal & {
        overflow: visible;
    }

    .openinghours-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        i {
            margin-right: 6px;
        }

        p {
            margin-top: 0;
            @extend .manchet;
            font-weight: 400;
        }
    }
}

.information-menu__content {
    p {
        @extend .manchet;
    }
}

.information-menu__list-wrapper {
    margin-top: 20px;
    padding: 15px 20px;
    border: 2px solid $black;

    @include medium-up {
        margin-top: 0;
    }
}

.information-menu__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.information-menu__list-header {
    @extend .manchet;
    font-weight: 600;
}

.information-menu__list-item {
    position: relative;
    margin-bottom: 10px;
    padding-right: 15px;
    cursor: pointer;
    @extend .manchet;

    &:last-child {
        margin-bottom: 0;
    }

    &:after {
        content: "+";
        display: block;
        position: absolute;
        right: 0;
        top: 3px;
        font-size: 20px;
        line-height: 14px;
    }

    &:hover {
        a {
            text-decoration: underline;
        }
    }

    a {
        color: inherit;
    }

    &--more {
        @extend .information-menu__list-item;
        cursor: pointer;

        &:after {
            content: none;
        }

        &:hover {
            text-decoration: underline;
        }

        i {
            position: absolute;
            top: 5px;
            right: 0;
            font-size: 10px;
        }
    }
}

.information-menu__modal {
    display: none;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($black, 0.95);
    color: $white;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    opacity: 0;
    transition: opacity 0.3s ease-in;

    .close {
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;

        &:after {
            content: "+";
            display: inline-block;
            font-size: 30px;
            transform: rotate(45deg);
            margin-left: 10px;
            vertical-align: sub;
        }

        @include medium-up {
            top: 0px;
            right: 45px;
        }
    }

    .info-modal-open & {
        display: block;
        z-index: 10;
    }

    &.open-modal {
        opacity: 1;
    }
}

.information-menu__modal__content {
    position: relative;
    max-width: 771px;
    margin: auto;
    padding: 30px;
    padding-bottom: 85px;

    @media screen and (orientation: portrait) and (min-device-height: 750px) {
        padding-bottom: 130px;
    }


    @include medium-up {
        margin-top: 10%;
    }
}

.information-menu__modal__header {
    text-align: center;
    max-width: 90%;
    margin: 30px auto;
}

.information-menu__modal__list {
    list-style: none;
    padding: 0;
}

.information-menu__modal__item {
    position: relative;
    transition: all 0.2s ease-in;

    &.active-item {
        margin-left: -30px;
        margin-right: -30px;
        padding: 0 30px;
        background-color: $white;
        color: $black;

        @include medium-up {
            margin-left: 0;
            margin-right: 0;
            padding: 0;
        }

        a {
            color: inherit;
        }
    }
}

.information-menu__modal__item-close {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 10px;
    top: 20px;
    transform: rotate(0);
    transition: transform 0.3s ease-in;
    cursor: pointer;

    @include medium-up {
        right: 20px;
    }

    &:after {
        content: "+";
        display: block;
        font-size: 24px;
        line-height: 14px
    }

    .active-item & {
        transform: rotate(45deg);
        right: 40px;

        @include medium-up {
            width: 20px;
            height: 20px;
            right: 15px;

            &:after {
                font-size: 30px;
                line-height: 20px;
            }
        }
    }
}

.information-menu__modal__item-header {
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    padding: 15px 10px;
    cursor: pointer;
    border-bottom: 1px solid #646464;

    @include medium-up {
        padding: 15px 20px;
    }

    .active-item & {
        border-bottom: 1px solid rgba(100,100,100,0.2);
    }
}

.information-menu__modal__item-content {
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in;
    opacity: 0;

    > div {
        padding: 15px 10px 30px;

        @include medium-up {
            padding: 25px 20px;
        }
    }

    &.show {
        height: auto;
        opacity: 1;

        @include medium-up {
            margin-bottom: 0;
        }
    }

    p {
        @extend .manchet;
    }

    .link-with-small-button {
        font-size: inherit;
        font-weight: inherit;

        &:before {
            @extend .icon-link-right;
            //content: "\E007";
        }
    }
}

.info-modal-open {
    overflow-y: hidden;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    .content-container {
        z-index: 11;
    }
}
