.hero {
    display: block;
    width: 100%;
    height: 65vh;
    position: relative;
    background-size: cover;
    background-position: center center;

    @media screen and (orientation : landscape) {
        height: 75vh;
    }

    @include medium-up {
        height: 75vh;
        max-height: 710px;

        @media screen and (orientation : portrait) {
            height: 50vh;
            max-height: 400px;
        }
    }

    .frontpage & {
        min-height: 490px;

        @include small-only {
            @media screen and (orientation : landscape) {
                height: auto;
                padding-bottom: 60px;
            }
        }

        @include medium-up {
            height: 650px;
            max-height: none;
        }

        @include xlarge-up {
            height: 700px;
        }

        @include xxlarge-up {
            height: 850px;
        }
    }

    .temp-bg {
        background-size: cover;
        background-position: center center;
    }

    > .display {
        display: block;
        color: $white;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 1em;
    }

    .image-wrapper {
        position: relative;
        height: 100%;
        overflow: hidden;

        &.hero__background-image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        video {
            max-width: none;
        }
    }


    > .panel {
        &.special-offer-block {
            margin: 0 auto;

            @include small-only {
                position: absolute;
                top: calc(50% + 30px);
                left: 50%;
                transform: translateX(-50%) translateY(-50%);

                @media screen and (orientation : landscape) {
                    position: relative;
                    top: auto;
                    left: auto;
                    transform: none;
                    height: auto;
                    padding-top: 95px !important;
                }
            }


            @include medium-up {
                width: 100%;
                margin-bottom: 15px !important;
                padding-top: 100px !important;
            }

            @include xxlarge-up {
                margin-bottom: 10px !important;
                padding-top: 180px !important;
            }
        }
    }

    .header-booking {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 90vw;
        max-width: 1100px;
        position: relative;
        margin: 0 auto;
        text-align: center;


        @include small-only {
            display: none;
        }

        @include xlarge-up {
            width: 75vw;
        }

        .multi-select > ul,
        .selectricScroll {
            box-shadow: 0px 1px 4px 1px rgba(#202020, 0.3);
        }
    }
}


.hero-block-mini {
    position: relative;
}

.hero-block-bg {
    position: relative;

    h2 {
        text-shadow: 0px 0px 40px rgba($black,0.7);
    }

    p {
        text-shadow: 0px 0px 25px rgba($black,0.7);
    }
}

.video-btn {
    font-size: rem-calc(80);

    @include medium-up {
        font-size: rem-calc(130);
    }
}

.video-wrapper {
    padding-top: 0 !important;
}

.hero-wrapper {
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);
}

.herologo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: rem-calc(20);
}



.block-on-top {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: visible !important;
}

.info-arrow-hero {
    @extend %icon-font;
    @extend .icon-link-right;

    .open & {
        @extend .icon-close;
    }

    &:before {
        font-size: rem-calc(30);
        margin-right: rem-calc(10);
        display: block;
        float: left;
    }
}

.hero-content-wrapper {
    padding-bottom: rem-calc(40);
    padding-left: rem-calc(30);
    padding-right: rem-calc(30);

    @include large-up {
        padding-left: 0;
        padding-right: 0;
    }

    h2 {
        padding-top: rem-calc(40);
    }
}

.hero-head:nth-child(2) {
    @include small-only {
        padding-top: rem-calc(30);
    }
}

.hero-block2 {
    padding-top: 40px;
    margin-bottom: -75px;
    padding-bottom: 70px;
    margin-top: rem-calc(50) !important;

    ul {
        list-style-type: none;
    }
}


.hero-blur {
}

.hero-blur:before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    padding: 40px;
    border-radius: $radius;
}

.blur25 {
    &:before {
        background: rgba(0,0,0, 0.25);
    }
}

.blur50 {
    &:before {
        background: rgba(0,0,0, 0.5);
    }
}

.blur75 {
    &:before {
        background: rgba(0,0,0, 0.75);
    }
}

.hero-blur-text {
    position: relative;
    z-index: 2;
}

.hero-bottom-space {
    padding-bottom: rem-calc(30) !important;
}
