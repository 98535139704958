﻿img, video, iframe, svg {
	&.loaded {
		@include transition('opacity .6s ease-in-out');
		opacity: 0;

		&.show {
			opacity: 1;
		}
	}
}

[data-background] {
	position: relative;
	z-index: 0;
	.temp-bg {
		@include transition('opacity .6s ease-in-out');
		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom : 0;
		left: 0;
		z-index: -1;
		&.show {
			opacity: 1;
		}
	}
}

.img-bg, .temp-bg {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	&.focus-TopLeft {
		background-position: top left !important;
	}

	&.focus-TopCenter {
		background-position: top center !important;
	}

	&.focus-TopRight {
		background-position: top right !important;
	}

	&.focus-MiddleLeft {
		background-position: center left !important;
	}

	&.focus-MiddleCenter {
		background-position: center center !important;
	}

	&.focus-MiddleRight {
		background-position: center right !important;
	}

	&.focus-BottomLeft {
		background-position: bottom left !important;
	}

	&.focus-BottomCenter {
		background-position: bottom center !important;
	}

	&.focus-BottomRight {
		background-position: bottom right !important;
	}

	.img-overlay {
		@include transition(background 0.4s ease-in-out);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: rem-calc(350);
		background: rgba(0, 0, 0, 0.6);

		.no-touch &:hover {
			background: rgba(0, 0, 0, 0.3);
		}

		p {
			margin-top: 0;
		}

		h3 {
			margin: 0;
			padding: 0;
		}
	}
}
