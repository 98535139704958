header + .center-select {
    position: relative;

    @include medium-up {
        margin-top: -120px;
    }
    @media only screen and (max-width: 768px) {
        margin-top: -100px;
    }
    @media only screen and (max-width: 698px) {
        margin-top: -80px;
    }
}

.center-select {
    &.specialofferSection {
        margin-top: 0;
    }

    .center-select__wrapper {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        width: 100%;
        background: rgb(0,110,185);
        background: linear-gradient(0deg, rgba(0,110,185,1) 0%, rgba(60,153,216,1) 100%);
        border-radius: 8px;

        &--mobile {
            display: none;
        }
        .center-select__mobile-header {
            display: none;
        }
        svg {
            g {
                transition: opacity 0.25s;
            }
        }

        &.billund {
            svg {
                g.pin-billund-g--selected {
                    opacity: 1;
                }
            }
        }
        &.rodby {
            svg {
                g.pin-rodby-g--selected {
                    opacity: 1;
                }
            }
        }
        &.sondervig {
            svg {
                g.pin-sondervig-g--selected {
                    opacity: 1;
                }
            }
        }
    }

    .center-select__item {
        position: relative;
        display: flex;
        flex-basis: 0;
        flex: 1 1 0px;
        max-width: 294px;
        padding: 50px 36px;
        text-align: left;

         &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 1px;
            background-color: white;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }

        .link-with-large-button {
            display: none;
        }

        &.center-select__item--map {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0 10px;
            max-width: none;
            text-align: center;

            .map-pin {
                position: absolute;
                top: 50%;
                left: 50%;
            }
            .pin-rodby-con {
                transform: translate(23px, 52px);
            }
            .pin-billund-con {
                transform: translate(-77px, -33px);
            }
            .pin-sondervig-con {
                transform: translate(-122px, -66px);
            }

            @media only screen and (max-width: 1104px) {
                display: none;
            }
        }

        @media only screen and (max-width: 1104px) {
            max-width: none;

            svg {
                display: none;
            }
        }
        @media only screen and (max-width: 768px) {
            justify-content: center;
            padding: 5px 10px;

            .link-with-large-button {
                display: block;
                margin: auto;

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
            p,
            .center-select__item-cta {
                display: none;
            }
            .center-select__item-content {
                position: relative;

                h2 {
                    font-family: 'Roboto', sans-serif;
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 500;
                    text-transform: unset;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                }
            }
        }
    }

    .center-select__item-content {
        display: flex;
        flex-direction: column;

        h2 {
            position: relative;
            font-size: 24px;
            line-height: 26px;
            max-width: 222px;
            padding-right: 50px;

            .pin {
                display: inline-block;
                position: absolute;
                right: 0;
                top: -8px;
            }
        }
        p {
            font-size: 14px;
            line-height: 21px;
            max-width: 222px;
            margin-bottom: 1em;
        }
    }

    .center-select__item-cta {
        margin-top: auto;

        .button.large {
            font-size: 14px;
            line-height: 22px;
            padding: 0.5em 2.2em;
        }
    }
    .dk-map {
        width: 100%;
        margin: 0;
        pointer-events: all;

        svg {
            position: relative;
        }
    }

    @media only screen and (max-width: 1080px) {

    }
    @media only screen and (max-width: 768px) {
        margin-left: 10px;
        margin-right: 10px;

        .center-select__wrapper {
            flex-wrap: wrap;
            padding: 10px 0 5px;

            .center-select__mobile-header {
                display: block;
                width: 100%;
                font-family: 'Roboto';
                font-size: 19px;
                line-height: 27px;
                text-transform: unset;
            }
        }
    }
}
