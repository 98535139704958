
@font-face {
    font-family: "Raleway";
    src: url('/css/fonts/raleway-thin.eot');
    src: url('/css/fonts/raleway-thin.eot?#iefix') format('embedded-opentype'), url('/css/fonts/raleway-thin.woff') format('woff'), url('/css/fonts/raleway-thin.ttf') format('truetype'), url('/css/fonts/raleway-thin.svg#ralewaythin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Raleway";
    src: url('/css/fonts/raleway-light.eot');
    src: url('/css/fonts/raleway-light.eot?#iefix') format('embedded-opentype'), url('/css/fonts/raleway-light.woff') format('woff'), url('/css/fonts/raleway-light.ttf') format('truetype'), url('/css/fonts/raleway-light.svg#ralewaylight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Raleway";
    src: url('/css/fonts/raleway-regular.eot');
    src: url('/css/fonts/raleway-regular.eot?#iefix') format('embedded-opentype'), url('/css/fonts/raleway-regular.woff') format('woff'), url('/css/fonts/raleway-regular.ttf') format('truetype'), url('/css/fonts/raleway-regular.svg#ralewayregular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Raleway";
    src: url('/css/fonts/raleway-semibold.eot');
    src: url('/css/fonts/raleway-semibold.eot?#iefix') format('embedded-opentype'), url('/css/fonts/raleway-semibold.woff') format('woff'), url('/css/fonts/raleway-semibold.ttf') format('truetype'), url('/css/fonts/raleway-semibold.svg#ralewaysemibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Raleway";
    src: url('/css/fonts/raleway-heavy.eot');
    src: url('/css/fonts/raleway-heavy.eot?#iefix') format('embedded-opentype'), url('/css/fonts/raleway-heavy.woff') format('woff'), url('/css/fonts/raleway-heavy.ttf') format('truetype'), url('/css/fonts/raleway-heavy.svg#ralewayheavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@mixin prim-font {
    font-family: 'Roboto', sans-serif;
}

@mixin header-font {
    font-family: 'Raleway', sans-serif;
}

@mixin light {
	 color: $white !important;
	-webkit-font-smoothing: antialiased;
		p {
			 color: $white;
			-webkit-font-smoothing: antialiased;
		}
}

@mixin dark {
	color: $black !important;
	-webkit-font-smoothing: antialiased;
		p {
			color: $black;
			-webkit-font-smoothing: antialiased;
		}
}
@mixin small {
	p {
        font-size: rem-calc(13);
		font-weight: 400;
        line-height: 1.4;
        @include large-up {
             font-size: rem-calc(16);
        }
    }
}
@mixin supersmall {
	p {
        font-size: rem-calc(14);
    }
}

@mixin supersmall {
	p {
        font-size: rem-calc(14);
    }
}

html, body {
    @include prim-font;
    line-height: 1.3;
    color: $white;
    text-align: center;
	-webkit-font-smoothing: antialiased;
	-moz-font-feature-settings:"lnum" 1; 
	-ms-font-feature-settings:"lnum" 1; 
	-o-font-feature-settings:"lnum" 1; 
	-webkit-font-feature-settings:"lnum" 1; 
	font-feature-settings:"lnum" 1;
}

.error {
    font-size: 0.8rem;
    line-height: 1.3;
    color: red !important;
    text-align: right;
    margin-right: 1rem;
}

.pligttext {
    p {
        font-size: rem-calc(11);
        padding-top: rem-calc(10);
    }
}

p,
.body-text {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    margin: 0.8em 0;

    @include large-up {
        font-size: 15px;
        line-height: 22px;
    }

    &.brown {
        color: $brown !important;
    }

    &.orange {
        color: $shadow-orange !important;
    }

    &.grey {
        color: $grey !important;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.small {
        font-size: 10px;
        line-height: 15px;

        @include large-up {
            font-size: 16px;
            line-height: 23px;
        }

        @include xxlarge-up {
            font-size: 14px;
            line-height: 21px;
        }
    }

    &.large {
        font-size: rem-calc(22);

        @include medium-up {
            font-size: rem-calc(26);
        }

        @include large-up {
            font-size: rem-calc(30);
        }
    }

    &.sub-header {
        @extend .large;
        color: $dark-grey;
    }

    &.section-header {
        @include header-font;
        font-size: rem-calc(80);
        font-weight: 100;
    }

    &.price {
        @include header-font;
        font-size: rem-calc(90);
        font-weight: 100;
        line-height: 1.1;
        margin: 1.2rem 0;
        max-width: none;
        text-shadow: 0 2px 0 rgba($black, 0.3);
    }

    &.valuta {
        @include header-font;
        font-size: rem-calc(20);
        text-transform: uppercase;
        font-weight: 400;
        line-height: 1.1;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
        margin-left: rem-calc(-20);
        max-width: none;
        display: inline;
        text-align: left;
        color: $shadow-orange !important;
    }



    &.display {
        @include header-font;
        font-size: rem-calc(40);

        @include xlarge-up {
            font-size: rem-calc(140);
        }

        @include large-only {
            font-size: rem-calc(120);
        }

        @include medium-only {
            font-size: rem-calc(80);
        }
    }
}
.manchet {
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;

    @include large-up {
        font-size: 19px;
        line-height: 27px;
        font-weight: 300;
    }
}
.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}


.price-small {
    @include prim-font;
    color: $black !important;
    font-weight: 600;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
}

.price-small-white {
    @include prim-font;
    color: $white !important;
    font-weight: 600;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
}

.price-black {
    @include header-font;
    font-size: rem-calc(30) !important;
    line-height: 1.1;
    font-weight: 900;
    color: $black !important;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    padding-top: 0;
    display: inline;
    margin-left: rem-calc(10);
    max-width: none;


    @include medium-up {
        font-size: rem-calc(45) !important;
    }

    @include large-only {
        font-size: rem-calc(40) !important;
    }
}

.price-white {
    @include header-font;
    font-size: rem-calc(30) !important;
    line-height: 1.1;
    font-weight: 900;
    color: $white !important;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    padding-top: 0;
    display: inline;
    margin-left: rem-calc(10);
    max-width: none;

    @include medium-up {
        font-size: rem-calc(45) !important;
    }

    @include large-only {
        font-size: rem-calc(40) !important;
    }
}

price-container {
    max-width: rem-calc(100);
}

.currency {
    @include small-only {
        //font-size: 0.65em;
    }
    text-transform: uppercase;
}


a {
    text-decoration: none;
    color: #fff;
    @include transition('color .3s ease-in-out, transform 0.3s ease-in-out');

    i {
        vertical-align: middle;
        position: relative;
        top: -1px;
        left: -1px;
    }

    &:not(.button):hover {
        text-decoration: underline;
    }

    &.no-decoration:hover {
        text-decoration: none;
    }

    &.react {
        display: block;
        transform: scale(1) translateZ(0);

        &:hover {
            transform: scale(1.05) translateZ(0);
        }
    }

    .p-small & {
        font-size: 10px;
        line-height: 15px;

        @include large-up {
            font-size: 14px;
            line-height: 21px;
        }
    }
}

.richtextlink {
    text-decoration: underline !important;
}

.nopadtop {
    padding-top: 0 !important;
}

.nopadbottom {
    padding-bottom: 0 !important;
}

.nospace {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    @include header-font;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0.5em 0;

    &.yellow {
        color: $yellow_hero !important;
    }

    &.white {
        color: $white !important;
    }

    &.display {
        @include header-font;
        font-size: 6vw;

        @include xlarge-up {
            font-size: rem-calc(120);
        }

        @include large-only {
            font-size: rem-calc(100);
        }

        @include medium-only {
            font-size: rem-calc(80);
        }
    }

    &.light {
        font-weight: 300;
    }
}

h1, h2, h3 {
    &:first-child {
        margin-top: 0;
    }
}


h1,
.h1,
.large-header,
.large {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 0.3em;

    @include large-up {
        font-size: 74px;
        line-height: 74px;
    }
}

//.medium-header,
h2,
.h2 {
    font-size: 36px;
    line-height: 46px;

    @include xlarge-up {
        font-size: 44px;
        line-height: 54px;
    }
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
.small-header {
    font-size: 24px;
    line-height: 28px;

    @include large-up {
        font-size: 36px;
        line-height: 42px;
    }  
}
h6 {
    font-size: rem-calc(24);
}

.light {
    @include light;
}

.dark {
    @include dark;
    a{
        color:$black;
    }
}

.text-left {
    text-align: left;

    &.back_text {
        @extend .manchet;
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.padleft20 {
    padding-left: 20px;
}

.padright20 {
    padding-right: 20px;
}

.padtop20 {
    padding-top: 20px;
}


.large {

    p {
        font-size: rem-calc(22);

        @include medium-up {
            font-size: rem-calc(26);
        }

        @include large-up {
            font-size: rem-calc(30);
        }
    }
}

.small {
	@include small
}

.supersmall {
    @include supersmall
}

.mini {
    p {
        font-size: rem-calc(10);
    }
}

.text-left {
    p {
        text-align: left;
    }
}

.bold {
    p {
        font-weight: bold;
    }
}

.action {
    cursor: pointer;
}

.strong {
    font-weight: 600;
}
