﻿.tool-tip {
	display: none;
	opacity: 0;
	@include transition(opacity .2s ease-in-out);
	position: absolute;
	@include gradient(($dark-grey, $black), "linear");
	text-align: center;
	z-index: 4;
	color: $white;
	line-height: em-calc(40);
	border-radius: $radius;
	width: 100%;
	max-width: 300px;
	padding: 0.6em;

	> p {
		font-size: 0.8em !important;
		line-height: 1.3;
        &.header {
            font-weight: 600;
            font-size: 1.6rem !important;
        }
		strong {
			display: block;
			display: block;
			font-size: 1.2em !important;
			margin-bottom: 0.3em;
		}
	}

	&:after {
		content: "";
		position: absolute;
		border-left: em-calc(5) solid transparent;
		border-right: em-calc(5) solid transparent;
		border-bottom: em-calc(5) solid $black;
		top: em-calc(-4);
		left: 0;
		right: 0;
		width: em-calc(5);
		margin: auto;
	}

    &.upper {
        top: em-calc(-60);

		&:after {
			border-left: em-calc(5) solid transparent;
			border-right: em-calc(5) solid transparent;
			border-top: em-calc(5) solid $black;
            border-bottom: none;
			bottom: em-calc(-5);
            top: auto;
			left: 0;
			right: 0;
		}
    }

	&.show {
		opacity: 1;
	}
	&.active {
		display: block;
	}
}
