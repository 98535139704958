﻿#map-container {
	margin-top: 0.8em;

	&.white-map {
		pointer-events: none;
	}

	#marker_LB {
		@include transform (translate(92px, 325px) scale(1), null);
	}

	#marker_LL {
		@include transform (translate(300px, 482px) scale(1), null);
	}

	#marker_LS {
		@include transform (translate(5px, 267px) scale(1), null);

		.letter_s {
			transform: translate(-61px, 0px);
		}
	}

	#marker_LL,
	#marker_LB,
	#marker_LS {
		@include transition('all .3s ease-in-out');
		cursor: pointer;

		.backdrop-0 {
			@include transition('all .3s ease-in-out');
			stop-color: #989898;
		}

		.backdrop-1 {
			@include transition('all .3s ease-in-out');
			stop-color: #5C5C5C;
		}

		.label-0 {
			@include transition('all .3s ease-in-out');
			stop-color: #A6A6A6;
		}

		.label-1 {
			@include transition('all .3s ease-in-out');
			stop-color: #5C5C5C;
		}

		.shadow {
			@include transition('all .3s ease-in-out');
			fill: #525252;
		}
	}

	&.white-map #map path {
		fill: $white;
	}

	&.billund #marker_LB {
		@include transform(translate(92px, 325px) scale(1.4), null);
	}

	&.rodby #marker_LL {
		@include transform(translate(300px, 482px) scale(1.4), null);
	}

	&.sondervig #marker_LS {
		@include transform(translate(5px, 267px) scale(1.4), null);
	}

	&.rodby #marker_LL,
	&.billund #marker_LB,
	&.sondervig #marker_LS {

		.backdrop-0 {
			stop-color: #aed61f;
		}

		.backdrop-1 {
			stop-color: #76a70c;
		}

		.label-0 {
			stop-color: #8cb914;
		}

		.label-1 {
			stop-color: #bbe123;
		}

		.shadow {
			fill: #69950b;
			@include transform (translateY(3px), null);
		}
	}

	// Large map
	.pin-booking-g {
		cursor: pointer;

		.shadow {
			opacity: 0;
		}
	}
	&.rodby {
		.pin-booking-g--rodby {
			.shadow {
				opacity: 1;
			}
			.grad01 {
				fill: url(#map-pin-grad-03)
			}
			.grad02 {
				fill: url(#map-pin-grad-04)
			}
		}
	}
	&.billund {
		.pin-booking-g--billund {
			.shadow {
				opacity: 1;
			}
			.grad01 {
				fill: url(#map-pin-grad-03)
			}
			.grad02 {
				fill: url(#map-pin-grad-04)
			}
		}
	}
	&.sondervig {
		.pin-booking-g--sondervig {
			.shadow {
				opacity: 1;
			}
			.grad01 {
				fill: url(#map-pin-grad-03)
			}
			.grad02 {
				fill: url(#map-pin-grad-04)
			}
		}
	}
}
