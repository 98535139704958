html, body {
	width: 100%;
	height: auto;
    //height: 100%;
	overflow-x: hidden;
    position: relative;

	&.show-nav, &.lock, &.modal {
		height: 100%;
		overflow-y: hidden;
       
	}
    &.lock.touch {
        body {
            height: 100%;
            overflow: hidden;
        }
    }
}

.content-container {
    position: relative;
    background-color: $white;

    &.black {
        background-color: $bg-page-black;
    }

    &.grey {
        background-color: $bg-page-grey;
    }

    &.sky {
        background-color: $bg-page-sky;
    }

    &.pool {
        background-color: $bg-page-pool;
    }

    &.winter {
        background-color: $bg-page-winter;
    }

    &.melon {
        background-color: $bg-page-melon;
    }

    &.aubergine {
        background-color: $bg-page-aubergine;
    }
}



form {
	margin: 0;
}

.section-container {
	margin-top: rem-calc(60);
}

