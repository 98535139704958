img, video, svg, object {
	max-width: 100%;

	.contain & {
		width: 100%;
        max-width: 850px;
        margin: auto;
	}

	.centered-image &, .centered-image .focus-MiddleCenter & {
		position: absolute;
		width: auto;
		height: auto;
        margin: 0;
		min-width: 100%;
		min-height: 100%;
		max-width: 125%;
        @include medium-up {
            max-width: 150%;
        }
	}
	.centered-image .focus-TopCenter & {
		right: 50%;
		bottom: auto;
		@include transform('translateX(50%)', null);
	}
	.centered-image &, .centered-image .focus-MiddleCenter & {
		right: 50%;
		bottom: 50%;
		@include transform('translate(50%, 50%)', null);
	}
	.centered-image .focus-BottomCenter & {
		right: 50%;
		bottom: 0;
		@include transform('translateX(50%)', null);
	}
	.centered-image .focus-TopLeft & {
		top: 0;
		left: 0;
		right: auto;
		bottom: auto;
		@include transform('translate(0%, 0%)', null);
	}
	.centered-image .focus-MiddleLeft & {
		left: 0;
		right: auto;
		bottom: 50%;
		@include transform('translateY(50%)', null);
	}
	.centered-image .focus-BottomLeft & {
		left: 0;
		right: auto;
		bottom: 0;
		@include transform('translate(0%, 0%)', null);
	}
	.centered-image .focus-TopRight & {
		right: 0;
		bottom: auto;
		@include transform('translate(0%, 0%)', null);
	}
	.centered-image .focus-MiddleRight & {
		right: 0;
		bottom: 50%;
		@include transform('translateY(50%)', null);
	}
	.centered-image .focus-BottomRight & {
		right: 0;
		bottom: 0;
		@include transform('translate(0%, 0%)', null);
	}

}

iframe {
	max-width: 100%;
	.centered-image & {
		overflow:hidden;
		position: absolute;
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		max-width: 125%;
        left:0;
		
        //dette er udkommenteret, da det skabte problemer for hvor video blevet placeret i fullscreen mode
		/*@include transform('translate(50%, 50%)', null);
        right: 50%;
		bottom: 50%;*/
	}
}

.gradient-bg-container {
	position: absolute;
	width: 100%;
	height: 100%;
	max-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
	z-index: -1;

	@include small-only {
		height: 500px;
	}

	svg {
		position: absolute;
		top: 0;
		left: 0;
	}
}