.row {
    margin-top: $column-gutter/2;
    margin-bottom: $column-gutter/2;

    section > & {
        //padding: rem-calc(40/2) 0 0 0;
        //padding-top: rem-calc(40/2);
        padding-top: 30px;

        /*@include medium-only {
            padding: rem-calc(75/2) 0 0 0;
            //padding-top: rem-calc(75/2);
        }*/

        @include medium-up {
            padding-top: 60px;
        }

        /*@include large-up {
            padding: rem-calc(45) 0 0 0;
            //padding-top: rem-calc(150/2);
        }*/
    }

    &:first-of-type {
        margin-top: 0;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &.collapse {
        margin-top: 0;
        margin-bottom: 0;
    }

    &.collapseall {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
    }

    &.collapseheight {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    &.collapse_openinghours {
        margin: 0;
        padding-top: 0;
        padding-bottom: rem-calc(20);
    }

    &.rounded {
        border-radius: $radius;
        overflow: hidden;
    }

    &.noradius {

        @include small-only {
            border-radius: 0 !important;
        }

        @include medium-only {
            border-radius: 0 !important;
        }
    }

    &.rounded-top {
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
        overflow: hidden;
    }

    &.rounded-bottom {
        border-bottom-left-radius: $radius;
        border-bottom-right-radius: $radius;
        overflow: hidden;
    }

    @include medium-up {
        &.table-layout {
            display: table;

            > .columns, > .column {
                display: table-cell;
                position: static;
                float: none;
                height: 100%;

                &.large-4 {
                    > .panel.image {
                        width: 33.333333%;
                    }
                }

                > .panel.image {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
}

.row-wide {
    position: relative;

    section > & {
        padding: rem-calc(40/2) 0 0 0;
        //padding-top: rem-calc(40/2);
        @include medium-only {
            padding: rem-calc(75/2) 0 0 0;
            //padding-top: rem-calc(75/2);
        }

        @include large-up {
            padding: rem-calc(50) 0 0 0;
            //padding-top: rem-calc(150/2);
        }
    }
}

.row-wide1 {
    position: relative;
}

.block-top-space {
    margin-top: rem-calc(60) !important;
}

.rounded {
    border-radius: $radius;
    overflow: hidden;
}

.grouping-title {
    margin-top: 0;
    margin-bottom: 15px;
    //padding-top: rem-calc(5);
    //padding-top: rem-calc(40/2);
    //margin-bottom:-40px !important;
    @include medium-only {
        //padding-top: rem-calc(50/2);
    }

    @include medium-up {
        margin-bottom: 30px;
    }

    @include large-up {
        //padding-top: rem-calc(10);
        // padding-top: rem-calc(50);
    }
}
