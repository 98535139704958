﻿#tabnav {
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;
    padding: 0;
}

.tabs-block {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;

    .temp-bg {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @include medium-up {
        background-size: 100% auto;

        .temp-bg {
            background-size: 100% auto;
        }
    }
}

.tabs-block__header {
    margin-top: 0;
    margin-bottom: 15px;

    @include small-only {
        padding: 0 15px;
    }

    @include medium-up {
        margin-bottom: 30px;
    }
}

.tabs-container {
}


.block-icon {
    display: inline-block;
}

.block-text {
    margin-bottom: 20px;
}

.tabs-nav-wrapper {
    margin-bottom: 30px;
}

.tabnav {
    padding: 0;
    margin: 0;

    li {
        display: inline;
        width: 100%;
        //padding: 0 15px;
        padding: 0 10px;


        &:first-child {
            border-left: none;
        }
    }

    a {
        @extend .manchet;
        opacity: 0.6;

        &.current,
        &:hover,
        &:active,
        &:focus {
            font-weight: 500;
            opacity: 1;
        }
    }
}

.tabcontent {
    transition: all ease-out 0.2s 0.1s;
}

.tabcontent__item {
    @include medium-up {
        display: flex;
        flex-direction: row;
    }

    .panel {
        @include medium-up {
            padding: 0;
        }

        &:before {
            content: none;
        }
    }

    .panel-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 200px;

        @include medium-up {
            padding: 30px;
        }

        @include large-up {
            padding: 50px;
            min-height: 300px;
        }
    }

    .slider {
        display: block;
    }
}

.tabcontent__item__header {
    margin-bottom: 15px;
}

.tabcontent__item__text {

    @include xlarge-up {
        width: 90%;
        margin: 0 auto;
    }

    p {
        @extend .manchet;
    }
}

.tabs {
    li {
        display: inline-block;
    }
}

.tabs-block__slider {
}

.tab_contents_container {
    margin: 0;
    padding: 0;
}

#tabs_container {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;

    ul {
        margin: 0;
        padding: 0;
    }
}

.tabs-block__item {
    .panel {
        padding: 30px 20px;
        position: relative;
    }

    .video {
            cursor: pointer;
            &:after {
                @extend %icon-font;
                @extend .icon-play:before;
                position: absolute;
                top: 0;
                font-size: 6em;
                bottom: 0;
                height: 1em;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
                backface-visibility: hidden;
                display: none;

                @include small-only {
                    display: block;
                }
            }

            &.playing {
                &:after {
                    display: none;
                }
            }

            img,
            iframe {
                @include small-only {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }

        }
    }

    .panel-content {
        > p {
            margin-bottom: 10px;
            @extend .manchet;
        }
    }
}

.tabs-block__info-pills {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.tabs-block__info-pill {
    margin: 5px 5px 0 !important;
    padding: 5px 8px;
    border-radius: 25px;
    background-color: rgba(#202020, 0.2);
    flex-grow: 0 !important;

    @extend .manchet;

    @include small-only {
        font-size: 12px;
    }

    @include medium-up {
        margin: 10px 5px !important;
        padding: 5px 15px;
        //font-size: 14px;
    }

    @include xxlarge-up {
        margin: 20px 10px !important;
        padding: 15px 20px;
        //font-size: 18px;
    }

    span {
        margin-left: 5px;
        //font-weight: 900;
    }
}
