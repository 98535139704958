$vertical-bp-small: "#{$screen} and (max-height:659px) and (min-width: #{lower-bound($medium-range)})";
$vertical-bp-fluid: "#{$screen} and (min-height:660px) and (max-height:900px) and (min-width:#{lower-bound($medium-range)})";
.booking-flow {
    .booking-dates {
        background: $light-booking-grey;

        &__calendar {
            padding-top: 100px;
            position: relative;

            @include small-only {
                padding-top: 40px;
            }

            @include medium-up {
                display: block;
            }

            .sub-header {
                margin-bottom: 60px;

                @include small-only {
                    margin-bottom: 20px;
                }
            }

            .inputs {
                display: flex;

                .icon {
                    margin-left: 10px;
                }
            }

            .departure, .arrival, .offers {
                max-width: 600px;
            }

            .ui-datepicker .ui-datepicker-calendar tbody tr {
                height: 55px;

                @include small-only {
                    height: 40px;
                }
            }

            .datepicker-offers {
                width: 100%;
                background-color: $white;
                border-top: 1px solid #f4f4f4;
                padding: 10px 0;

                i {
                    margin-left: 10px;
                }
            }

            button.disabled {
                background-image: $bookingflow-new-btn-grey-gradient;
                box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.3);
                color: #878787 !important;
                opacity: 1 !important;

                &:hover {
                    cursor: default;
                }
            }
        }

        &__calendar.calendar-normal {
            .ui-datepicker .ui-datepicker-calendar .arrivalDate:before,
            .ui-datepicker .ui-datepicker-calendar .ui-datepicker-current-day:before {
                //@include gradient($green, 'linear');
                //border: 1px solid #185C36;
                //z-index: 2;
            }

            .ui-datepicker .ui-datepicker-calendar .inrange:before {
                //@include gradient($green, 'linear');
            }
        }

        .calendars {
            position: relative;
            background: $booking-grey;
            border-radius: $radius;

            .calendar {
                &.arrival {
                    position: relative;
                }

                &.departure {
                    position: absolute;
                    display: none;
                }

                &.offers {
                    position: absolute;
                    display: none;
                }

                &.arrival,
                &.departure,
                &.offers {
                    background: $white;
                }

                padding: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            .ui-datepicker.ui-datepicker-multi .ui-datepicker-group {
                padding-right: 0;
            }

            .ui-datepicker .ui-datepicker-header {
                @include gradient($green, 'linear');
                //background-color: #f4f4f4;
                color: white;
                font-weight: 700;
                margin: 0;
                padding: 10px;

                .ui-datepicker-next {
                    right: 30px;
                    top: 15px;
                    color: $white;
                }

                .ui-datepicker-prev {
                    left: 30px;
                    top: 15px;
                    color: $white;
                }
            }

            .ui-datepicker .ui-datepicker-calendar .ui-datepicker-week-col {
                text-transform: none;
                color: #77A70D;
                font-size: 11px;
            }

            .ui-datepicker .ui-datepicker-calendar thead th {
                color: #77A70D;
            }
        }

        .inputs {
            color: $white;

            .label {
                display: flex;
                font-weight: 700;
                font-size: 16px;

                @include small-only {
                    flex-direction: column;
                    font-size: 13px;
                }

                &__text {
                    color: #77A70D;

                    @include small-only {
                        text-align: left;
                    }
                }

                .date {
                    color: $black;
                    margin-left: 8px;

                    @include small-only {
                        text-align: left;
                        margin-left: 0;
                    }
                }
            }

            .arrival,
            .departure {
                background-color: white;
                padding: 15px;
                width: 50%;

                @include small-only {
                    padding: 8px 15px;
                }
            }

            .arrival {
                border-right: 1px solid #ececec;
            }

            .departure {
                border-left: 1px solid #ececec;
            }
        }

        .information-wrapper.js-info {
            max-width: 270px;
            position: absolute;
            right: 0;
            top: 190px;
        }

        .total-nights {
            display: block;
            margin: 15px 0;
            font-size: 19px;
            font-weight: 700;

            img {
                margin-right: 4px;
                margin-top: -4px;
                width: 24px;
            }
        }

        .specialRates {
            background-color: white;
            border-top: 1px solid #f4f4f4;
            display: flex;
            flex-direction: column;
            padding: 0;

            .datepicker-special-rates {
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 12px;
                padding: 16px;
                position: relative;

                img {
                    width: 16px;
                    margin-right: 8px;
                }

                i {
                    font-size: 12px;
                    position: absolute;
                    right: 16px;
                }

                .icon-arrow-up {
                    font-size: 8px;
                }
            }

            ul {
                list-style-type: none;
                display: flex;
                justify-content: flex-start;
                margin: 0;
                padding: 0 0 16px;
                flex-wrap: nowrap;
                overflow-x: auto;
                overflow-y: hidden;

                li {
                    flex-shrink: 0;
                    margin: 0 8px 0 0;

                    &:first-of-type {
                        padding-left: 16px;
                    }

                    &:last-of-type {
                        padding-right: 16px;
                    }
                }

                .calendar-offer-btn {
                    align-items: center;
                    background: #FDF1D2;
                    border: 0;
                    border-radius: 20px;
                    font-size: 10px;
                    font-weight: 700;
                    color: $black;
                    display: flex;
                    margin: 0;
                    padding: 5px 14px 5px 5px;

                    &__text {
                        text-align: left;
                    }

                    .sr-subtitle {
                        display: block;
                        font-weight: 400;
                    }

                    img {
                        width: 24px;
                        margin-right: 5px;

                        @include small-only {
                            width: 18px;
                            margin-right: 4px;
                        }
                    }

                    &.active {
                        background-image: linear-gradient(180deg, #FFE24A 0%, #FFBD0F 99%);
                    }
                }
            }
        }
    }

    .arrival .calendars .calendar.arrival,
    .departure .calendars .calendar.departure,
    .offers .calendars .calendar.offers {
        display: block;
        z-index: 2;
    }

    button.cta {
        &.disabled {
            @include gradient(($booking-light-disable, $booking-grey-disable), 'linear');
            color: $white;
            box-shadow: 0 2px darken($grey, 10%);
            opacity: 0.4;
            text-shadow: 0 1px 0 rgba(0,0,0,0.22);
        }
    }
}

.calendar-wrapper {
    .calendars {
        .calendar {
            &.arrival,
            &.departure,
            &.offers {
                display: none;
                margin-bottom: 0;
                position: relative !important;
            }

            .row.arrival &.arrival,
            .row.departure &.departure,
            .row.offers &.offers {
                display: block;
            }
        }
    }
}

.calendar-wrapper .inputs .calendar-icon {
    cursor: default;

    small {
        font-size: 10px;
        font-weight: 400;
        margin-left: 4px;
    }

    &.arrival {
        cursor: pointer;
        opacity: 0.75;

        .label {
            opacity: 0.5;
        }

        .row.arrival & {
            cursor: default;
            opacity: 1;

            .label {
                opacity: 1;
            }
        }

        .row.offers & {
            pointer-events: none;
        }
    }

    &.departure {
        cursor: pointer;
        opacity: 0.75;

        .label {
            opacity: 0.5;
        }

        .row.departure &:not(.done) {
            cursor: default;
            opacity: 1;

            .label {
                opacity: 1;
            }
        }

        &.done {
            cursor: default;
            opacity: 0.75;

            .label {
                opacity: 0.5;
            }
        }

        .row.offers & {
            pointer-events: none;
        }
    }
}