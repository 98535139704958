﻿

.lang-select {
    display: block !important;
    background: transparent !important;
    border: 0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-transform: uppercase;

    optgroup{
        background:white !important;
        border-radius:$radius;
         border: 0 !important;
    padding:20px;

    
    }

   
}
