@mixin font-sizes {
    font-size: 13px;

    @media screen and (min-width: 1250px) {
        font-size: 15px;
    }
}

nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    @include prim-font;
    z-index: 9;
    text-align: left;
    background: rgba($black, 0.7);
    color: $white;
    justify-content: space-between;
    align-items: center;
    @include transition(all .3s ease-in-out);

    @media screen and (min-width: 1150px) {
        display: flex;
        height: 60px;
        padding: 20px 15px;
    }

    .global-fixed & {
        margin-top: -60px;
    }

    .frontpage & {
        margin-top: 0;
    }

    i {
        position: relative;
        font-size: 1.7em;
        vertical-align: middle;

        &.icon-arrow-down {
            font-size: 10px;
        }
    }

    //GLOBAL Nav
    &.global {
        backface-visibility: hidden;
        box-shadow: 0 0 24px 0 rgba(0,27,87,0.3);

        .info-modal-open & {
            z-index: 1;
        }


        &.deactivate {
            width: 120%;
            transition: all 400ms linear;

            .search {
                @include medium-only {
                    width: 30%;
                }
            }

            .nav-item {
                opacity: .5;
                pointer-events: none;
            }
        }

        .search {
            width: auto;

            .searchbar-wrapper {
                vertical-align: middle;
            }
        }

        .nav__menu {
            > ul {
                @include navigation-list('left');
            }
        }

        .tools {
            a {
                float: right;
            }

            ul {
                @include navigation-list('right');
                display: none;
            }
        }
    }
}

.nav__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 1600px) {
        max-width: 1230px;
        max-width: 1440px;
        margin: 0 auto;
    }
}

//NAVIGATION
.nav__menu {
    position: relative;

    .nav__menu-label {
        float: left;
        padding: 22px 10px;
        @include font-sizes;
        font-weight: 300;

        @media screen and (min-width: 1250px) {
            padding: 19.5px 10px;
        }
    }
    > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;

        > li {
            position: relative;

            &:not(.nav-item--dropdown) {
                &:first-child,
                &:nth-child(2) {
                    &:after {
                        content: "";
                        content:none;
                        width: 1px;
                        background-color: $white;
                        height: 20px;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }

            &:nth-child(3) {
                > a {
                    padding-right: 20px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 1px;
                    height: 50%;
                    transform: translateY(-50%);
                    background-color: white;
                }
            }
            &:nth-child(4) {
                > a {
                    padding-left: 20px;
                }
            }
            > a {
                position: relative;

                &:after {
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 10px 10px 10px;
                    border-color: transparent transparent $white transparent;
                    top: 50px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    opacity: 0;
                    @include transition(all .3s ease);
                }
                &:before {
                    content: attr(data-text);
                    content: attr(data-text) / "";
                    display: block;
                    height: 0;
                    visibility: hidden;
                    overflow: hidden;
                    user-select: none;
                    pointer-events: none;
                    font-weight: 600;

                    @media speech {
                        display: none;
                    }
                }
                &.active,
                &:hover {
                    font-weight: 600;
                }
            }

            &.active-subnav {
                a {
                    &:after {
                        content: " ";
                        opacity: 1;
                    }
                }
            }

            &.fav {
                position: relative;
                top: -3px;

                a {
                    padding: 0;
                }

                i {
                    position: relative;
                    font-size: 1.7em;

                    span {
                        @include prim-font;
                        position: absolute;
                        left: 0;
                        width: 100%;
                        font-size: 0.45em;
                        line-height: 2.2em;
                        text-align: center;
                        letter-spacing: 0px;
                    }
                }
            }
        }
    }
}

.nav__sub-nav {
    display: none;
    height: 0;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background: $white;
    color: $black;
    opacity: 0;
    @include transition(all .3s ease);
    margin-bottom: 20px;
    padding-left: 15px;
    box-shadow: 0px 1px 14px 0px rgba($black, 0.25);


    @media (min-width: 1900px) {
        padding-left: 0;
    }

    .global-fixed & {
        top: 0;
    }

    .frontpage & {
        top: 60px;
    }

    .active-subnav > & {
        display: block;
        z-index: 4 !important;
    }

    &.show {
        opacity: 1;
        height: 55px;
    }
}


.nav__sub-nav__content {
    position: relative;

    @media (min-width: 1600px) {
        max-width: 1230px;
        max-width: 1440px;
        margin: 0 auto;
    }
    //BOOK CTA
    .nav__book {
        position: absolute;
        right: 20px;
        bottom: 35px;
        z-index: 5;
        @include transition(all .3s ease);

        @media (min-width: 1900px) {
            right: 0;
        }

        .show & {
            bottom: -10px;
        }

        .button {
            height: 40px;
            margin: 0;
            font-size: 20px;
            line-height: 20px;
        }
    }
}

.nav__sub-nav__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    height: 100%;
    @include navigation-list('vertcal');
    @include transition(all .3s ease);



    > li {
        position: relative;
        @include transition(all .3s ease);

        > a {
            display: block;

            &:after {
                content: none;
            }
        }
    }
}

.nav__item {

    a {
        display: block;
        padding: 22px 10px;
        color: currentColor;
        text-decoration: none;
        white-space: nowrap;
        @include font-sizes;
        font-weight: 400;

        @media screen and (min-width: 1250px) {
            padding: 19.5px 10px;
        }

        &:hover {
            text-decoration: none;
            font-weight: 600;
        }

        &[href^="#"] {
            cursor: default;
        }
    }

    &.active {
        > a {
            font-weight: 600;
        }
    }
}

.nav-item--dropdown {
    position: relative;
    display: inline-block;
    cursor: default;
    font-size: 12px;
    @include font-sizes;
    font-weight: 400;
    padding: 20px 10px;

    @media screen and (min-width: 1250px) {
        padding: 19px 10px;
    }

    &:after {
        display: none;
    }

    &:hover {
        font-weight: 400 !important;

        .icon-arrow-down {
            transform: rotate(180deg);
        }
    }

    &__active {
        font-weight: 600;
    }

    i {
        display: inline-block;
        transition: transform 0.3s ease-in-out;
    }

    .nav__sub-nav {
        min-width: calc(100% + 10px);
        height: auto;
        width: auto;
        position: absolute;
        top: 52px;
        left: -15px;
        right: auto;
        padding: 10px 15px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: #F0F0F0;
        box-shadow: 0px 2px 8px 0px rgba($black, 0.2);


        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #F0F0F0 transparent;
            top: -8px;
            left: 0;
            right: 0;
            margin: auto;
            opacity: 0;
            @include transition(opacity .3s ease);
        }

        &.show:before {
            opacity: 1;
        }
    }
}



.sub-nav__dropdown-list {
    @extend .nav__sub-nav__list;
    flex-direction: column;

    > li {
        width: 100%;
        border-bottom: 1px solid #DBDBDB;
        padding: 5px 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    > ul {
        > li {

            &:hover {
            }
        }
    }

    a {
        padding: 10px;

        &:hover {
            text-decoration: none;
            font-weight: 600;
        }
    }
}

//LOGO
.nav__logo {
    padding: 6px 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    @include transition(all .3s ease);

    @media (min-width: 1150px) {
        width: 150px;
        padding: 0;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
    }

    a {
        padding: 0 !important;
    }

    img {
        width: 100%;
    }

    header:not(.frontpage) &,
    header:not(.toppage),
    &.small-logo {
        width: 100px;
        top: 7.5px;
    }
}



// NAV TOOLS
.nav__tools {
    display: flex;
    flex-direction: row;
}

//SEARCH FIELD
.nav__search {
    margin-right: 15px;
    display: flex;

    .search {
        position: relative;
        align-self: center;
    }

    .search-input {
        background-color: transparent;
        padding: 5px 10px;
        padding-right: 30px;
        width: 220px;
        height: 36px;
        margin: 0;
        transition: all 0.4s ease-in-out;
        color: $white;
        font-weight: 500;
        letter-spacing: 1.03px;
        text-indent: 0;
        font-size: 12px;
        @include font-sizes;
        line-height: 16px;
        border-radius: 30px;
        border: 1.5px solid rgba($white, 0.75);


        &::placeholder {
            color: rgba($white, 0.75);
            text-align: left;
        }

        &.active {
            padding-left: 25px;
        }
    }

    .search-clear {
        right: auto;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 5px;

        &:before,
        &:after {
            background-color: $white;
        }
    }

    .search-btn {
        position: absolute;
        padding: 0;
        border: none;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        background: transparent;
        color: $white;
        transition: color 0.2s ease-in-out;

        &:hover {
            background: transparent;
            color: rgba($white, 0.5);
            border: none;
        }

        i {
            display: block;
            margin: 0;
            background-color: transparent;
            font-size: 14px;
            color: $white;
            transform: scaleX(-1);
        }
    }
}

//LANGUAGE SELECTOR
.nav__language {
    margin: 0 10px;

    .lang-selector {
        color: $white;
        transition: all 0.4s ease-in-out;

        .lang-selector__selected {
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow: hidden;
            position: relative;
            text-transform: uppercase;
            @include font-sizes;

            span {
                padding-left: 28px;
                padding-right: 5px;
                overflow: hidden;
            }
        }

        .icon-culture {
            font-size: 20px;
            position: absolute;
            top: 20px;
            left: 0;
        }

        .icon-arrow-down {
            padding: 0;
            margin-left: -2px;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                color: currentColor;
                letter-spacing: 1.03px;
                line-height: 20px;
            }
        }

        li.nav-item--dropdown {
            left: 0;
            padding: 22px 0;

            &.active-subnav {
                &:after {
                    bottom: 0;
                }
            }
        }

        .nav__sub-nav {
            left: -25%;
            left: -30%;
            top: 62px;
            padding: 10px 15px;
            background-color: $white;

            &:before {
                border-color: transparent transparent $white transparent;
            }

            li {
                border-bottom: none;
                padding: 0;
            }
        }
    }
}

.nav-nonactive {
    cursor: default !important;
}
