$vertical-bp-small: "#{$screen} and (max-height:659px) and (min-width: #{lower-bound($medium-range)})";
$vertical-bp-fluid: "#{$screen} and (min-height:660px) and (max-height:900px) and (min-width:#{lower-bound($medium-range)})";

.ui-datepicker {
    @include clearfix;
    display: block;
    color: $dark-grey;
    text-align: center;
    font-size: 0.55rem;

    @include medium-only {
        font-size: 0.8rem;
    }

    @include large-up {
        font-size: 0.9rem;
    }

    @media #{$vertical-bp-small} {
        font-size: 0.7rem;
        padding: em-calc(20);
    }

    @media #{$vertical-bp-fluid} {
        font-size: 1.5vh;
    }

    a:not(.button):hover {
        text-decoration: none;
    }

    &#ui-datepicker-div {
        display: none;
        font-size: 0.6rem;
        background: $white;
        padding: 0.4em;
        border-radius: $radius;
        margin-top: 8px;
        padding: 1em;
        -webkit-font-smoothing: auto;
        //Todo: control if this is affecting where it shouldn't
        box-shadow: 0px 1px 4px 1px rgba(#202020, 0.3);

        td {
            padding: 0em 0.7em;
        }

        &:before {
            content: " ";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5px 5px 5px;
            border-color: transparent transparent rgba($white, 0.9) transparent;
            top: -4px;
            left: 73px;
            margin: auto;
        }

        .ui-datepicker-header {
            margin: 5px 4px;
        }
    }

    &.ui-datepicker-inline {
        width: 100% !important;
        background: none;
        border: 0;
    }

    &.ui-datepicker-multi {
        @include clearfix;

        .ui-datepicker-group {
            float: left;
            padding-right: $column-gutter;

            &.ui-datepicker-group-last {
                padding-right: 0;
            }
        }

        &.ui-datepicker-multi-2 {
            .ui-datepicker-group {
                width: 50%;
            }
        }

        &.ui-datepicker-multi-3 {
            .ui-datepicker-group {
                width: 33%;
            }
        }
    }

    .ui-datepicker-header {
        position: relative;
        font-weight: 300;
        font-size: em-calc(26);
        margin: 15px;

        .ui-datepicker-next, .ui-datepicker-prev {
            position: absolute;
            top: 0;
            color: $black;

            &:before {
                @extend %icon-font;
            }

            &.ui-state-disabled {
                color: $light-grey;
            }

            .ui-icon {
                display: none;
            }
        }

        .ui-datepicker-prev {
            left: 0;
            @extend .icon-small-arrow-left;
        }

        .ui-datepicker-next {
            right: 0;
            @extend .icon-small-arrow-right;
        }
    }

    .ui-datepicker-calendar {
        width: 100%;
        color: $dark-grey;
        font-size: em-calc(22);
        font-weight: 400;
        line-height: 2.5;

        @media all and (-ms-high-contrast:none) {
            *::-ms-backdrop, & {
                line-height: 3.25;
            }
        }

        a {
            color: inherit;
        }

        .ui-datepicker-week-col {
            color: nth($green, 1);
        }

        .departureDate,
        .arrivalDate,
        .inrange,
        .offerArrivalDate:not(.ui-datepicker-other-month) {
            position: relative;

            a {
                position: relative;
                color: $white;
                z-index: 2;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 2.5em;
                height: 2.5em;
                z-index: 1;
                border-radius: 3em;
            }
        }

        .offerInrange,
        .offerDepartureDate {
            position: relative;

            a {
                position: relative;
                color: $white;
                z-index: 2;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 2.5em;
                height: 2.5em;
                z-index: 0;
                border-radius: 3em;
            }
        }

        .arrivalDate,
        .inrange {
            span, a {
                position: relative;
                color: $white;
                z-index: 2;
            }

            &:before {
                background: lighten(nth($green, 1), 25%);
            }
        }

        .offerArrivalDate:not(.ui-datepicker-other-month) {
            span,
            a {
                position: relative;
                color: $white;
                z-index: 2;
            }

            &:before {
                background: nth($yellow, 2);
            }
        }

        .inrange:before,
        .offerInrange:after {
            width: 200%;
            border-radius: 0;
            left: -50%;
        }

        td {
            position: relative;
            width: 12.5%;

            &:before,
            &:after {
                border-radius: 2.5em;
                bottom: 0;
                content: '';
                height: 2.5em;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
                width: 2.5em;
            }

            &:before {
                z-index: 0;
            }

            &:after {
                z-index: 1;
            }

            .ui-state-default {
                position: relative;
                z-index: 2;
            }

            &.ui-datepicker-today {
                .ui-state-default {
                    color: #DADADA;
                    transition: none;
                }

                &:after {
                    border: 1px solid #9F9F9F;
                }
            }

            &.arrivalDate {
                .ui-state-default {
                    color: #fff;
                    z-index: 2;
                }

                &:after {
                    background: linear-gradient(to bottom, #B9DF23, #76A70C);
                    border: 1px solid #185C36;
                    border-radius: 50px;
                    z-index: 1;
                }
            }

            &.inrange {
                .ui-state-default {
                    color: #fff;
                    z-index: 2;
                }

                &:before {
                    background: linear-gradient(to bottom, #B9DF23, #76A70C);
                    left: 0;
                    right: 0;
                    width: auto;
                    z-index: 0;
                    left: -50%;
                    right: -50%;
                }

                &:last-of-type {
                    right: 0;
                }

                &:nth-of-type(2) {
                    left: 0;
                }

                &:after {
                    z-index: 1;
                }
            }

            &:nth-child(2) {
                &.inrange:before,
                &.offerInrange:after {
                    left: 0;
                }
            }

            &:last-child {
                &.inrange:before,
                &.offerInrange:after {
                    right: 0;
                }
            }

            &.ui-datepicker-current-day:not(.inrange):not(.ui-state-disabled),
            &.departureDate {
                .ui-state-default {
                    color: #fff;
                    z-index: 2;
                }

                &:after {
                    background: linear-gradient(to bottom, #B9DF23, #76A70C);
                    border: 1px solid #185C36;
                    border-radius: 50px;
                    z-index: 1;
                }
            }

            &.offerArrivalDate:not(.ui-datepicker-other-month),
            &.startOffer:not(.ui-datepicker-other-month) {
                .ui-state-default {
                    color: #202020 !important;
                    font-weight: 700;
                }

                &:after {
                    background: #FDF1D2 !important;
                    border: 1px solid #D5892E !important;
                }

                &.ui-datepicker-current-day,
                &.offer,
                &.endOffer {
                    .ui-state-default {
                        color: #C27D00 !important;
                    }

                    &:after {
                        background: linear-gradient(180deg, #FFE24A, #FFBD0F) !important;
                    }
                }
            }

            &.offerInrange:not(.ui-datepicker-other-month),
            &.offer:not(.ui-datepicker-other-month) {
                .ui-state-default {
                    color: #C27D00;
                }

                &:before {
                    background: linear-gradient(180deg, #FFE24A, #FFBD0F);
                    border: 0;
                    border-radius: 0;
                    left: -50%;
                    right: -50%;
                    width: auto;
                }
            }

            &.ui-datepicker-other-month {
                background-color: white;
                z-index: 2;

                .ui-state-default {
                    color: transparent;
                }
            }


            &:nth-child(2) {
                &.offer:before {
                    left: 0;
                }
            }

            &:last-child {
                &.offer:before {
                    right: 0;
                }
            }

            &.offerDepartureDate:not(.ui-datepicker-other-month),
            &.endOffer:not(.ui-datepicker-other-month) {
                .ui-state-default {
                    color: #C27D00;
                }

                &:after {
                    background: linear-gradient(180deg, #FFE24A, #FFBD0F);
                }
            }
        }

        thead {
            th {
                font-weight: 500;
                color: nth($green, 1);
                text-transform: uppercase;

                &:first-child {
                    color: $dark-grey;
                    font-weight: 400;
                }
            }
        }
    }

    .ui-state-default {
        display: block;
    }

    .ui-state-disabled,
    .ui-state-disabled.inrange {
        color: $light-grey;
    }
}

.ui-datepicker-calendar {
    .calendar.offers & {
        tbody td:not(.ui-datepicker-week-col) {
            &.ui-state-disabled {
                pointer-events: none;
            }

            &.ui-datepicker-today {
                &.ui-datepicker-other-month {
                    &:after {
                        border: 0;
                    }
                }
            }
        }

        tbody td:not(.ui-datepicker-week-col):not(.ui-state-disabled) {
            pointer-events: none;

            .ui-state-default {
                color: #DADADA;
                position: relative;
                transition: none;
                z-index: 2;
            }

            &.ui-datepicker-current-day {
                .ui-state-default {
                    color: #DADADA;
                }

                &:after {
                    background: transparent;
                    border: 0;
                }
            }

            &.stateDate.ui-datepicker-current-day,
            &.departureDate {
                .ui-state-default {
                    color: #C27D00;
                }

                &:after {
                    background: linear-gradient(180deg, #FFE24A, #FFBD0F);
                    border: 0;
                }
            }

            &.startOffer,
            &.offer,
            &.endOffer {
                .ui-state-default {
                    color: #C27D00;
                }
            }

            &.startOffer {
                pointer-events: all;
            }
        }
    }
}