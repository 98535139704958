﻿.gallery-block {
    h4 {
        margin-top: 0;
        margin-bottom: 15px;

        @include small-only {
            padding: 0 15px;
        }

        @include medium-up {
            margin-bottom: 30px;
        }
    }

    p {
        @extend .manchet;
    }

   
}

.gallery-block--one-column {
    @include small-only {
        width: 110%;
        margin-left: -5%;
    }
   
}
.gallery-block--two-column {
    .bullet-wrapper {
        bottom: 0;

        @include medium-up {
            display: none;
        }
    }
    .slider-inner {
        .next-btn {
            right: 10px;
        } 
        .prev-btn {
            left: 10px;
        }

        @include medium-up {
            .next-btn {
                right: 20px;
            } 
            .prev-btn {
                left: 20px;
            }
        }
    }
}
.gallery-block__slider {
    .slider-inner {
        z-index: 2;
        margin: auto;

        @include small-only {
            width: calc(100vw - 45px);
        }

        &:hover {
            .next-btn, .prev-btn {
                @include medium-up {
                    opacity: 1;
                }
            }
        }

        &.contain {
            @include small-only {
                overflow: visible;
            }
        }
    }

    .slider {
        height: 200px;

        @include medium-up {
            height: 300px;
        }

        @include large-up {
            height: 440px;
        }

        li {
            display: flex;
            flex-direction: column;
            border-radius: 6px;
            margin: 0 0.5rem;
            float: none;
            height: auto;
            opacity: 1;

            @include medium-up {
                flex-direction: row;
                margin: 0;
            }
        }
    }


    .bullet-wrapper {
        bottom: 0;
    }

    .next-btn, .prev-btn {
        top: 50%;
        transition: opacity 0.3s ease-in;
    }

    &.has-indicators  {
        .next-btn, .prev-btn {
            top: calc(50% - 30px);
            //opacity: 0;
        }
}
        .next-btn {
            right: 20px;
        }

        .prev-btn {
            left: 20px;
        }
    }
