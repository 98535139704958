﻿.lang-selector {
    cursor: pointer;
    z-index: 10;

    .light {
        color: $white;
    }

    .icon-arrow-down {
        display: block !important;
        float: right;
        padding-left: rem-calc(5);
        padding-top: rem-calc(4);

        @include large-up {
            padding-top: rem-calc(6);
        }
    }

    ul {
        left: calc(50% - 200px) !important;
        z-index:999 !important;
          @include large-up {
           left: calc(50% - 180px) !important;
        }

        li {
            color: $black;


            a {
                color: $black;
            }
        }
    }
}
