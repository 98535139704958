.offers {
    position: relative;
    overflow: visible !important;
    padding: 0 !important;
    max-width: 100%;

    @include medium-up {
        max-width: 630px;
        margin-bottom: 40px;
    }

    .bullet-wrapper {
        bottom: 0;

        @include xlarge-up {
        }

        .triggers {
            margin-top: 15px;

            li {
                background: white;

                &.active {
                    @include gradient($yellow, 'radial');

                    &:after {
                        background: rgba($white, 0.4);
                    }
                }
            }
        }
    }

    .slider {
        overflow: visible;
    }

    ul {
        &.slider {
            li {
                padding-left: 0;
                padding-right: 0;
                overflow: visible;
            }
        }
    }

    .slider-wrapper:before {
        min-height: 0 !important;
    }

    .price-small {
        text-shadow: 2px 2px 0 darken($light-grey, 10%);

        &--black {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken($black, 10%);
            color: $white !important;
        }

        &--purple {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken(#7A1C9C, 10%);
            color: $white !important;
        }

        &--green {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken(#76A70C, 10%);
            color: $white !important;
        }

        &--blue {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken(#006EB9, 10%);
            color: $white !important;
        }

        &--turkis {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken(#028C98, 10%);
            color: $white !important;
        }

        &--orange {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken(#D43D08, 10%);
            color: $white !important;
        }

        &--yellow {
            @extend .price-small;
            text-shadow: 2px 2px 0 lighten(#D43D08, 20%);
            color: $white !important;
        }

        &--dark-blue {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken(#0F1876, 10%);
            color: $white !important;
        }

        &--dark-green {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken(#185C36, 10%);
            color: $white !important;
        }

        &--coffee {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken(#3E272E, 10%);
            color: $white !important;
        }

        &--relax {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken(#C5AC7D, 10%);
            color: $white !important;
        }

        &--light-orange {
            @extend .price-small;
            text-shadow: 2px 2px 0 darken(#F0892E, 10%);
            color: $white !important;
        }
    }

    .price-small-white {
        text-shadow: 2px 2px 0 lighten(#D43D08, 20%);
    }

    .price-black {
        text-shadow: 2px 2px 0 darken($light-grey, 10%);
    }

    .price-white {
        text-shadow: 2px 2px 0 lighten(#D43D08, 20%);
    }

    .price-custom {
        &--black {
            text-shadow: 2px 2px 0 darken($black, 10%);
        }

        &--purple {
            text-shadow: 2px 2px 0 darken(#7A1C9C, 10%);
        }

        &--green {
            text-shadow: 2px 2px 0 darken(#76A70C, 10%);
        }

        &--blue {
            text-shadow: 2px 2px 0 darken(#006EB9, 10%);
        }

        &--turkis {
            text-shadow: 2px 2px 0 darken(#028C98, 10%);
        }

        &--orange {
            text-shadow: 2px 2px 0 darken(#D43D08, 10%);
        }

        &--dark-blue {
            text-shadow: 2px 2px 0 darken(#0F1876, 10%);
        }

        &--dark-green {
            text-shadow: 2px 2px 0 darken(#185C36, 10%);
        }

        &--coffee {
            text-shadow: 2px 2px 0 darken(#3E272E, 10%);
        }

        &--relax {
            text-shadow: 2px 2px 0 darken(#C5AC7D, 10%);
        }

        &--light-orange {
            text-shadow: 2px 2px 0 darken(#F0892E, 10%);
        }
    }
}

.thumbs-up {
    position: absolute;
    left: -60px;
    bottom: 100px;

    @include medium-up {
        left: rem-calc(-110);
        bottom: 100px;
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        bottom: 17% !important;
    }

    img {
        width: rem-calc(60);
        height: rem-calc(60);
        position: static !important;

        @include medium-up {
            width: rem-calc(100);
            height: rem-calc(100);
        }
    }
}

.offers-inner {
    overflow: visible;
    position: relative;

    &.panel {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 0 !important;
        width: auto !important;
        max-height: rem-calc(350);

        @include medium-up {
            max-height: rem-calc(300);
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            max-height: rem-calc(340);
        }

        @include xxlarge-up {
            max-height: rem-calc(365);
        }
    }
}

.offers-large {
    overflow: visible !important;
}

.thumbs-up_large {
    position: absolute;
    top: -29px;
    right: 40%;
    width: rem-calc(60);
    height: rem-calc(60);
    z-index: 1;

    @include medium-up {
        top: 140px;
        right: -33px;
        width: rem-calc(70);
        height: rem-calc(70);
    }

    @include large-up {
        right: -47px;
        width: rem-calc(100);
        height: rem-calc(100);
    }
}


.offers-container {
    @include large-up {
        padding-left: rem-calc(80);
        padding-right: rem-calc(80);
    }

    .calendar-offer {
        margin-top: rem-calc(40);
    }

     .calendar-offer-inside {
        margin-top: rem-calc(20);
        margin-bottom: rem-calc(20);
    }
}

.tabs {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}

// MULTI OFFER BLOCK
.multi-offer-block {
    @include small-only {
        margin-top: 10px;
    }
}

// SINGLE OFFER BLOCK + MULTI OFFER BLOCK
.single-offer-block,
.multi-offer-block {
    max-width: none;

    > .row {
        > .columns {
            padding: 0;
        }
    }

    .slider {
        li {
            @include small-only {
                overflow: visible;
            }
        }
    }

    .offer__image {
        @include small-only {
            height: 160px;
        }

        .image {
            height: 100%;
        }
    }

    .offer__item {
        height: auto;
        flex: 1 1 auto;

        > .row {
            height: 100%;

            > .columns {
                height: 100%;
            }
        }
    }


    .offer__content {
        display: flex;

        @include small-up {
            height: calc(100% - 25px);
            flex-direction: column;
        }

        @include medium-up {
            height: 100%;
            flex-direction: row;
        }

        &.has-thumb {
            @include small-only {
                margin-top: 29px;
            }
        }

        > .columns:nth-child(2) {
            @include small-only {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
            }
        }
    }

    .panel {
        padding: 0;

        @include small-only {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;
        }

        @include medium-up {
            height: 100%;
        }

        &:before {
            content: none;
        }

        > div {
            height: 100%;

            .ios-9 &,
                .ios-10 & {
                height: auto;
            }
        }
    }

    .panel-content {
        @include small-only {
            padding: 20px 20px 35px !important;
        }

        @include medium-up {
            height: 100%;
        }
    }
}

.offer__cta--mobile  {
    margin-top: -35px;
}

.offer__header--small {
    flex-grow: 1;
    font-size: 35px;
    line-height: 1.1;
    margin: 0;
    margin-bottom: rem-calc(5);
    text-transform: uppercase;


    &.minimize-header {
        font-size: 24px;
        line-height: 1.1;
    }
}

.offer__text p {
    @extend .manchet;
    font-weight: 400;
    margin-bottom: rem-calc(5);

    @include medium-up {
        margin-bottom: rem-calc(10);
    }

    .yellow & {
        color: #E87F35 !important;
    }

    .black &,
    .purple &,
    .green &,
    .blue &,
    .turkis &,
    .orange & {
        color: $white !important;
    }
}

.offer__price,
.offer__price-text,
.offer__priceText {
    &.price-small {
        text-shadow: 2px 2px 0 darken($light-grey, 10%);

        &--black {
            text-shadow: 2px 2px 0 darken($black, 10%);
            color: $white !important;
            @extend .price-small;
        }

        &--purple {
            text-shadow: 2px 2px 0 darken(#7A1C9C, 10%);
            color: $white !important;
            @extend .price-small;
        }

        &--green {
            text-shadow: 2px 2px 0 darken(#76A70C, 10%);
            color: $white !important;
            @extend .price-small;
        }

        &--dark-green {
            text-shadow: 2px 2px 0 darken(#185C36, 10%);
            color: $white !important;
            @extend .price-small;
        }

        &--blue {
            text-shadow: 2px 2px 0 darken(#006EB9, 10%);
            color: $white !important;
            @extend .price-small;
        }

        &--dark-blue {
            text-shadow: 2px 2px 0 darken(#0F1876, 10%);
            color: $white !important;
            @extend .price-small;
        }

        &--turkis {
            text-shadow: 2px 2px 0 darken(#028C98, 10%);
            color: $white !important;
            @extend .price-small;
        }

        &--orange {
            text-shadow: 2px 2px 0 darken(#D43D08, 10%);
            color: $white !important;
            @extend .price-small;
        }

        &--yellow {
            @extend .price-small;
            text-shadow: 2px 2px 0 lighten(#D43D08, 20%);
            color: $white !important;
        }

        &--light-orange {
            text-shadow: 2px 2px 0 darken(#F0892E, 10%);
            color: $white !important;
            @extend .price-small;
        }

        &--coffee {
            text-shadow: 2px 2px 0 darken(#3E272E, 10%);
            color: $white !important;
            @extend .price-small;
        }

        &--relax {
            text-shadow: 2px 2px 0 darken(#C5AC7D, 10%);
            color: $white !important;
            @extend .price-small;
        }
    }

    &.price-small-white {
        text-shadow: 2px 2px 0 lighten(#D43D08, 20%);
    }

    &.price-black {
        text-shadow: 2px 2px 0 darken($light-grey, 10%);
    }

    &.price-white {
        text-shadow: 2px 2px 0 lighten(#D43D08, 20%);
    }

    &.price-custom {
        &--black {
            text-shadow: 2px 2px 0 darken($black, 10%);
        }

        &--purple {
            text-shadow: 2px 2px 0 darken(#7A1C9C, 10%);
        }

        &--green {
            text-shadow: 2px 2px 0 darken(#76A70C, 10%);
        }

        &--dark-green {
            text-shadow: 2px 2px 0 darken(#185C36, 10%);
        }

        &--blue {
            text-shadow: 2px 2px 0 darken(#006EB9, 10%);
        }

        &--dark-blue {
            text-shadow: 2px 2px 0 darken(#0F1876, 10%);
        }

        &--turkis {
            text-shadow: 2px 2px 0 darken(#028C98, 10%);
        }

        &--orange {
            text-shadow: 3px 3px 0 darken(#D43D08, 10%);
        }

        &--light-orange {
            text-shadow: 2px 2px 0 darken(#F0892E, 10%);
        }

        &--coffee {
            text-shadow: 2px 2px 0 darken(#3E272E, 10%);
        }

        &--relax {
            text-shadow: 2px 2px 0 darken(#C5AC7D, 10%);
        }
    }
}

.special-offer-inner {
    .pligttext p {
        font-size: 12px;
    }
}