
.booking-flow {
    .choose-stay {

        > .row {
            margin-bottom: 0;
            padding-bottom: 0;
            padding-top: 0;
        }

        .checkout-overview {
            display: none;
        }

        .house-tabs-column .sub-header {
            margin: 32px 0;

            @include small-only {
                margin: 32px 0 8px;
            }
        }

        @include small-only {
            .house-tabs-column {
                position: relative;

                &:before, &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 1px;
                    background-color: $white;
                    z-index: 1;
                    backface-visibility: hidden;
                    outline: 1px solid red;
                }

                &:before {
                    left: -5px;
                }

                &:after {
                    right: -5px;
                }
            }
        }

        .house-tabs-wrapper,
        .table-wrapper {
            width: 100%;
            overflow-x: scroll;

            @include small-only {
                overflow-x: hidden;
            }

            @include medium-up {
                overflow-x: auto;
                overflow-y: hidden;
            }
        }

        .table-wrapper {
            overflow: hidden;

            @include small-only {
                padding: 5px 0;
                position: relative;

                &:before {
                    background-color: #D4D4D4;
                    border-radius: 14px;
                    bottom: 0;
                    content: '';
                    left: 50%;
                    position: absolute;
                    top: 0;
                    transform: translateX(-50%);
                    width: 250px;
                }
            }
        }

        .house-calendar-content__discount-text {
            margin-top: 30px;

            @include small-only {
                margin-top: 20px;
            }
        }

        .house-tabs-wrapper__inner {
            position: relative;
            padding-right: 30px;
            padding-left: 30px;

            &.with-arrows {
                display: grid;
                grid-auto-flow: column;
                @include small-only {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            @include small-only {
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 30px;
                    height: 100%;
                    width: 8px;
                    background: rgb(236,236,236);
                    background: linear-gradient( 270deg, rgba(236,236,236,1) 0%, rgba(236,236,236,0) 100%);
                    pointer-events: none;
                }
            }

            &.swipe {
                &:after, &:before {
                    content: " ";
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 8px;
                    background: rgb(236,236,236);
                    pointer-events: none; 
                    align-content: center;
                }
                &:before {
                    z-index: 1;
                    background: linear-gradient( 90deg, rgba(236,236,236,1) 0%, rgba(236,236,236,0) 100%);
                    left: 78px;
                    @include small-only {
                        left: 48px;
                    }
                }
                &:after {
                    background: linear-gradient( 270deg, rgba(236,236,236,1) 0%, rgba(236,236,236,0) 100%);
                    right: 78px;
                    @include small-only {
                        right: 48px;
                    }
                }
  
                div.slide-arrow {
                    position: relative;
                    z-index: 2;
                    margin-top: 1em;
                    padding: 1em;
                    background: #fff;
                    box-sizing: border-box;
                    cursor: pointer;
                    color: #5F6368;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    align-content: center;
                    &.right-slide-arrow.icon-small-arrow-right {
                        right: -6px;
                        @include small-only {
                            right: 0;
                        }
                    }
                    &.left-slide-arrow.icon-small-arrow-left {
                        left: -6px;
                        @include small-only {
                            left: 0;
                        }
                    }
                    &.inactive {
                        opacity: 0.5;
                        cursor: default;
                    }
                }
                &.with-arrows {
                    ul.houseList {
                        padding: 0 8px;
                    }
                }
                ul.houseList {
                    display: block;
                    overflow-y: hidden;
                    padding: 0 50px;
                    white-space: nowrap;
                    will-change: transform;
                    user-select: none;
                    cursor: pointer;
                    // vertical-align: top;
                    text-align: left;
                    -ms-overflow-style: none; /* for Internet Explorer, Edge */
                    scroll-behavior: smooth;
                    scrollbar-width: none; /* for Firefox */
                    overflow-x: scroll;

                    &::-webkit-scrollbar {
                        display: none; /* for Chrome, Safari, and Opera */
                    }

                    @include small-only {
                    }

                    li {
                        width: 143px;
                    }
                }
            }
        }

        ul.houseList {
            list-style-type: none;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            position: relative;

            @include small-only {
                overflow-x: auto;
            }

            li {
                display: inline-block;
                cursor: pointer;
                text-align: center;
                // &:first-of-type {
                //     padding-left: 16px;
                // }
                // &:last-of-type {
                //     padding-right: 16px;
                // }
            }
        }

        .house-tabs-wrapper {
            &__tab {
                margin: 0 2px;
                width: 100%;

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                div {
                    background-color: $white;
                    border-radius: $radius $radius 0px 0px;
                    padding: 15px 0;
                    width: 100%;

                    @include small-only {
                        padding: 10px 10px;
                    }

                    @include medium-only {
                        padding: 10px 10px;
                    }
                }

                p {
                    text-transform: uppercase;
                    margin: 0;
                    color: $black;
                    line-height: 18px;
                }

                &__name {
                    @include small-only {
                        min-width: 120px;
                        font-size: 14px;
                        line-height: 16px;
                    }

                    font-size: 16px;
                    font-weight: 600;
                }

                &__price {
                    @include small-only {
                        line-height: 12px;
                    }

                    font-size: 10px;
                }
            }

            .selected {
                div {
                    @include transition('background .2s ease-in-out, color .2s ease-in-out, height .2s ease-in-out');
                    background: #D4D4D4;
                }

                p {
                    color: $black;
                }
            }
        }

        .image-container {
            position: relative;
            height: em-calc(70);
            overflow: hidden;
            border-top-left-radius: $radius;
            border-top-right-radius: $radius;

            > img {
                width: 100%;
            }

            .splash {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 10px;
                margin: auto 0 auto auto;
                width: em-calc(50);
                height: em-calc(50);
                @include small;

                p {
                    line-height: 3.9em;
                }

                text-align: center;
                @include gradient(($dark-grey, $black), "linear");
                color: $white;
                border-radius: em-calc(25);
            }
        }

        .info-container {
            padding-left: em-calc(10);
            padding-right: em-calc(10);
        }

        .calendar-navigation {
            @include clearfix;
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            @include small-only {
                border-top: 1px solid rgba(#363636, 0.5);
                margin: 0 20px;
            }

            button {
                @include small-only {
                    display: none;
                }
            }

            a {
                font-size: rem-calc(18);
                font-weight: 100;
                margin: 16px 0;

                &.hidden {
                    opacity: 0;
                }

                span {
                    color: #202020;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 600;
                }

                @include small-only {
                    font-weight: 400;
                    font-size: 1rem;

                    span {
                        margin-left: -8px;
                    }
                }

                i {
                    color: $black;
                    display: inline-block;
                    font-size: 12px;
                    line-height: 1;
                    height: auto;
                    margin: 0;
                    width: auto;
                }

                &:not(.button):hover {
                    text-decoration: none;
                }
            }

            a.prev-week,
            a.next-week {
                span {
                    @include small-only {
                        display: none;
                    }
                }

                i {
                    font-size: 0.6em;
                    line-height: 4em;
                    height: 3.7em;
                    width: 1em;
                }
            }

            a.prev-week {
                span {
                    margin-right: 20px;
                }
            }

            a.next-week {
                span {
                    margin-left: 20px;
                }
            }

            .between-week-line {
                border-bottom: 1px solid $black;
                flex-grow: 1;
                opacity: 0.2;
                position: relative;

                @include small-only {
                    border-bottom: 0;
                    opacity: 1;
                }

                span {
                    color: $black;
                    display: none;
                    font-size: 13px;
                    font-weight: 700;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    &:first-letter {
                        text-transform: uppercase;
                    }

                    @include small-only {
                        display: block;
                    }
                }
            }
        }

        .house-calendar {
            position: relative;
            background-color: $light-booking-grey;
            width: 100%;
            padding: 0;
            z-index: 4;



            @include large-up {
                min-height: 100vh;
                min-height: calc(var(--vh, 1vh) * 100);
            }

            &.change {
                @include transition('opacity .6s ease-in-out');

                &.ng-enter.ng-enter-active {
                    opacity: 1;
                }

                &.ng-leave.ng-leave-active {
                    opacity: 0;
                }
            }

            .house-calendar-dates {
                position: relative;
                min-height: 250px;
            }

            .house-calendar-centers {
                background-color: $white;
                margin: 60px 0 0;
                padding: 35px 0;
                position: relative;
                z-index: 2;

                @include small-only {
                    margin: 30px 0 0;
                    padding-bottom: 0;
                    padding-top: 20px;
                }

                &:before {
                    bottom: 0;
                    background-color: $white;
                    content: '';
                    left: 50%;
                    position: absolute;
                    top: 0;
                    transform: translateX(-50%);
                    width: 100vw;
                    z-index: -1;
                }

                a {
                    color: $black;
                    text-decoration: underline;

                    .no-touch &:hover {
                        color: $black;
                        text-decoration: none;
                    }

                    &.ng-animate,
                    &.ng-hide,
                    &.ng-hide-remove {
                        display: none;
                    }

                }
            }

            .house-calendar-centers.centers-sure-back {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include small-only {
                    flex-direction: column;
                }

                div {
                    font-size: 14px;
                    margin: 0 30px;

                    @include small-only {
                        margin: 0;
                    }

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }
                }

                div:last-child {
                    display: flex;
                    align-items: center;

                    @include small-only {
                        width: 100%;
                        margin-top: 20px;
                        border-top: 1px solid #D4D4D4;
                    }

                    a {
                        padding: 0 15px;


                        &:last-of-type {
                            padding-right: 0;
                        }

                        @include small-only {
                            width: 50%;
                            display: flex;
                            margin: 0;
                            padding: 20px 15px;
                            justify-content: center;
                            align-items: center;
                        }

                        i {
                            display: none;
                            font-size: 0.6em;
                        }
                    }

                    a:first-child {
                        @include small-only {
                            border-right: 1px solid #ECECEC;
                        }
                    }
                }
            }

            .house-calendar-modal {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 10;

                &:not(.ng-hide) + .house-calendar-content {
                    opacity: 0.3;
                }

                a {
                    color: $grey;
                }

                .house-calendar-modal-wrapper {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                    background: $white;
                    border-radius: 8px;
                    //max-width: $row-width/1.5;
                    max-width: calc(100vw - 40px);
                    max-height: 200px;
                    padding: 60px;
                    border-radius: $radius;

                    @include small-only {
                        padding: 60px 30px;
                    }
                }
            }

            .calendar-table {
                font-size: 1rem;
                left: 50%;
                position: relative;
                transform: translateX(-50%);
                width: calc(100% + 30px);

                @include small-only {
                    margin: auto;
                    max-width: none;
                    width: 750px;
                }

                @include large-up {
                    font-size: 1rem;
                }

                @media #{$booking-bp} {
                    font-size: 1.25vw;
                }

                tr {
                    &:last-child {
                        td .calendar-content {
                            border-bottom-left-radius: $radius;
                            border-bottom-right-radius: $radius;
                        }
                    }

                    td,
                    th {
                        width: 12.5%;

                        .calendar-content {
                            position: relative;

                            @include small-only {
                                margin: 0;
                            }

                            .notPossible,
                            .soldOut {
                                color: $grey;
                                font-size: 20px;
                                line-height: 1.25;
                                padding: 10px 0;
                                position: relative;

                                &:after {
                                    @extend %icon-font;
                                    @extend .icon-sad:before !optional;
                                    display: none;
                                }

                                @include medium-up {
                                    &:after {
                                        display: block;
                                        font-size: 56px;
                                        margin: 10px 0 0;
                                        opacity: 0.3;
                                    }
                                }
                            }

                            .info {
                                bottom: 0;
                                display: flex;
                                height: auto;
                                margin: auto;
                                position: absolute;
                                top: 0;
                                width: 100%;

                                .info__box {
                                    display: flex;
                                    height: 100%;
                                    justify-content: space-around;
                                    padding: 5px;
                                    width: 100%;

                                    div {
                                        min-height: 74px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        flex-direction: column;

                                        button {
                                            margin: 0;
                                        }
                                    }

                                    .offer-text {
                                        font-size: 14px;
                                        font-weight: 600;

                                        @include small-only {
                                            font-size: 10px;
                                        }
                                    }

                                    .price {
                                        font-size: 20px;

                                        @include small-only {
                                            font-size: 16px;
                                        }
                                    }

                                    .offer-old-price {
                                        font-size: 14px;
                                        margin-bottom: 0;

                                        span:first-child {
                                            text-decoration: line-through;
                                        }

                                        @include small-only {
                                            font-size: 10px;
                                        }
                                    }

                                    .price-tag {
                                        font-size: 12px;
                                        position: relative;
                                        margin-left: 6px;
                                        displaY: inline-block;
                                        height: 16px;
                                        padding: 0 2px;
                                        background: $black;
                                        color: $white;
                                        line-height: 16px;

                                        @include small-only {
                                            font-size: 10px;
                                            height: 14px;
                                            line-height: 14px;
                                        }

                                        &:before {
                                            position: absolute;
                                            content: "";
                                            left: -5.6px;
                                            width: 1px;
                                            height: 0px;
                                            border-right: 6px solid $black;
                                            border-top: 8px solid transparent;
                                            border-bottom: 8px solid transparent;

                                            @include small-only {
                                                font-size: 10px;
                                                border-top: 7px solid transparent;
                                                border-bottom: 7px solid transparent;
                                            }
                                        }
                                    }

                                    .nights {
                                        color: #7D7D7D;
                                        font-size: 14px;
                                        font-weight: 700;
                                        line-height: 1;
                                        margin: 0 0 10px;

                                        @include small-only {
                                            font-size: 10px;
                                        }
                                    }

                                    .cta.small {
                                        font-size: 20px;
                                        line-height: 1;
                                        padding: 0.4em 1.1em;

                                        @include small-only {
                                            font-size: 1em;
                                            line-height: 1;
                                            padding: 0.4em 1em;
                                        }
                                    }
                                }
                            }

                            .info__low-availability {
                                font-size: 14px;
                                color: #7A1C9C;
                                text-align: center;
                                line-height: 18px;
                                position: absolute;
                                bottom: 4px;
                                right: 0;
                                left: 0;

                                @include small-only {
                                    bottom: 2px;
                                    font-size: 10px;
                                }
                            }

                            .low-availability {
                                position: absolute;
                                display: block;
                                @include gradient($blue, "linear");
                                color: $white;
                                font-size: 0.75rem;
                                font-weight: 500;
                                padding: 0.6em;
                                border-radius: $radius;
                                bottom: 10px;
                                width: 140px;
                                margin: auto;
                                left: 0;
                                right: 0;
                            }

                            .special-offer {
                                left: 65px;
                                position: absolute;
                                top: -10px;
                                width: 40px;
                                height: 40px;
                                z-index: 1;

                                @include small-only {
                                    left: 40px;
                                    top: -8px;
                                    width: 30px;
                                }

                                > img {
                                    width: 100%;
                                }

                                > p {
                                    @include header-font;
                                    position: relative;
                                    color: $white;
                                    text-shadow: 1px 1px 1px rgba($black, 0.2);
                                    font-size: 0.8em;
                                    font-weight: 900;
                                    z-index: 1;
                                    padding: 0.2em 0.8em;
                                }

                                .tool-tip {
                                    top: 3.1em;
                                    width: 10.6em;
                                    left: -3.8em;
                                }
                            }
                        }

                        &:first-child .calendar-content {
                            margin-left: 0px;
                        }
                    }

                    th {
                        padding: 0 15px 1px;

                        @include small-only {
                            display: none;
                        }

                        .calendar-content {
                            //background: #ECECEC;
                            background: #D4D4D4;
                            border-radius: 10px 10px 0 0;
                            color: $black;
                            font-size: 14px;
                            font-weight: 400;
                            height: 62px;
                            padding: 22px 0;

                            &:first-letter {
                                text-transform: uppercase;
                            }
                        }

                        &.match .calendar-content {
                            background-image: linear-gradient(180deg, #B9DF23 0%, #76A70C 100%);
                            color: $white;
                            font-weight: 700;
                        }

                        &.past {
                            opacity: 0.5;
                        }
                    }

                    td {
                        height: 154px;
                        padding: 1px 15px;

                        @include small-only {
                            height: 128px;
                            padding: 5px 10px;
                        }

                        .calendar-content {
                            background-color: $white;
                            height: 100%;
                            position: relative;

                            @include small-only {
                                border-radius: 6px;
                            }
                        }

                        .calendar-content.booking-special-offer {
                            background-image: linear-gradient(180deg, #FFE24A 0%, #FFBD0F 99%);
                        }

                        &.perferctMatch .calendar-content {
                            background-clip: padding-box;
                            border: solid 5px transparent;

                            p.price {
                                color: $blue;
                            }

                            &:before {
                                background: linear-gradient(180deg, #479847, #406e54);
                                border-radius: inherit;
                                content: '';
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                z-index: -1;
                                margin: -5px;
                            }
                        }

                        &.past {
                            opacity: 0.5;
                        }
                    }
                }

                @include small-only {
                    .info {
                        @include clearfix;

                        .price-wrapper {
                            float: left;
                            margin: 25px 0 25px 20px;

                            .price {
                                font-size: 20px;
                            }
                        }

                        button.cta {
                            float: right;
                            margin: 30px 20px 25px 0;
                        }
                    }
                }

                p {
                    font-size: em-calc(15);
                    font-weight: 400;
                    margin: 0.3em 0;

                    &.price {
                        @include prim-font;
                        font-size: 1.5em;
                        font-weight: 600;
                        margin: 0;
                        text-shadow: none;
                        color: #000000;
                    }

                    &.price-before {
                        font-size: 0.8em;
                        text-decoration: line-through;
                        line-height: 0;
                        color: $black;
                    }
                }
            }
        }

        .house-calendar > .row > .column {
            @include small-only {
                padding: 0 !important;
            }
        }
    }

    .house-detail-collapse {
        &:before {
            content: none;
        }

        .ferie-huse-wrapper {
            overflow: hidden;
            @include transition('max-height .3s ease-in-out');
        }

        .info-accordion-head .info-arrow-hero:before {
            margin-right: 0;
            @include transform('rotate(90deg)', '.3s ease-in-out');
        }

        &.expanded {
            .info-accordion-head .info-arrow-hero:before {
                margin-right: 0;
                @include transform('rotate(-90deg)', '.3s ease-in-out');
            }

            .ferie-huse-wrapper {
                max-height: 900px;
            }
        }
    }
}

/* Overview of the booking Statusbar / Close booking */
.booking-summary {
    position: relative;

    &__closing {
        cursor: pointer;
    }

    .booking-deeplink {
        position: absolute;
        top: 50px;
        right: 0;
    }
}

.booking-summary-bar {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: unset;
    height: 50px;
    width: 100vw;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    @include medium-up {
        height: 60px;
    }

    .booking-summary__closing {
        background: white;
        border: none;
        cursor: pointer;
        left: 0;
        position: absolute;
        top: 0;
        z-index: 5;
    }

    p {
        margin-left: 70px;
        margin-top: 0;

        @include medium-up {
            margin-left: 80px;
        }
    }
}

#bookingPanel .booking-flow section.choose-stay {
    //margin-top: 50px;

    @include medium-up {
        //margin-top: 60px;
    }
}