@import "vendor/foundation/functions";
@import "settings/all-settings";
@import "vendor/all-vendor";
@import "plugins/all-plugins";
@import "global/all-global";
@import "partials/all-partials";

@-ms-viewport{
    zoom: 1.0;
    width: device-width;
   user-zoom: fixed
}

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

/*//DEBUG
*{
	outline: 1px solid red;
}*/