﻿@mixin navigation-list ($type) {
	@if $type == 'left' {
		float: left;
	} @else if $type == 'center' {
		text-align: center;
	} @else if $type == 'right' {
		float: right;
	}
    list-style-type:none;
    padding:0;
    margin:0;
    > li{
		@if $type == 'left' or $type == 'right'  {
			display:block;
			float:left;
		} @else if $type == 'center' {
			display: inline-block;
		} @else if $type == 'vertical' {
			display:block;
		}
    }
}