

.teaser-text {
    .link-with-large-button + .link-with-large-button {
        margin-left: 1rem;
    }

    .button + .button {
        margin-left: 1rem;
    }
}

.grouping-block {
    margin-top: 30px;
    //margin-bottom: 60px;

    @include medium-up {
        margin-top: 60px;
    }

    /*@include large-up {
        margin-top: 45px;
    }
    @include xxlarge-up {
        margin-top: 60px;
    }*/
}