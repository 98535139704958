.confirm-dialog {
    color: $black;
    //@include gradient(($white, $booking-grey), 'linear');
    background: #ececec;
    overflow: hidden;

    a:not(.button):hover {
        text-decoration: none;
    }

    a:not(.cancelButton) {
        font-size: 0.85rem;
        font-weight: 400;
        color: $black;

        .no-touch &:hover {
            color: $black;
        }
    }

    a:not(.cancelButton).tooltip {
        font-size: 1.3em;
    }

    p.small {
        font-size: 0.85rem;
        font-weight: 400;
    }

    @include small-only {
        .tool-tip {
            max-width: 220px;
        }
    }

    .confirm-dialog-header {
        height: rem-calc(100);

        @include medium-up {
            height: rem-calc(270);
        }
    }

    .confirm-dialog-inner {
        @include medium-up {
            margin: $column-gutter;
        }

        &.optional {
            @include transition('margin .6s ease-in-out');
            margin-top: -100px;
            //margin-top: -30px;
            @include medium-up {
                margin-top: 30px;
            }

            &.move-up {
                margin-top: -140px;

                @include medium-up {
                    margin-top: -220px;
                }
            }
        }

        .priceDisclaimer {
            text-align: center;
        }
    }

    .confirm-dialog-summary-wrapper {
        width: rem-calc(710);
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $column-gutter;

        @include small-only {
            max-width: 90vw;
            margin-top: 60px;
        }

        .confirm-dialog-summary {
            //@include gradient(($booking-grey, darken($booking-grey, 3%)), 'linear');
            color: $black !important;
            background: white;

            .sub-header {
                text-align: center;
                color: $black;

                @include medium-up {
                    display: block !important;
                }
            }

            border-radius: $radius;

            @include medium-up {
                margin: $column-gutter/2;
            }
            /* IE10+ */
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                margin: $column-gutter/2 0 !important;
            }

            &__included {
                display: flex;
                align-items: center;
                margin-top: 22px;
                /* IE10+ */
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    max-width: 90%;
                }

                span {
                    min-width: 26px !important;
                    width: 26px;
                    height: 26px;
                    background-image: linear-gradient(180deg, #3C99D8 0%, #006EB9 100%);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        color: white;
                    }
                }

                p {
                    color: $black;
                    text-align: left;
                    margin: 0 0 0 15px;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    .optional-wrapper p.sub-header {
        text-align: center;
        margin: 40px 10px;
    }

    .optional {
        text-align: left;

        .panel {
            text-align: left;
        }
    }

    .panel-content {
        .small {
            text-align: center;
        }
    }

    .optional-selections {
        @include small-only {
            font-size: 0.7rem;
        }

        width: 100%;
        font-weight: 500;
        border-bottom: 1px solid $light-grey;

        tr {
            border-bottom: 1px solid #ECECEC;

            @include small-only {
                width: 100%;
                display: block;
            }

            &:first-child {
                border-top: 1px solid #ECECEC;
            }

            &:last-child {
                padding-bottom: 40px;

                @include small-only {
                    padding-bottom: 0;
                }
            }

            td {
                width: 50%;
                padding: $column-gutter/2;

                @include small-only {
                    width: 15%;
                }

                &.name {
                    width: 50%;
                    text-align: left;

                    @include small-only {
                        font-size: 0.8rem;
                        width: 100%;
                        display: block;
                        padding-bottom: 0;
                    }

                    .icon {
                        width: 50px;
                        height: 50px;
                        max-width: none;


                        @include small-only {
                            display: none;
                        }

                        padding: 0 $column-gutter/4;
                        color: $nav-darkgrey;
                    }
                }

                &.name span.highlighted {
                    font-weight: bold;
                }

                &.price {
                    width: 100%;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @include small-only {
                        justify-content: space-between;
                    }

                    p {
                        padding-right: 20px;
                        margin: 0;
                    }

                    label {
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: 400;

                        span {
                            font-weight: 500;
                        }
                    }
                }

                &.qty-count {
                    padding: $column-gutter/4;
                    font-size: 2.5em;
                    font-weight: 900;
                    color: $dark-grey;
                }
            }
        }
    }

    .cleaning-checkbox-error {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;

        p.small {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .note {
        p {
            text-align: left;
        }

        .panel:not(.cancellation-order) {
            padding-top: 0;
        }
    }

    .confirm-action {
        text-align: center;

        .total {
            font-size: 1.3em;
            font-weight: 900;
        }
    }

    .cancellation-order {
        margin-bottom: 15px;

        &__header-text {
            text-align: center !important;
            font-weight: 600 !important;
            font-size: 20px;
        }

        .small .price {
            display: block;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 500;
        }

        &__buttons {
            button {
                height: 30px;
                border-radius: 29px;
                font-size: 12px;
                letter-spacing: 2.08px;
                text-align: center;
            }

            .button-grey:not(.selected) {
                background-image: $bookingflow-new-btn-grey-gradient;
                box-shadow: 0 2px 0 0 rgba(0,0,0,0.30);
                color: #878787 !important;
            }

            .button-orange:not(.selected) {
                background-image: $bookingflow-new-btn-orange-gradient;
                box-shadow: 0 2px 0 0 #844A17;
                text-shadow: 0 1px 0 rgba(0,0,0,0.22);
                color: #FFFFFF;
            }
        }

        .row.collapse {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .house-calendar__extras-next:not(.selected) {
        background-image: $bookingflow-new-btn-grey-gradient;
        box-shadow: 0 2px 0 0 rgba(0,0,0,0.30);
        color: #878787 !important;

        &:hover {
            background-image: $bookingflow-new-btn-grey-gradient !important;
            box-shadow: 0 2px 0 0 rgba(0,0,0,0.30) !important;
            color: #878787 !important;
            cursor: default;
        }
    }
}
