.mini-shop {
    @extend .spot-block;

    .guest-group-amount {
        display: none;
    }

    .row {
        @include medium-up {
            display: flex;
            flex-direction: row;

            &:before {
                content: none !important;
            }
        }
    }

    .panel {
        text-align: left;

        &.height500 {
            @include medium-up {
                height: 100%;
                min-height: 500px;

                &:before {
                    content: none;
                }
            }
        }
    }

    .checkbox label {
        background-color: #e8e8e8;
    }

    a.payment-terms {
        text-decoration: underline;
    }

    .panel:not(.white) .checkbox, .panel:not(.white) .radio {
        label {
            background: rgba($black, 0.2);
        }
    }

    .panel-content {
        max-width: 100%;

        .highlighted-text {
            font-weight: 700;

            i {
                display: block;
                font-size: 5em;
                margin-bottom: 25px;
            }
            .cta {
                margin-top: 35px;
            }
        }
    }

    .order {
        overflow: hidden;

        .selectric {
            background: $bg-grey;
            box-shadow: none;
            margin-top: 8px;
        }

        .selectricItems {
            &:before {
                border-color: transparent transparent darken($bg-grey, 3%) transparent;
            }

            .selectricScroll {
                background: darken($bg-grey, 3%);
            }

            li:not(.selected):hover {
                &:hover {
                    background: darken($bg-grey, 6%);
                }
            }
        }

        .panel-content {
            display: block;
            text-align: center;

            p.small, label {
                font-size: 1.3em;
                font-weight: 300;
            }

            p.strong {
                text-align: left;
                padding-left: 30px;
            }

            label {
                font-size: 1.1rem;
                display: block;
                text-align: right;
                margin: 0.5em 0;

                @include small-only {
                    margin-bottom: 0;
                    text-align: left;
                }
            }
        }

        &.ng-enter,
        &.ng-leave {
            @include transition('max-height .6s ease-in-out');
        }

        &.ng-enter,
        &.ng-leave-active {
            max-height: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            max-height: 450px;
        }
    }

    input.qty {

        @include small-only {
            width: 35px;
            height: 25px;
            padding: 5px;
            font-size: 14px;
            font-weight: 300;
        }

        @include medium-up {
            margin: auto;
            display: block;
        }
    }

    textarea.peronalGreeting {
        height: 100px;
    }



    .checkbox-wrapper {
        margin: $column-gutter/2;
        font-size: 0.75em;

        label {
            display: inline !important;
        }
    }

    .summary-wrapper {
        @include clearfix;

        .summary {
            margin: auto;
        }

        &.multi {
            .summary {
                float: left;
                width: calc(50% - 20px);
                margin: 10px 10px 10px 0;
            }
        }
    }

    &__block {
        @include medium-up {
            display: flex;
            flex-direction: row;
        }

        .panel {
            @include medium-up {
                height: 100%;

                &:before {
                    content: none;
                }
            }
        }

        .image {
            min-height: 230px;

            @include medium-up {
                min-height: 500px;
            }
        }

        h2 {
            @include small-only {
                font-size: 20px;
                line-height: 22px;
            }
        }
    }

    .product-list {
        width: 100%;
        margin-top: $column-gutter;

        tr {
            &:last-child {
                //border-top: 1px solid rgba($black, 0.1);
                p {
                    padding-bottom: 0;
                }
            }

            td {
                vertical-align: top;

                @include small-only {
                    font-size: 14px;
                }

                p {
                    @include small;
                    padding: 0.8em 0;
                }
            }
        }


        tr:first-child,
        tr:last-child {
            td {
                border-bottom: none;
            }
        }

        tr:nth-child(2) {
            span.strong {
                margin-top: 5px;

                @include medium-up {
                    margin-top: 0;
                }
            }
        }

        tr.product-list__item {
            td {
                padding-bottom: 10px;

                &:last-child {
                    text-align: right;
                }
            }
        }

        tr.product-list__item,
        tr.delivery,
        tr.total {
            width: 100%;

            td {
                &:nth-child(1) {
                    width: 10%;
                    min-width: 100px;
                }

                &:nth-child(2) {
                    min-width: 65px;
                    width: 5%;

                    @include small-only {
                        min-width: 50px;
                    }
                }

                &:nth-child(3) {
                    width: 55%;

                    @include medium-up {
                        width: 45%;
                    }

                    @include xlarge-up {
                        width: 55%;
                    }
                }

                &:nth-child(4) {
                    width: 30%;
                    text-align: right;

                    @include medium-up {
                        width: 20%;
                    }

                    @include xlarge-up {
                        width: 25%;
                    }
                }
            }
        }

        tr.delivery,
        tr.total {
            td {
                &:nth-child(n+3) {
                    border-top: 2px solid rgba(#FFFFFF, 0.2);
                }
            }

            span.strong {
                display: block;
                margin-top: 15px;
                margin-bottom: 15px;
            }
        }
    }

    button.cta:not([type='submit']) {
        display: block;
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .sub-header {
        margin: 10px 0;
        font-size: 22px;
        line-height: 1.2;
        font-weight: 300;

        @include medium-up {
            font-size: 30px;
        }
    }

    .small {
        line-height: 1.25;
    }

    /**
    * GOLD BRACELET MINISHOP
    **/
    &.mini-shop--gold-bracelet {
        h3,
        p,
        .sub-header {
            text-align: left;
        }
        .sub-header {
            font-size: 1rem;
            font-weight: 700;
        }
        .lookup-form {
            .error {
            }
        }
        .product-list {
            tr.product-list__item {
                td:nth-child(1) {
                    width: 70px;
                    min-width: 50px;
                    padding-right: 20px;

                    @include small-only {
                        width: 35px;
                        min-width: 35px;
                    }
                }
                td:nth-child(2) {
                    width: auto;
                    text-align: left;
                    padding-right: 20px;
                    vertical-align: middle;
                }
                td:nth-child(3) {
                    width: 20%;
                    text-align: right;
                    white-space: nowrap;
                    vertical-align: middle;
                }
            }
            tr.total {
                td:nth-child(1) {
                    width: 70px;
                    min-width: 50px;

                    @include small-only {
                        width: 35px;
                        min-width: 35px;
                    }
                }
                td:nth-child(2) {
                    width: auto;
                    text-align: left;
                    border-top: 2px solid rgba(255, 255, 255, 0.2);
                    padding-right: 20px;
                }
                td:nth-child(3) {
                    width: 20%;
                    text-align: right;
                    white-space: nowrap;
                }
            }
        }
    }
}
