footer {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 16%;

    @include small-only {
        background-image: none !important;
    }

    .temp-bg {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
    }

    text-align: left;

    .footer-inner {
        @include transition(opacity .3s ease);
        background: rgba($black, 0.7);
    }



    li {
        font-weight: 300;
        line-height: 1.8rem;

        @include medium-only {
            font-size: 0.9rem;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    h3 {
        text-align: left;
        margin: 0;

        @include medium-only {
            font-size: 2rem;
        }

        .light-grey {
            color: $plus45;
        }
    }

    .phone-no {
        @include prim-font;
        font-weight: 900;
        font-size: 1.5rem;
        white-space:nowrap;

        &.small {
            font-size: 16px !important;
            display: inline;
            line-height: 1.8 !important;
        }

        @include medium-only {
            font-size: 1.4rem;
        }
    }

    button, .button {
        margin-top: 0.8rem;
    }

    .margintop20 {
        margin-top: 20px;
    }

    &.footer {
        @include medium-up {
            .footer-inner {
                background: rgba(32, 32, 32, 0.8);
            }
            .footer-top {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding-top: 2rem;

                .permission-signup {
                    order: 1;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-bottom: 10px;
                    margin-bottom: 40px;
                    border-bottom: 1px solid white;

                    .accordion-head {
                        font-size: 24px;
                        line-height: 1;
                        margin: 8px 0 0;
                    }
                    form {
                        display: flex;

                        .signup-permission-field {
                            max-width: 185px;
                            margin: 0 15px 0 0;

                            &.signup-permission-field--btn {
                                max-width: 165px;
                                margin-right: 0;

                                button {
                                    font-size: 14px;
                                    padding: 8px 15px;
                                    white-space: nowrap;
                                    margin: 0;
                                    min-width: 165px;
                                }
                            }
                            .error {
                                margin-top: 5px;
                            }
                        }
                        input[type="text"],
                        input[type="email"] {
                            border-radius: 1.25em;
                            margin: 0;
                        }
                    }
                    .gdpr-disclamer-link {
                        font-size: 12px;
                        line-height: 14px;
                        color: #9F9F9F;
                        margin: 10px 0 0;
                        text-align: right;

                        a {
                            text-decoration: underline;
                            color: #9F9F9F;
                        }
                    }
                }
                > .columns {
                    float: none;

                    &:nth-child(1) {
                        order: 2;
                    }
                    &:nth-child(2) {
                        order: 3;
                    }
                    &:nth-child(3) {
                        order: 4;
                    }
                    &:nth-child(4) {
                        order: 5;
                    }
                    &:nth-child(5) {
                        order: 1;
                        margin-right: 20px;
                    }
                    &:nth-child(6) {
                        order: 6;
                    }
                }
                .footer__link-group {
                    width: 18%;

                    @media only screen and (max-width: 1024px) {
                        width: 25%;
                    }
                }
                .footer__image {
                    margin-bottom: auto;
                    width: 26%;
                }
            }

            //Subfooter
            .sub-footer {
                padding-bottom: 0;

                .sub-footer__inner {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .sub-footer__map {
                    width: auto;
                }
                .sub-footer__info {
                    width: auto;
                    @media only screen and (max-width: 1024px) {
                        width: 33%;
                    }
                }
                .sub-footer__resort-header {
                    font-weight: 700;
                }
                .sub-footer__resort-info {
                    color: #bfc0c0;
                }
                .phone-no {
                    margin-bottom: 7px;
                    color: #bfc0c0;

                    span {
                        color:white;
                    }
                }
                .footer-map {
                    height: auto;
                    width: 289px;
                    margin: auto;
                }
                .footer__book {
                    width: calc(100% + 60px);
                    display: flex !important;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 40px;
                    margin-left: -30px;
                    background-color: white;
                    border-radius: 0 0 10px 10px;
                    padding: 23px 33px;

                    .footer__book-headline {
                        align-self: center;
                        padding-right: 30px;
                        font-size: 24px;
                        line-height: 1;
                        color: black;
                    }
                    .footer__book-text {
                        align-self: center;
                        padding-right: 30px;
                        color: #828282;
                        font-size: 24px;
                        line-height: 1;
                        font-weight: 300;

                        @media only screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                    .phone-no {
                        align-self: center;
                        padding-right: 30px;
                        margin: 0;
                        color: black;

                        a {
                            color: black;
                        }
                        span {
                            color: #828282;
                        }
                    }
                    .button {
                        flex-shrink: 0;
                        min-width: 0;
                        margin: auto 0;
                        padding-left: 30px;
                        padding-right: 30px;
                        font-size: 20px;
                    }
                }
            }
        }

        @include small-only {
            .permission-signup {
                button {
                    width: 100%;
                    padding: 10px 0;
                }
                .gdpr-disclamer-link {
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 14px;
                    color: #9F9F9F;

                    a {
                        display: inline;
                        color: #9F9F9F;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.alignc {
    text-align: center;
}

.footer-top {
    padding-top: 3rem;

    @include small-only {
        padding-top: 1rem;
    }

    @include medium-only {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.footer-map {
    height: 250px;
}

.border-top {
    border-top: solid 1px rgba($white, 0.2);
    padding-bottom: 20px;
}

.sub-footer {
    @include transition(opacity .3s ease);
    background: rgba($black, 0.6);
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    width: calc(100% - 20px);

    @include large-up {
        width: 100%;
    }

    @include medium-up {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 40px;
        padding-bottom: 30px;
        width: calc(100% - 20px);
        margin-top: 60px;
    }

    @include small-only {
        text-align: center;
    }
}
div.sub-footer__resort {

    @include small-only {
        padding-top: 30px;

        &:nth-of-type(2) {
            padding-top: 0;
        }
    }
}

.sub-footer__resort-header {
    @include small-only {
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 10px;
    }
}

.sub-footer__resort-info {
    @include small-only {
        li {
            font-size: 12px;
            line-height: 20px;

            a {
                font-size: 14px;
            }
        }
    }
}
.sub-footer__service-phone {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

.sub-footer__phone-list {
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
}

.sub-footer__phone-list__title {
    font-weight: 400;
    line-height: 30px;
}

.sub-footer__phone-list__item {
    td {
        line-height: 25px;

        &:nth-child(even) {
            text-align: right;
            font-weight: 400;
        }
    }
}

.sub-footer__cta {
    .cta {
        height: 48px;
        margin-top: 20px;
        span {
            display: block;
            margin-top: 9px;
        }
    }
}

.footer-title {
    padding-bottom: 1rem;
}

.list-phone-small {
    padding-bottom: 1.2rem;
}

.trustpilot-small {
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;

    @include medium-only {
        margin-top: 45px;
    }
}

.icon-white-bg {
    color: $black;
    background: $white;
    padding: 5px;
    border-radius: 50%;
    margin-right: 5px;
}


.icon-round {
    border: none;
    border-radius: 50%;
    -webkit-background-clip: border-box;
    padding: 0.8em;

    @include small-only {
        padding: 0.7em;
    }
}

.social {
    padding-right: 40px;
    padding-left: 15px;
    padding-bottom: 40px;
    margin-bottom: 0;

    @include small-only {
        padding: 0 0 30px;
        margin-top: 40px;
        text-align: center;
    }

    @include medium-only {
        padding-right: 0;
        padding-left: 0;
    }

    .social-icons {
        margin-top: 54px;

        @include small-only {
            margin: 0;
        }
    }

    a:not(.button):hover {
        text-decoration: none;
    }
}

.footer-logo {
    height: 86px;
    width: 183px;
}


.i-facebook {
    @include gradient($facebook, 'linear');
    box-shadow: 0 2px darken(nth($facebook, 2), 10%);
    text-shadow: 0 1px 1px rgba($black, 0.3);
    margin-right: 10px;

    @include small-only {
        font-size: 1.6rem;
    }

    @include medium-only {
        margin-right: 5px;
    }
}

.i-instagram {
    @include gradient($orange, 'linear');
    box-shadow: 0 2px darken(nth($orange, 2), 10%);
    text-shadow: 0 1px 1px rgba($black, 0.3);
    margin-right: 10px;

    @include small-only {
        font-size: 1.6rem;
    }

    @include medium-only {
        margin-right: 5px;
    }
}

.i-youtube {
    @include gradient($youtube, 'linear');
    box-shadow: 0 2px darken(nth($youtube, 2), 10%);
    text-shadow: 0 1px 1px rgba($black, 0.3);
    margin-right: 10px;

    @include small-only {
        font-size: 1.6rem;
    }

    @include medium-only {
        margin-right: 5px;
    }
}

.i-newsletter {
    @include gradient($newsletter, 'linear');
    box-shadow: 0 2px darken(nth($newsletter, 2), 10%);
    text-shadow: 0 1px 1px rgba($black, 0.3);

    @include small-only {
        font-size: 1.6rem;
    }
}



.last-column {

    li {
        text-align: center;
    }

    h3 {
        text-align: center;
        line-height: 2.2rem;
    }
}

.break {
    padding-bottom: 20px;
}

.break10 {
    padding-bottom: 10px;
}

.break30 {
    padding-bottom: 30px;
}

/*Accordian*/

.accordion {

    @include small-only {
        font-size: rem-calc(18);
        padding: 0;

        &:last-of-type {
            border-bottom: 0;
        }
    }

    .accordion-head {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        padding-bottom: 0.7em;
        font-weight: bold;
        text-transform: uppercase;

        @include small-only {
            font-size: inherit;
            padding-bottom: 1em;
            padding-top: 1em;
            margin-left: 2em;
            margin-right: 2em;
            border-bottom: solid 1px rgba($white, 0.2);
        }
    }

    .accordion-body {
        height: auto;
        display: none;

        @include medium-up {
            display: block;
        }

        @include small-only {
            margin-left: 2em;
            margin-right: 2em;

            ul {
                padding-bottom: 0.5em;
            }

            a {
                display: block;
                font-size: inherit;
                padding-bottom: 0.5em;
                padding-top: 0.5em;
            }
        }
    }
}

.arrow {
    @extend %icon-font;
    @extend .icon-plus;

    .open & {
        @extend .icon-minus;
    }

    &:before {
        float: right;
        color: $white !important;
    }

    @include medium-up {
        display: none;
    }
}


.noborder {
    border: none !important;
}





.show {
    display: block;
}

.privacy-wrapper {
    margin-top: rem-calc(40);

    @include medium-up {
        height: rem-calc(35);
        position: relative;
        margin-top: rem-calc(0);
    }

    @include large-up {
        height: rem-calc(25);
    }
}

.privacy-link {
    font-weight: 100 !important;
    margin: 0 auto;
    font-size: rem-calc(12);
    color: rgba($white, 0.5);

    @include medium-up {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }

    p {
        font-size: rem-calc(12);
        color: rgba($white, 0.5);
    }

    a {
        text-decoration: underline !important;
        color: rgba($white, 0.5);
    }
}

.gdpr-disclamer {
    margin-top: 1rem;
}
