﻿.giftcard-list {
    .radio-list {
        margin: rem-calc(10) 0;
    }

    .radio-border {
        border-bottom: solid 1px $grey;
    }

    .richtext {
        margin: rem-calc(10) 0;
    }

    .amountInput {
        position: absolute;
        top: rem-calc(-10);
        font-size: rem-calc(14);

        .input-currency {
            position: absolute;
            top: 16px;
            right: 15px;
            color: $grey;
            background: $white;
            padding-left: rem-calc(10);
        }
    }

    .checkbox {
        &.row {
            width: 96% !important;
            display: inline-block;
        }
    }

    .checkbox-wrapper {
        margin-left: 0 !important;
        float: left;
    }
}


.orderform {

    p:not(.label) {
        padding: 0 !important;
    }
}

.second-column-space {
    padding-left: rem-calc(20);
}


.giftcard-subtext {
    p {
       padding-top: rem-calc(12);
        font-size: rem-calc(11);
        padding-left: rem-calc(33);
    }
}


