﻿.fixed-price-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

.fixed-price {
    position: relative;
    //@include gradient(($bg-grey, $light-grey), '50% 50%, circle closest-side');
    background-color: #ececec;
    padding: 0;
    z-index: 4;

    @include small-only {
        padding-bottom: 20px;
    }

    @include large-up {
        min-height: rem-calc(1000);
    }

    section {
        min-height: 0 !important;
    }

    .confirm-dialog {
        background: #ececec;
    }

    .confirm-dialog-inner {
        margin-top: -100px;

        @include medium-up {
            margin-top: -150px;
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 0;
        }
    }

    .confirm-dialog .confirm-dialog-summary-wrapper {
        margin-bottom: 70px;

        @include small-only {
            margin-bottom: 30px;
        }
    }

    .confirm-dialog-steps {
        background-color: $white;

        .feriehuse {
            @include small-only {
                background-color: $white;
            }
        }

        .main-steps-header {
            margin-top: 60px;

            @include small-only {
                margin-top: 30px;
            }

            i {
                margin-left: 5px;
            }
        }

        .information-wrapper {
            margin-top: 0;

            @include medium-up {
                width: 23% !important;
            }
        }

        .error {
            //font-size: 10px !important;
            //margin-top: 10px !important;
            //text-align: center !important;
        }

        button[type="submit"] {
            margin: 70px 0;

            @include small-only {
                margin: 20px 0;
            }
        }

        .cancellation-order__btn:not(.selected) {
            background-image: linear-gradient(180deg, #F9F9F9 0%, #ddd 99%);
            box-shadow: 0 2px 0 0 rgba(0,0,0,0.3);
            color: #878787 !important;

            &:hover {
                background-image: linear-gradient(180deg, #F9F9F9 0%, #ddd 99%) !important;
                cursor: default;
            }
        }

        .cancellation-order__link {
            font-size: 16px;

            @include small-only {
                font-size: 12px;
            }
        }

        .checkout .checkbox label:after {
            @include gradient($green, 'linear');
            border-top: none;
            border-right: none;
        }
    }

    .payment-info table.summary {
        background-color: white;
    }

    .radio label {
        background: $white;
        border: 1px solid $light-grey;

        &:after {
            background: #ececec;
        }
    }

    .feriehuse .house-detail-collapse__see-more a {
        color: $white;
    }

    .feriehuse .house-detail-collapse__h3-seemore h3 {
        color: $black;
    }

    .checkbox-upgrate {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        width: 90%;
        margin: 20px 5%;

        label {
            font-weight: 500;
        }
    }

    .checkbox-green-dot label {
        &:after {
            border: 3px solid #B9DF23;
            border-top: none;
            border-right: none;
        }
    }

    .checkbox-green .radio label {
        background: #ececec;

        &:after {
            @include gradient($green, 'linear');
        }
    }

    .checkbox-green-dot .radio label {
        &:after {
            @include gradient($green, 'linear');
            border: none;
            top: 4px;
            left: 4px;
        }
    }

    .feriehuse-info-expander {
        a span {
            text-decoration: underline;
        }
    }

    .checkbox-upgrate {
        @include small-only {
            flex-direction: column;
            border-bottom: 1px solid #ececec;
            padding-bottom: 10px;
            margin-bottom: 0;
        }

        .checkbox-wrapper {
            flex-direction: column;
            display: flex;
            flex-direction: row;
            flex-basis: 50%;
            margin-left: 10px;

            @include medium-up {
                margin-left: 30px;
            }

            .radio {
                width: 10px;
                margin: 10px 0;
            }

            .checkbox {
                width: 100%;
                text-align: start;
                margin: 0;

                @include small-only {
                    width: 100vw;
                }

                p {
                    font-weight: 600;

                    span {
                        font-weight: 400;

                        @include small-only {
                            margin-left: 15px;
                        }

                        @include medium-up {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .house-detail-collapse {
        background-color: $white;
        color: $black;
    }

    .house-detail-collapse__detail .feriehuse {
        border-radius: 8px 8px 0 0;
    }

    .checkbox-upgrate {
        @include medium-up {
            border: 1px solid #ececec;
            margin-top: 0;
            padding: 20px 0;
        }
    }

    .house-detail-collapse__detailed__first-info .first-small-info-each p {
        color: $black;
    }

    .house-detail-collapse__detailed-info.feriehuse-info-expanded {
        p, h3 {
            color: $black;
        }

        .blue-circle i {
            color: $white;
        }
    }
}

form[name="step2form"] {
    button {
        margin-bottom: 10px !important;
    }
}

.fixed-price__title {
    font-size: 20px;
    line-height: 20px;

    @include medium-up {
        font-size: 30px;
        line-height: 30px;
    }

    @include large-up {
        font-size: 50px;
        line-height: 50px;
    }
}

.fixedpricebtn {
	text-align: left;

	@include large-up {
			text-align: right;
	}
}

.birthday-booking {
	margin-top: 30px;
	@include large-up {
		margin-top: 90px;
	}
	h2 {
		@include prim-font;
		font-weight: 300;
		text-transform: none;
		font-size: rem-calc(20);
		margin-bottom: rem-calc(25);
		@include medium-up {
			margin-bottom: rem-calc(50);
			font-size: rem-calc(36);
		}
	}
	.input-wrapper {
		margin-bottom: rem-calc(15);
		@include medium-up {
			display: inline-block;
			margin-bottom: 0;
			&:not(:last-child) {
				margin-right: rem-calc(30);
			}
		}
		&.fixed {
			width: auto;
			@include medium-up {
				width: rem-calc(260);
			}
		}
		.label {
			font-size: rem-calc(12);
		}
	}
}
.birthday-booking-header {
    background-color: $lightest-grey;
    margin-bottom: 0;

    @include medium-up {
        border-radius: $radius $radius 0 0;
    }
}

.birthday-booking__group-size {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: rem-calc(25) !important;
	@include medium-up {
		padding-top: rem-calc(50) !important;
	}
}
.birthday-booking-tabs {
	display: flex;
	justify-content: center;
}
.birthday-booking-tab {
    padding: rem-calc(20);
    text-align: center;
    color: $black;
    cursor: pointer;
    background-color: #D4D4D4;
    margin-right: 5px;
    border-radius: 6px 6px 0 0;

    @include medium-up {
        padding: rem-calc(20) rem-calc(30);
    }

    &.is-active {
        color: $black;
        background-color: $white;
        border-radius: 6px 6px 0 0;
    }
}
.birthday-booking-tab__number {
	font-weight: bold;
	font-size: rem-calc(16);
	display: block;
	@include medium-up {
		display: inline;
	}
}
.birthday-booking-tab__text {
	font-size: rem-calc(10);
	display: block;
	@include medium-up {
		font-size: rem-calc(16);
		font-weight: bold;
		display: inline;
		text-transform: uppercase;
	}
}
.birthday-booking__time-of-week {
	margin-top: 0;
}
.birthday-booking__offers {
	background-color: $white;
	margin-top: 0;
	padding-bottom: rem-calc(140);
	padding-top: rem-calc(15) !important;
	@include medium-up {
		padding-top: rem-calc(40) !important;
	}
}
.birthday-booking-offer {
	color: $black;
	margin-bottom: rem-calc(15);
	text-align: left;
}
.birthday-booking-offer__wrapper {
	@include medium-up {
		height: rem-calc(80);
		display: flex;
	}
}
.birthday-booking-offer__top {
	background: linear-gradient(180deg, #FFE24A 0%, #FFBD0F 100%);
	padding: rem-calc(15);
	border-radius: 6px 6px 0 0;
	position: relative;
	@include medium-up {
		border-radius: 6px 0 0 6px;
		flex-basis: 50%;
		padding-left: rem-calc(25);
	}
	@media only screen and (min-width: 1200px) {
		display: flex;
		justify-content: flex-start;
		align-items: center;
        flex-basis: 60%;
	}
	.birthday-booking-offer--message & {
		//border-radius: 6px 0 0 0;
	}
}
.birthday-booking-offer__badge {
	width: rem-calc(80);
	height: rem-calc(16);
	border-radius: 0 6px 0 6px;
	background: linear-gradient(180deg, #A53CCB 0%, #7A1C9C 100%);
	font-weight: bold;
	font-size: rem-calc(10);
	text-align: center;
	color: $white;
	text-transform: uppercase;
	position: absolute;
	top: 0;
	right: 0;
	line-height: 16px;
	@include medium-up {
		border-radius: 0 0 0 6px;
	}
}
.birthday-booking-offer__date {
	font-weight: bold;
	font-size: rem-calc(16);
	@include medium-up {
		margin-right: rem-calc(15);
	}
}
.birthday-booking-offer__days {
	font-size: rem-calc(10);
	@include medium-up {
		font-size: rem-calc(16);
	}
}

.birthday-booking-offer__bottom {
    border-radius: 0 0 6px 6px;
    background-color: #ECECEC;
    padding: rem-calc(15);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include medium-up {
        flex-basis: 50%;
        border-radius: 0 6px 6px 0;
        padding-left: 40px;
    }

    @media only screen and (min-width: 1200px) {
        flex-basis: 40%;
    }

    .birthday-booking-offer--message & {
        //border-radius: 0 6px 0 0;
    }
}

.big-house-sold-out {
    .birthday-booking-offer__top {
        @include medium-up {
            border-radius: 6px 0 0 0px;
        }
    }

    .birthday-booking-offer__bottom {
        border-radius: 0;

        @include medium-up {
            border-radius: 0 6px 0px 0;
        }
    }
}

.birthday-booking-offer__price {
	font-size: rem-calc(14);
	@include medium-up {
		font-size: rem-calc(16);
	}
}
.birthday-booking-offer__amount {
	font-weight: bold;
}
.birthday-booking-offer__button {
	margin: 0;
}
.birthday-booking-offer__message {
    font-size: rem-calc(10);
    padding: 8px;
    line-height: 1.4;
    text-align: center;
    border-radius: 0 0 6px 6px;
    border-left: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    border-right: 1px solid #ececec;
    margin-top: 0;
}

#bookingPanel .fixed-price .overlay.out-of-stock-popup {
    @include medium-up {
        width: 100% !important;
        transform: none !important;
    }
}

.out-of-stock-popup {
    &__first {
        margin-bottom: 30px;
    }

    .modal-wrapper {
        height: 400px !important;
        min-height: 400px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .close-btn {
            top: 10px !important;
            right: 10px !important;
            color: $black !important;
        }
    }

    .modal-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        strong, span {
            font-size: 20px;
        }

        span {
            display: block;
        }

        button {
            max-width: 160px;
            margin: 30px 0;
        }
    }
}

.fixed-price-wrapper {
    //display: flex;
    //flex-direction: row;
    //width: 100%;
    .panel-content {
        width: 100%;

        .booking-summary-block {
            display: flex;
            flex-direction: column;
            width: 100%;

            @include medium-up {
                flex-direction: row;
            }

            .booking-summary-block__overview:first-child {
                @include small-only {
                    border-bottom: 1px solid #d2d2d2;
                    padding-bottom: 20px;
                }

                @include medium-up {
                    border-right: 1px solid #d2d2d2;
                    padding-right: 20px;
                }
            }

            .booking-summary-block__overview:last-child {
                @include small-only {
                    padding-top: 20px;
                    padding-bottom: 30px;
                }

                @include medium-up {
                    padding-left: 20px;
                    padding-bottom: 30px;
                }
            }
        }
    }
}

.booking-summary-block__overview {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    @include medium-up {
        width: 50%;
    }

    &__section {
        display: flex;
        justify-content: space-between;

        span {
            font-size: 14px;
        }

        .total.price {
            font-size: 1.2em;
            font-weight: 800;
            line-height: 1rem;
            right: 0;
        }
    }

    &:last-child &__section:last-child {
        position: absolute;
        bottom: 0;
        width: 100%;

        @include medium-up {
            width: calc(100% - 20px);
        }
    }

    &__section-extra {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        span {
            width: 50%;
            font-size: 14px;
            display: flex
        }

        span:nth-child(odd) {
            justify-content: flex-start;
            text-align: left;
        }

        span:nth-child(even) {
            justify-content: flex-end;
        }
    }
}

.betingelserne-overlay {
    text-align: left;
}

.step1Form {
    button {
        margin-bottom: 20px !important;
    }

    span {
        display: block;
        padding-bottom: 50px;
        text-decoration: underline;
        cursor: pointer;
        font-size: 12px;
    }
}

.overlay .modal-wrapper {
    .close-btn {
        top: 10px !important;
        right: 10px !important;
        color: $black !important;

        @include medium-up {
            right: 20px !important;
        }
    }
}