﻿.slider {
    position: relative;
    @include clearfix;
    @include transform('translateX(0)', '.3s ease-in-out');
    padding: 0;
    margin: 0;
    list-style: none;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    .outer-nav.article & {
        position: absolute;
    }

    li {
        position: relative;
        @include transition('opacity .3s ease-in-out');
        float: left;
        opacity: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &.active {
            opacity: 1;
        }

        &.video {
            cursor: pointer;

            &:after {
                @extend %icon-font;
                @extend .icon-play:before;
                position: absolute;
                top: 0;
                font-size: 6em;
                bottom: 0;
                height: 1em;
                margin: auto;
                text-align: center;
                left: 0;
                right: 0;
                backface-visibility: hidden;
            }

            &.playing {
                &:after {
                    display: none;
                }
            }
        }
    }

    .slider-item {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        margin: 0 7.5px;
        float: none;
        height: auto;

        @include medium-up {
            flex-direction: row;
        }

        @include large-up {
            margin: 0 15px;
        }
    }
}


.slider-wrapper {
    text-align: center;
}

.opening-hours-events {
    .slider-inner {
        @media (min-width: 690px) {
            &.contain {
                ul.slider.centered-image {
                    height: 100%;
                    li {
                        width: 100%;
                        img {
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}

.slider-inner {
    position: relative;

    .no-touch &:hover {
        .next-btn, .prev-btn {
            opacity: 1;
        }
    }

    .next-btn, .prev-btn {
        @include transition('opacity .2s ease-in-out');
        opacity: 0.7;
        position: absolute;
        //top: 0;
        top: 50%;
        //bottom: 0;
        transform: translateY(-50%);
        margin: auto;
        height: 1em;
        font-size: rem-calc(30);
        z-index: 1;
        cursor: pointer;
        backface-visibility: hidden;
        width: 1.1em;

        @include medium-up {
            font-size: rem-calc(40);
        }
    }

    .prev-btn {
        left: $column-gutter/4;

        @include medium-up {
            left: $column-gutter;
            left: 0;
        }
    }

    .next-btn {
        right: $column-gutter/4;

        @include medium-up {
            right: $column-gutter;
            right: 0;
        }
    }

    &.contain {
        height: 100%;
        overflow: hidden;
    }

    &.outer-nav {
        &.article {
            padding-bottom: 50%;

            @include large-up {
                padding-bottom: 40%;
            }
        }

        //@include large-up {
        @media (min-width: 1340px){
            .prev-btn {
                left: -$column-gutter*2;
            }

            .next-btn {
                right: -$column-gutter*2;
            }
        }
    }
}

.block-slider {

    .slider-inner {
        position: relative;
        margin: 0 auto;
        z-index: 2;

        @include small-only {
            width: calc(100vw - 45px);
        }

        @include medium-up {
            width: calc(100% - 60px);
            margin-bottom: 20px;
        }

        @media (min-width: 1100px) {
            // @include large-up {
            width: calc(100% - 40px);
            max-width: calc(1025px + 40px);
        }

        @include xxlarge-up {
            max-width: none;
        }

        ul {
            li {
                opacity: 1;
            }
        }
    }

    .slider-item {
        @include large-up {
            margin: 0 10px;
        }

        @include xlarge-up {
            margin: 0 20px;
        }
    }

    .panel {
        padding: 25px 30px;

        &:before {
            content: none;
        }

        @include small-only {
            flex: 1;

            &.image {
                //height: 177px;
                flex-basis: 160px;
                flex-grow: 0;
                flex-shrink: 0;
                background-color: $black;
            }
        }

        @include medium-up {
            flex-basis: 50%;
            min-height: 300px;
            padding: 30px;
        }

        @include xlarge-up {
            padding: 45px;
            min-height: 350px;
        }
    }

    .panel-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;

        @include medium-up {
            justify-content: center;
        }


        p {
            margin-top: 0;
            margin-bottom: 0;

            @include medium-up {
                flex-grow: 0;
                max-height: none;
            }

            .more {
                font-size: 24px;
                font-weight: 400;
                width: 14px;
                display: inline-block;
            }
        }

        .button:not(.offer-cta) {
            margin: 0 auto;
            margin-top: 25px;


            @include small-only {
                padding: 8.5px 20px;
                font-size: 14px;
                line-height: 1.1;
                margin-top: 10px;
            }
        }
    }

    .slider-item__header {
        margin-bottom: 10px;

        @include medium-up {
            margin-bottom: 15px;
        }
    }

    .bullet-wrapper {
        bottom: 0;

        @include medium-up {
            bottom: -20px;
        }
    }
}



.slider-with-grey-arrows {
    .prev-btn, .next-btn {
        i {
            color: $black;
        }
    }
}

.bullet-wrapper {
    display: inline-block;
    position: relative;
    bottom: 4em;

    ul.triggers {
        text-align: center;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin: 20px 0 0;


        @include medium-up {
            margin-top: 30px;
        }


        li {
            @include transition(background .3s ease-in-out);
            background: rgba($white, 0.4);
            margin: 0 5px 20px;
            cursor: pointer;
            text-indent: -9999px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            //float: left;
            display: block;
            min-height: auto;
            width: 10px;
            height: 10px;
            margin: 0 10px 15px;
            background-color: #202020;
            position: relative;

            @include medium-up {
                margin: 0 14px 15px;
            }

            &.active {
                background: rgba($white, 1);
                height: 16px;
                width: 16px;
                background: radial-gradient(circle, #5C5C5C 0%, #202020 100%);

                &:after {
                    content: "";
                    width: 30px;
                    height: 30px;
                    background: rgba(#202020, 0.4);
                    display: block;
                    border-radius: 50%;
                    position: absolute;
                    top: -7px;
                    left: -7px;
                    z-index: -2;
                }
            }
        }

        &.orange {
            li.active {
                background: radial-gradient(circle, #F49233 0%, #D43D08 100%);
            }
        }

        &.turkis {
            li.active {
                background: radial-gradient(circle, #4AD0E4 0%, #008A96 100%);
            }
        }

        &.blue {
            li.active {
                background: radial-gradient(circle, #4DA5E1 0%, #006EB9 100%);
            }
        }

        &.purple {
            li.active {
                background: radial-gradient(circle, #B246DA 0%, #781B9A 100%);
            }
        }

        &.green {
            li.active {
                background: radial-gradient(circle, #AACE36 0%, #76A70C 100%);
            }
        }

        &.yellow {
            li.active {
                background: radial-gradient(circle, #FFE44D 0%, #FFB502 100%);
            }
        }
    }
}
//artikelside slider
.article_slider {
    padding: 30%;
    background-repeat: no-repeat !important;
    height: 50%;
    background-position: center center !important;
    background-size: cover !important;

    .temp-bg {
        background-repeat: no-repeat !important;
        background-position: center center !important;
        background-size: cover !important;
    }

    @include large-up {
        padding: 0;
        height: 550px;
    }
}

.slider_text {
    background: rgba($black, 0.8);
    overflow: hidden;
    text-align: left;
    padding: rem-calc(20);
    position: absolute;
    bottom: 0;
    left: 0;

    @include small-only {
        font-size: rem-calc(12);
        padding: rem-calc(10);
        width: 100%;
    }
}

.next {
    position: absolute;
    right: 0;
    margin-top: rem-calc(16);
    margin-right: rem-calc(20);


    @include medium-up {
        top: 45%;
        right: 0;
        position: absolute;
        transform: translate(0%, -50%);
    }

    i {
        background: $grey;
        padding: rem-calc(15);
        border-radius: $roundradius;
        cursor: pointer;

        @include small-only {
            font-size: rem-calc(12);
            padding: rem-calc(10);
        }
    }
}

.prev {
    position: absolute;
    left: 0;
    margin-top: rem-calc(16);
    margin-left: rem-calc(20);

    @include medium-up {
        top: 45%;
        left: 0;
        position: absolute;
        transform: translate(0%, -50%);
    }

    i {
        background: $grey;
        padding: rem-calc(15);
        border-radius: $roundradius;
        cursor: pointer;

        @include small-only {
            font-size: rem-calc(12);
            padding: rem-calc(10);
        }
    }
}


.small-next {
    position: absolute;
    right: 0;
    top: rem-calc(5);
    margin-right: rem-calc(20);

    @include small-only {
        top: rem-calc(0);
    }


    i {
        background: transparent;
        padding: rem-calc(15);
        border-radius: $roundradius;
        border: solid 1px $white;
        cursor: pointer;

        @include small-only {
            padding: rem-calc(10);
            font-size: rem-calc(10);
        }
    }
}

.small-prev {
    position: absolute;
    left: 0;
    top: rem-calc(5);
    margin-left: rem-calc(20);

    @include small-only {
        top: rem-calc(0);
    }



    i {
        background: transparent;
        padding: rem-calc(15);
        border-radius: $roundradius;
        border: solid 1px $white;
        cursor: pointer;

        @include small-only {
            padding: rem-calc(10);
            font-size: rem-calc(10);
        }
    }
}
