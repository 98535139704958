.booking-summary-block {
    @include grid-row();
    width: auto;
    font-size: 0.8rem;

    @include medium-up {
        font-size: 1rem;
        display: flex;
    }

    dl {
        position: relative;
        @include grid-column($columns:12);

        @include medium-up {
            @include grid-column($columns:6);
            padding: 0 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        padding: 20px 0;
        margin: 0;

        &:first-child {
            padding-left: inherit;
            border-bottom: 1px solid $light-grey;

            @include small-only {
                padding-top: 0;
            }

            @include medium-up {
                border-bottom: none;
                border-right: 1px solid $light-grey;
            }
        }

        &:last-child {
            padding-right: inherit;
        }

        dt, dd {
            font-size: 14px;
            width: 50%;
            float: left;
            margin: 0;

            @include medium-up {
                align-self: flex-start;
            }
            &.total {
                margin-top: 1rem;
                line-height: 1rem;
                bottom: 0;

                &.price {
                    font-size: 1.2em;
                    font-weight: 800;
                    line-height: 1rem;
                    right: 0;
                }

                @include medium-up {
                    margin-top: 2rem;
                    align-self: flex-end;
                }
            }
        }

        dt {
            text-align: left;
        }

        dd {
            text-align: right;
        }
    }
}

.payment-visa {
    &.information {
        margin: 0;

        @include large-only {
            padding: 25px;
        }
    }

    img {
        width: 45%;
        max-width: 70px;
        vertical-align: bottom;
        margin-right: 10px;

        @include large-only {
            margin-right: 0px;
        }
    }

    .mastercard,
    .secureDankort {
        max-width: 75px;
        padding-top: 10px;
        padding-bottom: 2px;
        margin-left: 5px;
    }

    .secureDankort {
        max-width: 45px;
    }

    p {
        font-size: 11px;
    }
}

.payment-method-info {   
    p {
        font-size: 0.9em;
        &.payment-method-info-header {
            font-weight: 500;
        }
    }  
}
   
