﻿.kolonneinfo {
    padding-top: 30px;
    padding-bottom: 15px;

    @include large-up {
        padding-bottom: 30px;
    }

    &.bgwhite {
        background: $white;
        width: 100%;
        overflow: hidden;

        p {
            line-height: rem-calc(30);
        }
    }

    &.bggrey {
        background: $lightest-grey;
        width: 100%;
        overflow: hidden;
    }

    &.bglightblue {
        background: $light-blue;
        width: 100%;
        overflow: hidden;
    }



    .kolonneinfo-body {
        @include medium-only {
            display: table;
            float: left;
            padding-left: 0.9375rem;
            padding-right: 0.9375rem;
            position: relative;
        }

        @include small-only {
            float: left;
            padding-left: 0.9375rem;
            padding-right: 0.9375rem;
            position: relative;
        }

        h5 {
            color: $dark-grey;
            margin-bottom: 10px;

            @include large-up {
                margin-bottom: 15px;
            }
        }

        p {
            color: $dark-grey;
            @extend .manchet;
        }

        &__icon {
            font-size: 40px;
        }
    }



    .column.small-centered {
        margin-left: auto;
        margin-right: auto;
        float: none !important;
    }

    .kolonneinfo-title {
        padding-left: rem-calc(10);
    }

    .kolonneinfo-break {
        padding-top: 15px;
        padding-bottom: 15px;

        @include large-up {
            padding: 0;
        }
    }

    .kolonneinfo-wrapper {

        @include large-up {
            max-width: rem-calc(50);
        }
    }

    .kolonne-panel {
        .panel {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    .kolonneinfo-border {

        @include large-up {
            border-right: solid 1px $light-grey;
            display: table-cell;
            //height: rem-calc(60);
            padding-left: rem-calc(10);
            padding-right: rem-calc(10);
        }
    }

    .padleft20 {

        @include large-up {
            padding-left: rem-calc(20);
        }

        @include medium-only {
            padding-left: 0;
        }

        @include small-only {
            padding-left: 0;
        }
    }

    .padright20 {

        @include large-up {
            padding-right: rem-calc(20);
        }

        @include medium-only {
            padding-right: 0;
        }

        @include small-only {
            padding-right: 0;
        }
    }


    .border-left {
        @include medium-only {
            border-left: solid 1px $light-grey;
            float: left;
            padding-left: 0.9375rem;
            padding-right: 0.9375rem;
            position: relative;
        }
    }

    .border-top {
        @include medium-only {
            border-top: solid 1px $light-grey;
        }
    }

    .border-right {
        @include medium-only {
            border-right: solid 1px $light-grey;
        }
    }

    .border-bottom {
        @include medium-only {
            border-bottom: solid 1px $light-grey;
        }
    }

    .small-border-bottom {
        @include small-only {
            border-bottom: solid 1px $light-grey;
        }
    }
}
