﻿// margins
$margintops: inspiration-block-items-1 1, inspiration-block-items-2 2, inspiration-block-items-3 3, inspiration-block-items-4 4, inspiration-block-items-5 5, inspiration-block-items-6 6, inspiration-block-items-7 7, inspiration-block-items-8 8, inspiration-block-items-9 9, inspiration-block-items-10 10;

@function margin-calculation($number) {
    @return -$number * 225px
}

.inspiration-block {
    &.move-up {
        > .row {
            padding-top: 0;
        }

        @include small-only {
            padding-bottom: 30px;
        }

        @each $margintop in $margintops {
            &.#{nth($margintop, 1)} {
                margin-top: margin-calculation(nth($margintop, 2));
            }
        }

        @include medium-up {
            margin-top: -75px !important;
        }

        @include large-up {
            margin-top: -100px !important;
        }
    }

    .grouping-block {
        margin-bottom: rem-calc(80);
    }
}

.inspiration-block__header {
    margin-top: 0;
    margin-bottom: 15px;

    @include small-only {
        padding: 0 15px;
    }

    @include medium-up {
        margin-bottom: 30px;
    }
}

.inspiration-block__item {
    display: flex;
    flex-direction: row;
    padding: 0 !important;

    @include small-only {
        flex-wrap: wrap;
        margin-bottom: 30px !important;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    &:before {
        display: block !important;
    }

    .inspiration-block--automated & {
        margin-bottom: 0 !important;
    }

    .row {
        @include small-only {
            margin-bottom: 0 !important;
        }
    }

    &.medium-pull-6  {
        order: 1;
    }
}

.inspiration-block__item__img {
    height: 177px;

    @include small-only {
        position: relative;

        .inspiration-block--automated & {
            height: 200px;
        }
    }

    @include medium-up {
        height: auto !important;
        min-height: 300px;
    }

    @include xlarge-up {
        min-height: 400px;
    }

    &.big-wrapper,
    .big-wrapper {
        max-width: 950px !important;
    }

    .big-img-left {
        height: 100%;
        width: 100%;

        @include medium-up {
            position: absolute;
            right: 0;
            z-index: 0;
        }

        @include xlarge-up {
            width: 50vw;
        }
    }

    .big-img-right {
        height: 100%;
        width: 100%;

        @include xlarge-up {
            width: 50vw;
        }
    }

    .slider {
        display: block;

        li {
            opacity: 1;
        }
    }
}

.inspiration-block__item__content-wrapper {

    @include small-only {
        margin-top: -30px;

        .inspiration-block--automated & {
            margin-top: 0;
        }
    }

    &.medium-pull-6 {
        order: 1;
    }

    .panel {
        height: 100%;
        max-width: 950px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 30px;
        padding: 15px 20px;
        border-radius: 8px;

        @include medium-up {
            padding: 30px;
            border-radius: 0;
        }

        @include xlarge-up {
            padding: 45px;
        }

        @include small-only {

            &.orange {
                @include gradient($orange, 'linear');
            }

            &.light-orange {
                @include gradient($light-orange, 'linear');
            }

            &.blue {
                @include gradient($blue, 'linear');
            }

            &.dark-blue {
                @include gradient($dark-blue, 'linear');
            }

            &.yellow {
                @include gradient($yellow, 'linear');
            }

            &.green {
                @include gradient($green, 'linear');
            }

            &.dark-green {
                @include gradient($dark-green, 'linear');
            }

            &.black {
                @include gradient($black-gradiant, 'linear');
            }

            &.turkis {
                @include gradient($turkis, 'linear');
            }

            &.purple {
                @include gradient($purple, 'linear');
            }

            &.medium-grey {
                @include gradient(($medium-gradient), 'linear');
            }

            &.coffee {
                @include gradient($coffee, 'linear');
            }

            &.relax {
                @include gradient($relax, 'linear');
            }

            &.grey {
                @include gradient($grey-gradient, 'linear');
            }
        }
        &:before {
            content: none;
        }

        &.no-trigger-events {
            cursor: wait;
            pointer-events: none;
        }

        .inspiration-block--automated & {
            border-radius: 0;
        }
    }

    .big-text-box-left {
        @include medium-up {
            position: absolute;
            width: 100%;
            right: 0;
        }

        @include xlarge-up {
            width: 50vw;
            position: absolute;
            right: 0;
        }
    }

    .big-text-box-right {
        @include medium-up {
            width: 100%;
            right: 0;
        }

        @include xlarge-up {
            width: 50vw;
            right: 0;
        }
    }
}

.inspiration-block__item__text-content {
    overflow: hidden;

    .link-with-large-button + .link-with-large-button {
        margin-left: 1rem;
        cursor: pointer;
    }

    .button + .button {
        margin-left: 1rem;
    }

    @include small-only {
        .link-with-large-button,
        .link-with-large-button + .link-with-large-button {
            display: block;
            margin-left: 0;
            margin-bottom: 10px;
        }
    }
}
.inspiration-block__item__text-wrapper {
    @include small-only {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
.inspiration-block__item__header {

    @include small-only {
        margin-bottom: 0;
        padding-right: 20px;
        text-transform: unset;
        text-align: left;
        position: relative;

        // AS .MANCHET ON MOBILE
        font-size: 14px;
        line-height: 20px;
        @include prim-font;
        font-weight: 600;

        i {
            position: absolute;
            right: 0;
            top: 50%;
            font-size: 12px;
            transform: translateY(-50%);
            transition: transform 0.4s ease-in;

            .open & {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    @include medium-up {
        margin-bottom: 15px;
    }
}

.inspiration-block__item__text {

    @include small-only {
        text-align: left;

        .inspiration-block--automated & {
            text-align: center;
        }
    }
    p {
        @extend .manchet;
    }
}

.inspiration-block__item__cta {
    a {
        margin-top: 20px;

        @include medium-up {
            margin-top: 25px;
        }
    }
}
