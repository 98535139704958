//Navigation animation

@keyframes slide-right {
    0% {
        transform: translateY(0)
    }

    100% {
        transform: translateY(0) translateX(0)
    }
}

@keyframes slide-down {
    0% {
        transform: translateX(0)
    }

    100% {
        transform: translateX(0) translateY(0)
    }
}

.content-container {
    @include transform(none, .6s ease-in-out);
    position: relative;
    overflow: hidden;
    @include transform('none', '.4s ease-in-out');
    box-shadow: -5px 0 10px rgba($black, 0.2);
    z-index: 2;

    @media screen and (max-width: 1150px) {

        .show-booking & {
            @include transform('translate(-100%) translate(40px)', null);
        }
    }
}

nav.small {
    display: block !important;
    height: 60px;
    background: rgba($black, 0.65);
    @include transform(none, '.6s ease-in-out');
    transition: height .6s ease-in-out;

    .corona & {
        top: 130px;
    }

    @media screen and (min-width: 1150px) {
        display: none !important;
    }


    .show-nav & {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        background: rgba($black, 0.8);

        @include medium-up {
            background: rgba($black, 0.9);
        }
    }

    .show-booking & {
        @include transform('translate(-100%) translate(40px)', null);
    }

    .small__header {
        position: absolute;
        width: 100%;
        top: 0;
        height: 60px;
        transition: transform 0.4s ease-in-out;
    }

    hgroup {
        width: auto;
        padding: 12px 13.5px;
        right: auto;
        left: 0;

        img {
            width: 79px;
        }
    }

    .menu {
        padding: 24px 20px;
        display: inline-block;
        text-shadow: 1px 1px rgba($black,0.2);
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }

    .menu__line {
        height: 2px;
        width: 18px;
        display: block;
        margin-bottom: 2px;
        background-color: $white;
        transition: all 0.6s ease-in-out;
    }

    .small__header__resort {
        position: absolute;
        right: 45px;
        top: 50%;
        transform: translateY(-50%);
        @include prim-font;
        font-size: 12px;
        font-weight: 300;
    }

    .book {
        position: absolute;
        bottom: -17.5%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 11;

        .button.cta {
            width: 111px;
            font-size: 20px;
            line-height: 20px;
            margin: 0;
            padding: 10px;
            height: 38px;
            font-weight: 800;
            letter-spacing: normal;
        }
    }

    .searchbar-wrapper {
        position: relative;
        width: 100%;
    }

    .search-input {
        background-color: transparent;
        border: 2px solid white;
        height: 50px;
        width: 100%;
        padding: 10px 40px 10px 20px;
        border-radius: 25px;
        color: $white;
        text-indent: 0;
        @extend .manchet;
        font-weight: 300;

        &::placeholder {
            color: $white;
            text-align: left;
        }

        &.active {
            padding-left: 30px;
        }
    }

    .search-clear {
        right: auto;
        left: 10px;
        color: white;
        top: 50%;
        transform: translateY(-50%);

        &:before, &:after {
            background-color: $white;
        }
    }

    .search-btn {
        position: absolute;
        padding: 0;
        border: none;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        background: transparent;

        i {
            background: transparent;
            display: block;
            transform: scaleX(-1);
            font-size: 16px;
            margin: 0;
            padding: 0;
        }
    }


    .slide-right-aside-nav & {
        > .small__header,
        .small__nav--main {
            transform: translateX(-100vw);
        }

        .small__nav--aside-subnav {
            &.active {
                transform: translateX(0);
            }
        }
    }

    .slide-down-aside-nav & {
        .small--aside {
            transform: translateY(0);
        }

        > .small__header,
        .small__nav--main {
            display: none;
        }
    }
}

.show-nav {
    .menu__line {

        &:first-child {
            transform: translateY(4px) rotate(45deg);
        }

        &:last-child {
            transform: translateY(-4px) rotate(-45deg);
        }

        &:nth-child(2) {
            opacity: 0;
        }
    }
}

@media screen and (max-width: 1150px) {
    .small-nav {
        ul {
            @include navigation-list("vertical");

            &.top-tools {
                li {
                    position: relative;
                    padding: 5px 20px;
                    color: #000;
                }
            }

            li {
                padding: 10px 20px;
            }
        }
    }
}

.small-nav {

    text-align: left;
    @include header-font;
    @include transform(translateY(-100vh), '.6s ease-in-out, opacity 1s ease-in-out');
    position: absolute;
    padding: 35px 30px 85px;
    top: 0;
    top: 60px;
    width: 100%;
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 1;

    @media screen and (orientation: portrait) and (min-device-height: 750px) {
        padding-bottom: 120px;
    }

    @media screen and (min-width: 1150px) {
        display: none;
    }

    a:hover {
        text-decoration: none;
    }

    &::-webkit-scrollbar {
        display: block;
        width: 11px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1.5px;
        background-color: rgba(#bcbcbc, 0.6);
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #bcbcbc;
    }


    .show-nav & {
        @include transform(translate(0%), null);
    }

    &__center-button {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 10px;
        padding: 14px 20px;
        border-radius: 4px;
        border: none;
        background-color: white;
        color: black;
        @include prim-font;
        font-size: 15px;
        text-align: left;

        i {
            position: absolute;
            top: 50%;
            left: auto;
            right: 15px;
            transform: translateY(-50%);
            color: white;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: black;


            &:before {
                display: block;
                transform: rotate(-90deg);
            }
        }
    }
}

.offcanvas-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: transparent;
}

#small-search {
    margin: 0;
}

.accordionNav {
    position: relative;
    z-index: 1;
    margin-top: 5px;
    margin-bottom: 0;

    dt {
        margin: 0;
        border-bottom: 1px solid rgba($white, 0.5);
        position: relative;
        display: flex;
        flex-direction: row;

        a {
            flex-grow: 1;
        }

        &.active {
            a {
                font-weight: 500;
            }
        }

        i {
            position: absolute;
            top: 20px;
            right: 15px;
            transition: transform 0.4s ease-in-out;
        }

        span {
            display: block;
            width: 45px;
            position: relative;
            margin-left: 5px;
        }
    }

    .on {
        cursor: default;

        i {
            transform: rotate(180deg);
        }
    }

    .nav__sub-items {
        display: none;
        margin: 0;
        padding: 5px 0;
        border-bottom: 1px solid rgba($white, 0.5);


        &.active {
            display: block;
        }
    }

    .nav__sub-item {

        &.active {
            font-weight: 500;
        }
    }

    a, p {
        display: block;
        padding: 12.5px 20px;
        margin: 0;
        @extend .manchet;
        @include prim-font;
        font-weight: 300;
        color: $white;
        letter-spacing: 0.055em;
        text-align: left;
    }
}

.small__top-tools {
    ul.top-tools {
        li {
            padding: 15px 20px;
        }
        // LANGUAGE SELECT
        select {
            margin: 0;
            padding: 0;
            color: $white;
            background-color: transparent;
            background-image: none;
            border-radius: 0;
            border-bottom: none;
            box-shadow: none;
            text-indent: 30px;
            @extend .manchet;
            @include prim-font;
            font-weight: 300;


            option {
                color: $black;
                text-indent: 0;
                padding: 5px;
            }
        }

        .icon-culture,
        .icon-arrow-down {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: $white;
        }

        .icon-culture {
            left: 20px;
            font-size: 18px;
            margin-top: -1px;
        }

        .icon-arrow-down {
            right: 15px;
        }
        // SEARCH FIELD
        .top-tools__search {
            padding: 15px 0;
        }
    }
}

.small__nav--main {
    transition: transform 0.4s ease-in-out;
}

.small--aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 11;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transform: translateY(-100vh);
    transition: transform 0.25s ease-in-out;

    &.active-aside-nav {
        transform: translateY(0);
    }
}

.small__nav--aside-subnav {
    width: 100%;
    height: 100%;
    display: none;
    background: linear-gradient(180deg, rgba(#4DA5E1, 0.9) 0%, rgba(#006EB9, 0.9) 100%);
    transform: translateX(100vw);
    transition: transform 0.4s ease-in-out;

    &.active {
        display: block;

        .down & {
            transform: translateX(0);
        }
    }

    .small__header {
        background-color: rgba(#000000, 0.32);
    }

    .small__header__back {
        padding: 20px;
        cursor: pointer;
        display: inline-block;
        @include prim-font;
        font-size: 12px;
        line-height: 20px;
        font-weight: 300;

        i {
            display: inline-block;
            transform: rotate(90deg);
        }
    }
}

.small--aside__nav-header {
    font-weight: 900;
    text-transform: uppercase;
    display: block;

    &:first-child {
        text-align: center;
        margin: 10px auto;

        @extend .small-header;
    }
}
