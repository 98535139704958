﻿.section-navigation {
	position: fixed;
	display: none;
	left: 20px;
	top: 0;
	bottom: 0;
	z-index: 7;
	text-align: left;

	@media screen and (min-width: 1300px){
		display: block;
	}	
	a:not(.button):hover {
		text-decoration: none;
	}
	.bullets {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		span {
			@include transition('background .4s ease-in-out');
			  display: block;
			  position: relative;
			  background: rgba($white, 0);
			  width: 1em;
			  height: 1em;
			  border-radius: 1em;
			  margin: 0.785em 0;
			  &:before {
				position: absolute;
				z-index: -1;
				content: "";
				background: rgba(188, 188, 188, 0.4);
                box-shadow: 0 0 15px rgba(0,0,0,0.4);
				width: 1em;
				height: 1em;
				border-radius: 1em;
				top: 1px;
				left: 0;
			  }
			  &.active {
				background: rgba($white, 1);
			  }
		}
	}
	> ul {
		display: none;
		@include navigation-list('vertical');
		@include transition('opacity .4s ease-in-out');
		@include header-font;
		font-size: rem-calc(14);
		font-weight: 600;
		text-transform: uppercase;
		position: absolute;
		top: 50%;
		left: 30px;
		transform: translateY(-50%);
		background: rgba($black, 0.4);
		border-radius: $radius;
		opacity: 0;
		padding: 0.3rem 1.3rem;

		&.active {
			.no-touch & {
				@include medium-up {
					display: block;
				}
			}
		}
		&.show {
			opacity: 1;
		}
		.no-bookmark & {
			display: none;
		}

		> li {
			position: relative;
			margin: 1em 0;
            font-size: 0.9em;
			white-space:nowrap;
			&:before {
				@include transition('opacity .4s ease-in-out');
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0.4em 0.4em 0.4em 0;
				border-color: transparent rgba($black, 0.4) transparent transparent;
				left: -2.05em;
				top: 0;
				bottom: 0;
				margin: auto;
				opacity: 0;
			}
			&.active {
				font-weight: 900;
				&:before {
					opacity: 1;
				}
			}
		} 
	}
}
