﻿
// array of paddings
$inspirationBlockPaddingbottoms:
    padding-items-1 1, 
    padding-items-2 2, 
    padding-items-3 3, 
    padding-items-4 4,
    padding-items-5 5,
    padding-items-6 6,
    padding-items-7 7,
    padding-items-8 8,
    padding-items-9 9,
    padding-items-10 10;

@function inspirationPadding-calculation($number) {
    @return $number * 225 + 30px
}

.hero-block {
    position: relative;

    ~ .move-up {
    }

    ~ .spot-block {
        &.move-up {
            margin-top: -140px;

            @include medium-up {
                margin-top: -135px;
            }

            @include large-up {
                margin-top: -170px;
            }

            @include xlarge-up {
                margin-top: -185px;
            }
        }
    }

    ~ .tabs-block {
        &.move-up {
            margin-top: -100px;

            @include medium-up {
                margin-top: -220px;
            }

            @include large-up {
                margin-top: -250px;
            }
        }
    }

    ~ .gallery-block {
        &.move-up {
            margin-top: -125px;

            @include large-up {
                margin-top: -150px;
            }
        }
    }
}

section + .hero-block {
    margin-top: 30px;

    @include medium-up {
        margin-top: 60px;
    }

}

.hero-block__top {
    position: relative;
    padding: 10px 0;
    background: $bg-black;

    &.white {
        background: $white;
    }

    &.grey {
        background: $bg_grey;
    }

    &.black {
        background: $bg-black;
    }

    &.orange {
        background: $bg_orange;
    }

    &.purple {
        background: $bg_purple;
    }

    &.green {
        background: $bg_green;
    }

    &.blue {
        background: $bg_blue;
    }

    &.yellow {
        background: $bg_yellow;
    }

    &.turkis {
        background: $bg_turkis;
    }

    &.light-orange {
        background: $bg_light-orange;
    }

    &.dark-green {
        background: $bg_dark-green;
    }

    &.dark-blue {
        background: $bg_dark-blue;
    }

    &.coffee {
        background: $bg_coffee;
    }

    &.relax {
        background: $bg_relax;
    }
}

.hero-block__background {
    width: 100%;
    height: 210px;
    position: relative;

    @include medium-up {
        height: 500px;
    }

    @include large-up {
        height: 550px;
    }

    @include xxlarge-up {
        height: 650px
    }

    .hero-block--secondary & {
        @include medium-up {
            position: absolute;
            height: 100%;
            max-height: none;
            top: 0;
        }
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
    }


    + .hero-block__content-wrapper {

        @include medium-up {
            margin-top: calc(-50vh / 4);
        }

        @include large-up {
            margin-top: -120px;
        }

        @include xxlarge-up {
            margin-top: calc(-710px / 4);
        }

        .hero-block--secondary & {
            @include medium-up {
                margin-top: 0;
                height: auto;
                min-height: 500px;
                display: flex;
            }

            @include large-up {
                min-height: 550px;
            }

            @include xxlarge-up {
                min-height: 650px;
            }

            > .row {
                display: flex;

                > .column {
                    display: flex;
                }
            }
        }
    }
}

.hero-block__logo {

    @include medium-up {
        margin-bottom: 30px;
    }


    img {
        height: auto;
        width: auto;
        padding: 0;
        max-width: 175px;

        @include medium-up {
            max-width: 190px;
        }

        @include xlarge-up {
            max-width: 200px;
        }

        @include xxlarge-up {
            max-width: 230px;
        }
    }
}

.hero-block__content-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include gradient($black-gradiant, 'radial');

    .hero-block--secondary & {
        @include medium-up {
            opacity: 0.9;
            min-height: 400px;
        }
    }

    &.white {
        background: $white;

        & + div {
            color: $black;
        }
    }

    &.grey {
        @include gradient($grey-gradient, 'radial');
    }

    &.black {
        @include gradient($black-gradiant, 'radial');
    }

    &.light-orange {
        @include gradient($light-orange, 'radial');
    }

    &.orange {
        @include gradient($orange, 'radial');
    }

    &.purple {
        @include gradient($purple, 'radial');
    }

    &.green {
        @include gradient($green, 'radial');
    }

    &.dark-green {
        @include gradient($dark-green, 'radial');
    }

    &.blue {
        @include gradient($blue, 'radial');
    }

    &.dark-blue {
        @include gradient($dark-blue, 'radial');
    }

    &.yellow {
        @include gradient($yellow, 'radial');
    }

    &.turkis {
        @include gradient($turkis, 'radial');
    }

    &.coffee {
        @include gradient($coffee, 'radial');
    }

    &.relax {
        @include gradient($relax, 'radial');
    }
}

.hero-block__content {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    position: relative;

    @include medium-up {
        flex-basis: 100%;
        min-height: 250px;
        padding-top: 50px;
        padding-bottom: 50px;
        justify-content: center;
    }

    @include large-up {
        min-height: 350px;
    }

    .has-spot-overflow & {
        padding-bottom: 130px;

        @include large-up {
            padding-bottom: 155px;
        }

        @include xlarge-up {
            padding-bottom: 170px;
        }

        @include xxlarge-up {
            padding-bottom: 190px;
        }
    }

    .has-tabs-overflow & {
        padding-bottom: 100px;

        @include medium-up {
            padding-bottom: 210px;
        }

        @include large-up {
            padding-bottom: 230px;
        }

        @include xxlarge-up {
            padding-bottom: 250px;
        }
    }

    .has-gallery-overflow & {
        padding-bottom: 125px;

        @include xlarge-up {
            padding-bottom: 150px;
        }
    }

    @each $paddingbottom in $inspirationBlockPaddingbottoms {
        .has-inspiration-overflow.#{nth($paddingbottom, 1)} & {
            padding-bottom: inspirationPadding-calculation(nth($paddingbottom, 2));

            @include medium-up {
                padding-bottom: 100px;
            }
        }
    }
}

.hero-block__header {
    margin: 0 -15px 15px;

    @include large-up {
        margin: 0 -50px 30px;
    }
}

.hero-block_info-pills {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -15px 20px;

    @include xxlarge-up {
        margin: 0 0 40px;
    }
}

.hero-block_info-pill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px;
    padding: 7.5px 10px;
    border-radius: 25px;
    @extend .manchet;
    background-color: rgba(#202020, 0.2);

    @include small-only {
        font-size: 13px;
    }

    @include medium-up {
        margin: 5px;
        padding: 10px 15px;
        margin: 0 10px;
    }

    i {
        font-size: 14px;

        @include medium-up {
            font-size: 18px;
        }

        @include xlarge-up {
            font-size: 22px;
        }
    }

    span {
        margin-left: 7.5px;
        font-weight: 600;

        @include medium-up {
            margin-left: 10px;
        }
    }
}
.hero-block__cta {
    margin-top: 10px;

    @include large-up {
        margin-top: 15px;
    }
}

.hero-block__opening-hours {
    margin-top: 20px;
}

.hero-block__text {
    p {
        @extend .manchet;
    }
}
