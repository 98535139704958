﻿.spot-mosaics-block {

}

.spot-mosaic__box {
    display: flex;
    flex-direction: column;
}

.spot-mosaic__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    border-radius: 8px;
    overflow: hidden;


    @include medium-up {
        margin: 10px;
    }

    @include medium-only {
        .spot-mosaic__box:nth-of-type(odd) & {
            margin-left: 0;
        }

        .spot-mosaic__box:nth-of-type(even) & {
            margin-right: 0;
        }
    }

    @include large-up {
        .spot-mosaic__box:nth-of-type(3n + 1) & {
            margin-left: 0;
        }

        .spot-mosaic__box:nth-of-type(3n) & {
            margin-right: 0;
        }
    }

    &:before {
        content: none;
    }

    p {
        @extend .manchet;
    }
}

.spot-mosaic__box-img {
    width: 100%;
    flex-basis: 100px !important;

    @include medium-up {
        flex-basis: 200px !important;
    }
}

.spot-mosaic__box-text {
    width: 100%;
    flex-grow: 1;
    padding: 25px 15px;
    @include gradient($black-gradiant, 'linear');

    @include medium-up {
        padding: 30px 25px;
    }

    h3 {
        margin-bottom: 0;
    }

    p {
        @extend .manchet;

        @include medium-up {
            margin-top: 10px;
        }
    }

    a {
        @extend .manchet;

        &:before {
            margin-right: 7.5px;
            top: 1px;
        }
    }
}

.spot-mosaic__slider {
    .slider-inner {
        width: calc(100% + 15px);
        margin-left: -7.5px;
    } 
    .bullet-wrapper {
        bottom: 0;
    }
}