﻿$vertical-bp-fluid: "#{$screen} and (min-height:660px) and (max-height:840px)";
.information-wrapper {
	position: absolute;
	z-index: 3;
	display: none;
	opacity: 0;
	@include transition('opacity .4s ease-in-out');
	&.show {
		display: block;
		&.visible {
			opacity: 1;
		}
	}

	.close {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
	}

	@include large-up {
		display: block;
		position: relative;
		top: auto;
		opacity: 1;
		@media #{$vertical-bp-fluid} {
			margin-top: -60px;
			.checkout & {
				margin-top: 0;
			}
		}
		.close {
			display: none;
		}
	}


}

.information {
	position: relative;
	display: block;
	@include gradient($information, 'linear');
	@include light;
	padding: $column-gutter;
	border-radius: $radius;
	text-align: left;
	font-size: 1rem;
	margin-top: $column-gutter/2;
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
    margin-bottom: $column-gutter/2;
	@include large-up {
		position: relative;
		display: block;
		margin-top: 0;
		max-width: none;
	}
    &:last-child {
        margin-bottom: 0;
    }
	&:before {
		content: none;
	}
    &.subtile {
        background: transparent;
        border: 1px solid $info-text;
        @include dark;
        .icon {
            color: $info-text;
        }
    }
	&.grey {
		@include gradient($medium-gradient, 'linear');
		color: $dark-grey;
		.icon {
			color: $grey;
		}
		.text {
			margin-bottom: 0.5em;
			margin-top: 0.5em;	
		}
		h5 {
			@include prim-font;
			font-weight: 300;
			font-size: 1.6em;
			text-transform: none;
		}
	}
	.icon {
		position: absolute;
		font-size: 2em;
		left: 0.72em;
	}
	.text {
		margin-left: em-calc(40);
		p, h6 {
			@include prim-font;
			text-align: left;
			font-size: 0.9em;
			font-weight: 400;
			margin: 0.5em 0;
		}
		h6 {
			margin-top: 0.6em;
			margin-bottom: 0.2em;
			text-transform: none;
			color: $info-text;
			font-weight: 600;
			&:first-child {
				margin-top: 0;
			}
		}
		&:first-child {
			margin-left: 0;
		}
        ul {
            padding-left: 1em;
            font-size: 0.9em;
            margin: 0.5em 0;

            li {
                margin: 0.3em 0;
            }
        }
	}
}

.information-trigger {
	cursor: pointer;
	vertical-align: middle;
	@include large-up {
		display: none;
	}
}