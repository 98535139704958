.switch-btn {
	position: relative;
	display: inline-block;
	height: 1.8em;
	border-radius: 1.8em;
	background: $white;
	font-size: rem-calc(30);
	@include medium-only {
		font-size: rem-calc(35);
	}
	@include large-up {
		font-size: rem-calc(45);
	}
	span {
		cursor: pointer;
		@include transition('color .3s ease-in-out, text-shadow .3s ease-in-out');
		position: relative;
		width: 1.3em;
		height: 1.3em;
		display: inline-block;
		padding: 0;
		margin: 0.22em;
		line-height: 1.2em;
		text-align: center;
		color: $light-grey;
		@include header-font;
		font-weight: 900;
		font-size: 1em;
		-ms-font-feature-settings: "onum" 1;
		-moz-font-feature-settings: "onum" 1;
		-webkit-font-feature-settings: "onum" 1;
		font-feature-settings: "onum" 1;
		z-index: 2;
		text-shadow: 1px 1px 2px rgba(0,0,0,0); 
	}
	&.select-1 span.select-1, &.select-2 span.select-2 {
		color: $white;
		text-shadow: 1px 1px 2px rgba(0,0,0,0.2); 
	}

	.selector {
		@include transition('left .3s ease-in-out');
		position: absolute;
		width: 1.3em;
		height: 1.3em;
		@include gradient($green, 'radial');
		border-radius: 1.3em;
		top: 0.25em;
		left: 0.2em;
		z-index: 1;
		display: none;
	}
	&.select-1 .selector {
		display: block;
		left: calc(0% + 0.2em);
	}
	&.select-2 .selector {
		display: block;
		left: calc(100% - 1.5em);
	}
}