.checkbox, .radio {
    display: inline;
    width: 30px;
    margin: 30px auto;

    input[type=checkbox], input[type=radio] {
        visibility: hidden;
    }

    + label {
        padding-left: 20px;
        vertical-align: middle;
        font-size: 1em;
        font-weight: 300;
        line-height: 1.5;
    }

    label {
        cursor: pointer;
        position: absolute;
        width: 25px;
        height: 25px;
        top: 0;
        left: 0;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        border-radius: 8px;
        //background: darken($booking-grey, 3%);
        background-color: $white;
        border: 1px solid $light-grey;
        @include transition(background .2s ease-in-out);

        &:after {
            opacity: 0;
            content: '';
            position: absolute;
            @include transition(opacity .2s ease-in-out);
        }

        &:hover {
            background: lighter($light-grey, 10%);
        }
    }

    input[type=checkbox]:checked + label:after, input[type=radio]:checked + label:after {
        opacity: 1;
    }
}

.radio {
	label {
		width: 20px;
		height: 20px;
    }
}

.checkbox-wrapper {
	position: relative;
	display: inline-block;
}

.checkbox label {
	border-radius: 8px;
	&:after {
		top: 7px;
		left: 6px;
		width: 13px;
		height: 8px;
		background: transparent;
		border: 3px solid $white;
		border-top: none;
		border-right: none;
		@include transform(rotate(-45deg), null);
	}
}

.radio label {
    border-radius: 50%;

    &:after {
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
        @include gradient($green, 'linear');
        border-radius: 50%;
    }
}
