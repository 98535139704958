/* Selectic */
.no-touch select[selectric] {
	display: none;
}

.selectricWrapper {
	position: relative;
	font-weight: 500;
	margin: 0;
	width: 100%;
	cursor: pointer;
	text-align: left;
}

.selectricResponsive {
	width: 100%;
}

.selectric {
	@include gradient($light-gradient, linear);
	position: relative;
	border-radius: 18px;
	box-shadow: 0 2px 0 darken($light-grey, 15%);
	text-align: left;
	cursor: pointer;

	.label {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		margin: 0 30px 0 0;
		padding: 9px 15px;
		font-size: 11px;
		font-weight: 500;
		text-align: left;
		line-height: 18px;
		color: $dark-grey !important;
		min-height: 18px;
		-webkit-font-smoothing: antialiased;

		@include large-up {
			font-size: 12px;
		}
	}

	i {
		position: absolute;
		right: 15px;
		top: 13px;
		font-size: 11px;
		color: $dark-grey;
	}
}

.selectricHover .selectric {
	border-color: #CCC;

	.button {
		color: #888;

		&:after {
			border-top-color: #888;
		}
	}
}

.selectricOpen {
	z-index: 9999;

	.selectricItems {
		display: block;
	}
}

.selectricDisabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-touch-callout: none;
	user-select: none;
}

.selectricHideSelect {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;

	select {
		position: absolute;
		left: -100%;
		display: none;
	}
}

.selectricInput {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectricTempShow {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

/* Items box */
.selectricItems {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	margin-top: 8px;
	z-index: -1;

	&:before {
		content: " ";
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 5px 5px 5px;
		border-color: transparent transparent rgba($white, 0.9) transparent;
		top: -5px;
		left: 0;
		right: 0;
		margin: auto;
	}

	&:focus {
		outline-color: transparent;
		outline-style: none;
	}

	.selectricScroll {
		height: 100%;
		overflow: auto;
		background: $white;
		border-radius: $radius;
	}

	.selectricAbove & {
		top: auto;
		bottom: 100%;
	}

	ul, li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 12px;
		line-height: 20px;
		min-height: 20px;
	}

	li {
		display: block;
		padding: 8px 15px;
		color: $dark-grey;
		cursor: pointer;

		&.selected, &.selected:hover {
			background: nth($green, 1);
			color: $white;
		}

		&:hover {
			background: #F0F0F0;
			color: #444;
		}

		&.disabled, &.disabled:hover {
			color: #BBB;
			background: transparent;
			cursor: default;
		}
	}
}
