@import "sections";
@import "hero";
@import "centerselect";
@import "spot-block";
@import "spot-carousel";
@import "teaser";
@import "gavekort";
@import "googlesearch";
@import "lang-selector";
//@import "emailkvittering";
@import "sub-nav";
@import "house-details";
@import "mini-shop";
@import "gallery-block";
@import "inspiration-block";
@import "hero-block";
@import "_spot-mosaic";