@mixin transform($argument, $transition) {
  -webkit-transform: #{$argument};
  -ms-transform: #{$argument};
  -moz-transform: #{$argument};
  -o-transform: #{$argument};
  transform: #{$argument};
  @if $transition != null {
	  -webkit-transition: -webkit-transform #{$transition};
	  -ms-transition: -ms-transform #{$transition};
	  -moz-transition: -moz-transform #{$transition};
	  -o-transition: -o-transform #{$transition};
	  transition: transform #{$transition};
  }
}