.spot-block {

    .slider-inner, .slider-inner.outer-nav {
        height: auto;
    }

    .slider-inner {
        z-index: 2;
        margin: auto;

        @include small-only {
            width: calc(50vw + 75px);
        }
        @include medium-up {
            //overflow: hidden;
        }

        ul {
            list-style: none;
            padding: 0;

            @include small-only {
                li {
                    opacity: 1;
                }
            }
        }

        .bullet-wrapper {
            bottom: 0;
        }
    }

    .image-container {
        position: relative;
        border-radius: 50%;
        margin: auto;
        overflow: hidden;
        z-index: 1;
        backface-visibility: hidden;
        width: 196px;
        height: 196px;

        @include medium-up {
            width: 180px;
            height: 180px;
        }

        @include large-up {
            width: 220px;
            height: 220px;
        }

        @include xlarge-up {
            width: 250px;
            height: 250px;
        }
    }

    p {
        padding: 0;
        margin-bottom: rem-calc(5);
        margin-top: rem-calc(5);

        @include xxlarge-up {
            margin-bottom: rem-calc(10);
            margin-top: rem-calc(10);
        }
    }

    &.space-top {
        @include medium-up {
            margin-top: rem-calc(80);
        }
    }

    .spot-block__slider-item__content {
        padding-top: 20px;
        width: 80%;
        margin: 0 auto;
    }
}

.spot-block__item {
    max-width: 270px;
    margin: 0 auto;
}

.spot-block__item-content {
    padding-top: 20px;

    @include large-up {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.spot-block__item-header,
.spot-block__slider-item__header {
    text-transform: unset;
    @extend .manchet;
    @include prim-font;
    font-weight: 600;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.spot-block__item-text,
.spot-block__slider-item__text {

    a {
        display: block;
        margin-top: 5px;
        font-size: inherit;
        font-weight: inherit;

        @include xxlarge-up {
            margin-top: 10px;
        }

        &:before {
            content: "\E007";
            font-size: 10px;
            top: 0;
            margin-left: -12px;

            @include xxlarge-up {
                font-size: 14px;
            }
        }

        &.richtextlink {
            display: inline;
            &:before {
                content: none;
            }
        }
    }
}

.spot-block__price {
    @include medium-only {
        font-size: rem-calc(30) !important;
    }
}


// Offer spot block

.spot-block-offer {
    .slider-inner {
        width: calc(100vw - 45px);
    }
}
.spot-block-offer__list {
    display: flex;
    flex-direction: row;
    padding-top: 70px !important;
    overflow: visible;

    @include medium-up {
        padding-top: 110px !important;
        padding-bottom: 50px;
    }
}

.spot-block-offer__item {
    overflow: visible !important;
    height: 100% !important;

    @include small-only {
        height: auto !important;
        margin: 0 10px 25px;
    }

    .image-container {
        position: absolute;
        top: -70px;
        left: 50%;
        transform: translateX(-50%);
        height: 170px;
        width: 170px;
        border: 5px solid $white;
        border-radius: 50%;
        overflow: hidden;

        @include medium-up {
            top: -90px;
            height: 190px;
            width: 190px;
        }
    }
}

.spot-block-offer__item-content {
    width: auto;
    height: 100%;
    padding: 120px 25px 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    @include large-up {
        padding: 130px 25px 50px;
    }

    &.yellow {
        @include gradient($yellow, 'radial');
        @include light;
    }

    &.white {
        background-color: $white;
        @include dark;
    }

    &.orange {
        @include gradient($orange, 'radial');
        @include light;
    }

    &.light-orange {
        @include gradient($light-orange, 'radial');
        @include light;
    }

    &.blue {
        @include gradient($blue, 'radial');
        @include light;
    }

    &.dark-blue {
        @include gradient($dark-blue, 'radial');
        @include light;
    }

    &.green {
        @include gradient($green, 'radial');
        @include light;
    }

    &.dark-green {
        @include gradient($dark-green, 'radial');
        @include light;
    }

    &.black {
        @include gradient($black-gradiant, 'radial');
        @include light;
    }


    &.turkis {
        @include gradient($turkis, 'radial');
        @include light;
    }

    &.purple {
        @include gradient($purple, 'radial');
        @include light;
    }

    &.medium-grey {
        @include light;
        @include gradient(($medium-gradient), 'radial');
    }

    &.coffee {
        @include gradient($coffee, 'radial');
        @include light;
    }

    &.relax {
        @include gradient($relax, 'radial');
        @include light;
    }

    .spot-block-offer__item-text {
        margin-bottom: 10px;
        p {

            @extend .body-text;

            @include small-only {
                font-size: 15px;
            }
        }
    }
}

.spot-block-offer__item-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    
    h3 {
        font-size: 35px;
        line-height: 1.1;
        margin: 0;
        margin-bottom: rem-calc(5);
        text-transform: uppercase;

        &.minimize-header {
            font-size: 24px;
            line-height: 1.1;
        }
    }

   
}

.spot-block-offer__item-price-container {

    .offer__price {
        font-size: 36px !important;

        @include medium-only {
            font-size: 30px !important;
        }
    }
}
.spot-block-offer__item-cta {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
    .button {
        font-size: 20px;
        padding: 12.5px 25px;
    }
}


.spot-block-offer__read-more {
    font-size: 18px;

    &:before {
        margin-right: 10px;
    }
}