﻿.overlay {
    &.modal {
        position: fixed !important;
        width: 100%;
        height: 100%;
        opacity: 1;
        color: $black;
        pointer-events: auto;

        .modal-wrapper {
            position: absolute;
            width: calc(100% - 80px);
            height: calc(100% - 80px);
            max-height: 520px;
            max-width: 1024px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-color: $white;
            border-radius: $radius;
            box-shadow: 0 0 40px rgba($black, 0.2);

            a {
                color: $black;
                text-decoration: underline;

                .no-touch &:hover {
                    text-decoration: none;
                }
            }

            .modal-container {
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 100%;
                padding: 40px;

                section.article-block .row {
                    padding-top: 0;
                }
            }

            .close-btn {
                position: absolute;
                top: -40px;
                font-size: 1.5rem;
                right: 0;
                cursor: pointer;
                color: $white;
                z-index: 2;

                .booking-wrapper & {
                    color: $black;
                }
            }
        }
    }
}
