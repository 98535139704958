$booking-bp: "#{$screen} and (min-width:690px) and (max-width:1230px)";

.booking::-webkit-scrollbar {
    display: none;
}

#bookingPanel {
    .small-reset {
        color: black;
        font-size: 0.9em;
        text-decoration: underline;
    }

    .bookingsection-guests {
        background: $white;
        padding-top: 40px;

        @include small-only {
            padding-top: 30px;
        }

        @media screen and (max-width: 850px) and (min-width: 60px) {
            padding-top: 0px;
        }

        &__info {
            min-height: 60vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__house {
            position: relative;

            @include small-only {
                width: 90%;
                margin-left: 5%;
            }

            &__amount {
                margin: 20px 0;

                @include small-only {
                    margin-top: 0;
                }

                h1 {
                    position: relative;
                    font-size: 14px;
                    z-index: 1;
                    overflow: hidden;
                    text-align: center;

                    a {
                        font-weight: 400;
                        text-transform: none;
                        text-decoration: underline;
                    }
                }

                h1:before, h1:after {
                    position: absolute;
                    top: 51%;
                    overflow: hidden;
                    width: 50%;
                    height: 1px;
                    content: '\a0';
                    background-color: #DDDDDD;
                }

                h1:before {
                    margin-left: calc(-50% - 20px);
                    text-align: right;
                    /* IE10+ */
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        margin-left: -100px;
                    }
                }

                h1:after {
                    margin-left: 20px;
                }

                a {
                    color: $black;
                }
            }

            &__info {
                position: absolute;
                top: 0;

                @include small-only {
                    width: 100%;
                    right: 0;
                    left: 0;
                }

                &.about-kids, &.about-house {
                    right: 0;
                }
            }
        }

        &__people {
            @include medium-up {
                height: 270px;

                .columns {
                    height: 100%;
                }
            }

            .incrementor {
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                @include small-only {
                    align-items: center;
                    width: 90%;
                    height: 10em;
                    margin-bottom: 10px;
                }

                @include medium-up {
                    flex-direction: column;
                }

                .incrementor-people {
                    @include small-only {
                        display: flex;
                        align-items: center;
                        margin-bottom: 0
                    }
                }
            }

            .incrementor-label {
                min-height: 40px;

                @include small-only {
                    margin-bottom: 0;
                    text-align: left;
                }

                .sub-header {
                    font-size: 1rem;
                    margin-bottom: 0;

                    @include small-only {
                        padding: 0;
                    }
                }

                .small {
                    font-size: 0.8rem;
                    margin-top: 0;
                }
            }
        }

        button {
            @include small-only {
                margin: 0;
            }
        }

        .cta {
            margin-top: 30px;
            margin-bottom: 20px;

            @include small-only {
                margin-top: 10px;
            }
        }

        .hide-medium {
            @include medium-up {
                display: none;
            }
        }

        .extra-house-open {
            margin-top: 50px;
            margin-bottom: 40px;

            @include small-only {
                margin-top: 20px;
            }
        }
    }


    .booking-summary {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        background: $white;
        color: $black;
        border-bottom: 1px solid $light-booking-grey;
        z-index: 10;
        font-size: rem-calc(12);
        flex-direction: row;

        .touch & {
            width: 100%;
        }

        @include large-only {
            font-size: rem-calc(13);
        }

        @include xlarge-up {
            font-size: 1rem;
        }

        @include medium-up {
            display: flex;
        }

        &__closing {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid $light-booking-grey;

            @include large-up {
                width: 60px;
                height: 60px;
            }

            i {
                color: $black;
            }
        }

        ul {
            @include navigation-list(left);

            > li {
                cursor: pointer;
                position: relative;
                height: 50px;
                line-height: 50px;
                padding-left: 2.1em;
                padding-right: 2.1em;

                @include large-up {
                    height: 60px;
                    line-height: 60px;
                }

                &:first-child {
                    cursor: auto;
                    display: none;
                    color: $black;

                    @media (min-width: 1180px) {
                        display: block;
                    }
                }

                &:after {
                    @extend %icon-font;
                    @extend .icon-small-arrow-right:before;
                    position: absolute;
                    right: em-calc(-8);
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 1em;
                    color: $bullet-green;
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .house-group {
            margin-left: em-calc(20);

            &:first-child {
                margin-left: 0;
            }
        }

        .house {
            @extend .icon-house;
            position: relative;
            display: inline-block;
            margin-right: em-calc(10);
            margin-left: em-calc(-5);
            width: em-calc(25);
            height: 1em;
            vertical-align: middle;
            text-align: center;

            &:before {
                @extend %icon-font;
                position: absolute;
                font-size: 1.7em;
                top: -0.4em;
                left: -0.1625em;
                z-index: 1;
            }

            span {
                @include header-font;
                position: absolute;
                width: 1.2625em;
                left: 0;
                top: em-calc(-2);
                font-weight: 500;
                color: $white;
                text-align: center;
                line-height: 1;
                z-index: 2;
            }
        }

        button {
            display: none;
            font-size: 1em;
            position: absolute;
            right: 2.15em;
            top: 0;
            bottom: 0;
            margin: auto;
            height: em-calc(40);
            color: #000;
            border: 1px solid #000;

            @include large-up {
                display: block;
            }

            &.hidden {
                opacity: 0;
                padding: 0;
                margin: 0;
                border: none;
                max-width: 20px;
                width: 20px;
                position: absolute;
                right: 0;
            }
        }
    }

    .booking-summary__fixed-price {
        align-items: center;

        @include small-only {
            display: flex;
        }

        p {
            text-align: left;
            width: 100%;
            margin: 0 20px;
        }
    }

    .booking-summary-mobile {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        background: $white;
        border-bottom: 1px solid $light-booking-grey;
        z-index: 10;
        display: flex;
        flex-direction: row;

        @include medium-up {
            display: none;
        }

        &__closing {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid $light-booking-grey;
        }

        ul {
            @include navigation-list(left);

            > li {
                cursor: pointer;
                position: relative;
                height: 50px;
                line-height: 50px;
                padding-left: 1.8em;
                padding-right: 1.8em;
                font-size: 12px;

                @media screen and (max-width: 350px) {
                    padding-left: 1.6em;
                    padding-right: 1.6em;
                }

                &:after {
                    @extend %icon-font;
                    @extend .icon-small-arrow-right:before;
                    position: absolute;
                    right: em-calc(-8);
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 1em;
                }

                &:last-child::after {
                    display: none;
                }
            }

            .summaryDone {
                color: $bullet-green;

                &:after {
                    color: $bullet-green;
                }
            }

            .summaryOn {
                color: $black;

                &:after {
                    color: $black;
                }
            }

            .summaryLeft {
                color: $grey;

                &:after {
                    color: rgba($grey, 0.8);
                }
            }
        }
    }

    .booking-flow-inner {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &.checkout .booking-wrapper {
            @include transform('translateX(-50%)', null);
        }

        .booking-wrapper {
            position: relative;
            @include transform('translateX(0%)','.6s ease-in-out');
            width: 200%;
            height: 100%;
            @include clearfix;

            .booking, .checkout {
                float: left;
                width: 50%;
                height: 100%;
                overflow-x: hidden;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;

                .touch & {
                    position: relative;
                }
            }
        }

        .modalDialog {
            position: absolute;
            pointer-events: all !important;
            z-index: 99;
            width: 100%;

            a {
                color: black;
                text-decoration: underline;

                @include large-up {
                    font-size: rem-calc(18);
                }
            }
            &.modalDialog--closedbooking {
                .close-btn {
                    position: absolute;
                    top: 10px;
                    margin: 0;
                    font-size: 1.5rem;
                    right: 20px;
                    cursor: pointer;
                    color: #202020;
                    z-index: 2;
                }
                .modal-container {
                    overflow-y: auto;
                    height: 100%;
                }
            }
        }
    }

    .booking-flow {
        position: fixed;
        top: 0;
        right: 0;
        background-color: $white;
        background: #ececec;
        height: 100%;
        width: 100%;
        text-align: center;
        color: black;
        box-shadow: 0 0 57px 0 rgba($black, 0.3);
        z-index: 10;
        @include transform('translateX(100%)', .6s ease-in-out);

        .show-booking & {
            @include transform(none, null);

            @media screen and (max-width: 1150px) {
                z-index: 12;
            }
        }

        @include small-only {
            .show-nav & {
                //@include transform('translateX(200%) translateX(-40px)', null);
            }
        }

        section {
            position: relative;
            min-height: 100vh;
            min-height: calc(var(--vh, 1vh) * 100);

            > .center {
                @extend %vertical-align;
            }
        }

        .modal-content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $white;
            width: calc(100% - 40px);
            max-width: 1024px;
            height: 450px;
            margin: auto;
            padding: 70px 40px;
            border-radius: $radius;
            box-shadow: 0 0 65px rgba($black,0.2);

            &.modal-content--extra-height {
                height: 630px;
            }

            @include small-only {
                padding: 40px 20px;

                p {
                    font-size: 14px;
                }
            }

            p {
                font-size: rem-calc(16);
                font-weight: 300;

                @include large-up {
                    font-size: rem-calc(18)
                }
            }

            .sub-header {
                font-weight: bold;
                font-size: rem-calc(20);
                margin-bottom: rem-calc(40);

                @include medium-up {
                    font-size: rem-calc(26);
                }

                @include large-up {
                    font-size: rem-calc(36);
                    margin-bottom: rem-calc(50);
                }
            }

            &.continue-booking {
                height: 350px;

                @include small-only {
                    height: 400px;
                }
            }

            .continue-booking-button {
                margin-right: 20px;

                @include small-only {
                    margin-right: 0;
                }
            }

            .cross-promotion {
                padding: 0 0 30px;

                @include medium-up {
                    padding: 0 50px 30px;
                }
                @include large-up {
                    padding: 0 190px 30px;
                }


                &.cross-promotion--feriehusesondervig {

                }
            }

            .not-price-match {
                display: flex;
                justify-content: center;
                margin-bottom: rem-calc(35);

                @include large-up {
                    width: 70%;
                    margin-bottom: rem-calc(60);
                }

                .column {
                    @include small-only {
                        padding-left: 0.7rem;
                        padding-right: 0.7rem;
                    }

                    &:last-child {
                        border-left: 1px solid #ECECEC;
                    }
                }
            }
            .close-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                top: 0;
                width: 60px;
                height: 60px;
                cursor: pointer;

                i {
                    color: #202020;
                }
            }
            .close-btn {
                margin-top: 20px;
            }
        }

        .optional-selections-wrapper {
            background-color: $white;

            .optional-selections {
                border: none;

                .button-group {
                    &.grey {
                        @include gradient(($white, darken($booking-grey, 3%)), 'linear');

                        .no-touch:not(.dynamicliteraleditmode) &:not(.disabled):hover {
                            @include gradient(($white, darken($booking-grey, 3%)), 'linear');
                        }
                    }
                }

                .price.radio-check {
                    .radio label {
                        border: 1px solid $bg_green;
                        font-size: 15px;
                        line-height: 22px;
                        font-weight: 400;
                    }

                    .checkbox-wrapper {
                        margin: 13px 0;

                        &:last-child {
                            margin-left: 15px;
                        }
                    }
                }
            }
        }

        .extra-options {
            background-color: $white;
        }

        .cancellation-order {
            border-bottom: 1px solid #ECECEC;
            color: $black !important;

            p:nth-of-type(2) {
                margin-bottom: 25px;
            }

            p, a {
                font-size: 16px;
                font-weight: 400;
                color: $black;

                @include small-only {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            .optional-selections {
                font-weight: 100;
                font-size: 100%;
                border: none;
            }

            .checkbox-wrapper {
                display: block;
                margin: 8px 0 0.5rem;
                text-align: center;

                @include medium-up {
                    margin-bottom: auto;
                }

                & > label {
                    display: inline-block;
                    max-width: 90%;
                    font-size: 0.85rem;
                }
            }

            .error {
                margin: 0;
                text-align: center;
            }

            .row:last-of-type {
                //margin-top: 1rem;
                padding-top: 0;
            }

            &__link {
                text-decoration: underline;
            }
        }

        .row .row .row {
            margin-bottom: 0;
        }

        .back-button {
            position: absolute;
            background: $white;
            left: 0px;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            transform: translateX(-50%) rotate(-90deg);
            transform-origin: center bottom;
            cursor: pointer;

            @include small-only {
                line-height: 45px;
                height: 35px;
            }

            span {
                padding: 10px;
                display: flex;
                @include transition('opacity .3s ease-in-out');
                font-size: 14px;
            }

            &-dot,
            .icon-small-arrow-left {
                margin: 0 20px;
            }

            &-dot {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                @include gradient($green, 'linear');
                box-shadow: 0 2px 0 0 $bg_dark-green;
            }

            i {
                @include transform('rotate(90deg)', '.6s ease-in-out .3s');

                .show-booking & {
                    @include transform('rotate(180deg)', null);
                }
            }
        }
    }
}
.mobile-space {
    @include small-only {
        margin-bottom: 60px;
    }
}
