.house-information {
    .house-calendar & {
        overflow: hidden;
    }

    .house-slider {
        @include large-up {
            height: 370px;
        }

        padding: 0;

        .slider {
            padding-bottom: 0;
        }

        .prev-btn {
            margin-left: 10px;

            @include small-only {
                margin-left: 5px;
            }
        }

        .next-btn {
            margin-right: 10px;

            @include small-only {
                margin-right: 5px;
            }
        }
    }

    .feriehuse {
        //background: $lightest-grey;
        width: 100%;
        overflow: hidden;

        > .row {
            margin-bottom: 0 !important;
            min-height: 320px;
        }

        h3 {
            color: $dark-grey;
        }

        p {
            color: $dark-grey;

            &.sub-header {
                margin-bottom: 0.5em;
            }
        }

        ul {
            padding-left: 0;
            padding-bottom: rem-calc(10);
        }

        li {
            list-style-type: none;

            p {
                font-weight: bold;
                line-height: 2em;
            }
        }
    }

    .feriehuse-info {
        background: $white;
        width: 100%;
        padding-top: rem-calc(40);
        padding-bottom: rem-calc(40);
        overflow: hidden;

        .booking & .demo-info {
            margin: 35px 11px;
        }

        .row {
            padding-bottom: rem-calc(10);
        }
    }

    .house-calendar & {
        .feriehuse-info, .feriehuse-info-shrink {
            @include transition('max-height .6s ease-in-out');
            max-height: 0;
        }

        .moreinfo .feriehuse-info {
            max-height: 1000px;
        }
    }

    .icon-rotate {
        @include transform('rotate(45deg)', null);
    }
}

.house-information.fullpage-house {
    @include small-only {
        background-color: #ececec !important;
    }

    .house-detail-collapse {
        &__detail {
            display: flex;
            justify-content: center;

            .feriehuse {
                max-width: 1200px;

                .feriehuse-info-expander {
                    display: none !important;
                }

                .less-text-mobile {
                    white-space: normal;
                }
            }
        }

        &__image {
            display: none;
        }

        &__detailed-info {
            display: block;
            max-height: none;
        }

        &__see-more {
            display: none;
        }

        &__h3-seemore h3 {
            @include small-only {
                display: block !important;
                margin-top: 30px;
            }
        }
    }

    .house-info__house {
        .column:first-child {
            @include small-only {
                padding-left: 0 !important;
            }
        }
    }

    .info-btn {
        display: none;
    }

    .feriehuse {
        margin-bottom: 60px;
    }
}

.img-ie-fix {
    position: static !important;
    height: 100% !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @include medium-up {
        background-position: top center;
        margin-right: rem-calc(-14);
    }
}

.house-detail-collapse {
    background-color: $light-booking-grey;
    padding: 0 !important;
}

//collapse panel
.house-detail-collapse {
    background-color: $light-booking-grey;
    padding: 0 !important;
    color: $black;

    .hero-accordion-section {
        display: inline;

        @include medium-up {
            float: right;
        }

        i {
            color: $grey !important;
        }

        p {
            float: left;
            padding: 0 !important;
            margin-bottom: 0 !important;
            margin-right: rem-calc(20) !important;
        }
    }
    //New design - Tab
    //New design - Image
    &__image {
        @include small-only {
            max-height: 120px;
            height: 120px;
        }

        max-height: 340px;
        height: 340px;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: -50%;
            bottom: -50%;
            margin: auto;
            right: 0;
            left: 0;
        }
    }
    //New design - info
    &__detail {
        border-radius: $radius;

        @include small-only {
            overflow: visible;
        }

        @include medium-up {
            width: 90%;
            margin-left: 5%;
            margin-top: -60px;
        }

        .feriehuse {
            border-radius: $radius;
            background-color: $light-booking-grey;
            padding: 30px;

            @include small-only {
                padding: 0px 20px 0 20px;
                overflow: visible;
                background-color: transparent;
                margin-top: 12px;
            }

            .less-text-mobile {
                @include small-only {
                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .more-text-mobile {
                @include small-only {
                    white-space: normal;
                }
            }
        }

        p {
            font-size: 14px;
        }
    }

    &__h3-seemore {
        display: flex;
        justify-content: space-between;
    }

    &__see-more {
        @include gradient($black-gradiant, 'linear');
        max-height: 40px;
        border-radius: 5em;
        padding: 9px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        @include small-only {
            width: 100%;
            z-index: 3;
        }

        @include medium-up {
            width: 310px;
        }

        a {
            font-size: 12px;
            width: 50%;

            span {
                text-decoration: underline;
            }

            i {
                padding-right: 10px;
            }
        }

        a:first-child {
            border-right: 1px solid $black;
        }
    }
    //Small info
    &__detailed {
        //Desktop
        &__first-info {
            @include medium-up {
                display: flex !important;
                justify-content: space-between;
            }

            .first-small-info-each {
                display: flex;
                flex-direction: column;
                float: left;

                @include medium-up {
                    width: 20%;
                }

                p {
                    margin: 0;
                    text-align: left;

                    i {
                        margin-right: 10px;
                        padding-top: 4px;
                        color: $black;
                    }

                    @include medium-up {
                        display: flex;

                        span {
                            max-width: 140px;
                            word-wrap: break-word;
                            padding-bottom: 4px;
                        }
                    }
                }

                .text-description {
                    font-size: 14px;
                }

                a {
                    color: $black;

                    span {
                        text-decoration: underline;
                    }

                    i {
                        margin-left: 10px;
                    }
                }

                span {
                    max-width: 100px;
                }
            }



            @include medium-up {
                .first-small-info-each:last-child {
                    width: 60%;
                }
            }

            @media all and (max-width: 1100px) and (min-width: 690px) {
                margin-top: 10px;
                flex-direction: column;

                .first-small-info-each {
                    width: 100%;
                    border-bottom: 1px solid #C0C0C0;
                    padding-top: 10px;
                }

                .first-small-info-each:last-child {
                    width: 100%;
                    padding-bottom: 10px;
                    border-bottom: none;

                    p {
                        width: 100%;
                    }
                }
            }
        }
    }
    //New design - more info
    .feriehuse-info-expander {
        padding-bottom: 20px;

        a {
            color: $black;

            span {
                font-weight: 400;
                text-decoration: underline;
                margin-right: 15px;
            }
        }
    }

    &__detailed-info {
        display: none;
        @include transition('max-height .3s ease-in-out');
        max-height: 0;

        .each-section {
            width: 100%;
            border-bottom: 1px solid #C0C0C0;
            margin-top: 20px;
            padding-bottom: 20px;

            @include medium-up {
                margin-top: 40px;
            }
        }

        .house-slider {
            margin: 20px 0;

            @include medium-up {
                margin: 40px 0;
                min-height: 420px;
            }

            img {
                max-width: 100%;
            }
        }


        .person-size {
            color: $black;
            display: flex;
            flex-direction: row;

            p {
                margin: 0 20px 0 0;
            }

            i {
                margin-right: 5px;
            }
        }

        .list-included {
            margin-top: 20px;

            @include small-only {
                display: block !important;
                flex-direction: column;
            }

            @media all and (max-width: 1100px) and (min-width: 690px) {
                display: none !important;
            }

            i {
                margin-right: 10px;
            }
        }

        .house-info {

            @include medium-up {
                display: flex;
                flex-direction: row;
                border-bottom: 0;
            }


            &__house {
                @include small-only {
                    .row {
                        display: flex;
                        margin-bottom: 20px;
                    }
                }

                @include medium-up {
                    order: 2;
                    width: 60%;

                    .row {
                        margin-bottom: 20px !important;
                    }
                }
            }

            &__img {
                @include medium-up {
                    width: 40%;
                    height: 100%;
                    padding-right: 40px;
                    order: 1;
                    flex-grow: 4;
                }

                span {
                    display: none;
                }
            }
        }

        :not(.house-plan-bigger) {
            .house-info__img {
                img {
                    cursor: pointer;
                }
            }
        }

        .house-plan-bigger {
            @include medium-up {
                flex-direction: column;
                margin-top: 0;
            }


            .house-info__house {
                margin-top: 20px;
                width: 100%;
            }

            .house-info__img {
                @include medium-up {
                    width: 100%;
                    position: relative;
                    padding: 0;
                }

                span {
                    background-color: white;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;

                    @include medium-up {
                        display: block;
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }

                    i {
                        font-size: 2.5rem;
                        opacity: 0.7;
                        color: $black;

                        &:hover {
                            opacity: 1;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .included {
            @include medium-up {
                border-bottom: 1px solid #C0C0C0;
                padding-bottom: 40px;
            }

            h3 {
                color: $black;
                font-size: 14px;
                font-weight: bold;
                overflow: hidden;
            }

            h3:after {
                @include medium-up {
                    width: 100%;
                    height: 1px;
                    content: "";
                    display: inline-block;
                    position: relative;
                    background-color: #C0C0C0;
                    vertical-align: middle;
                    left: 20px;
                    margin-right: -50%;
                }
            }

            &__list {
                @include medium-up {
                    display: flex;
                    flex-direction: row;
                }

                &__each {
                    display: flex;
                    align-items: center;

                    @include medium-up {
                        width: 33%;
                    }

                    .blue-circle {
                        width: 40px;
                        height: 40px;
                        min-width: 40px;
                        margin: 10px 20px 10px 0;
                        background-image: linear-gradient(180deg, #3C99D8 0%, #006EB9 100%);
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media all and (max-width: 1100px) and (min-width: 690px) {
                            width: 40px;
                            height: 40px;
                            margin: 10px 0;
                        }

                        @media all and (min-width: 1101px) {
                            width: 60px;
                            height: 60px;
                            min-width: 60px;
                        }

                        i:before {
                            font-size: 20px;

                            @include medium-up {
                                font-size: 30px;
                            }

                            @media all and (max-width: 1100px) and (min-width: 690px) {
                                font-size: 20px;
                            }
                        }
                    }

                    p {
                        margin-top: 0;

                        @include medium-up {
                            margin-right: 20px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }

        .btn-shrink {
            a {
                color: $black;

                span {
                    font-weight: 400;
                    text-decoration: underline;
                    padding-right: 10px;
                }

                i {
                    font-size: 8px;
                }
            }
        }
    }

    &__detailed-info.feriehuse-info-expanded {
        display: flex;
        flex-direction: column;
        max-height: 2500px;
    }
}

