section {
	> h4 + .row {
		padding-top: 0;
	}
	&.cover {
		background-size: cover;
		background-position: 50% 50%;
		.temp-bg {
			background-size: cover;
			background-position: 50% 50%;
		}
	}

    &.cover_landscape {
		background-size: 100% auto;
		background-position: top center;
        background-repeat: no-repeat;
		.temp-bg {
			background-size: 100% auto;
			background-position: top center;
			background-repeat: no-repeat;
		}
	}
	&.orange {
		@include gradient($orange, 'radial');
		@include light;
	}

	&.blue {
		@include gradient($blue, 'radial');
		@include light;
	}

	&.yellow {
		@include gradient($yellow, 'radial');
		@include light;
		p:not(.display){
			@include dark;
		}
	}

	&.green {
		@include gradient($green, 'radial');
		@include light;
	}

	&.turkis {
		@include gradient($turkis, 'radial');
		@include light;
	}
	&.grey {
		background: $bg-grey;
	}
	&.front-page:first-of-type {
		position: relative;
		padding-top: rem-calc(160);
		width: 100%;
		min-height: rem-calc(540);
		max-height: rem-calc(720);
		height: 75vh;
	}

}