﻿.corona {
    .content-container,
    nav.global {
        margin-top: 130px;

        @media screen and (min-width: 1150px) {
            margin-top: 100px;
        }
    }
}

.corona-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    padding: 15px;
    background: #015C8D;
    z-index: 10;
    height: 130px;
    display: flex;
    align-items: center;

    @media screen and (min-width: 650px) {
        padding: 25px;
    }

    @media screen and (min-width: 1150px) {
        height: 100px;
    }

    .show-booking & {
        @media screen and (max-width: 1150px) {
            transform: translate(-100%) translate(40px);
            transition: transform .4s ease-in-out;
        }
    }
}

.corona-button {
    position: absolute;
    right: 0;
    top: 0;
}

.close-corona {
    font-size: 2rem;
    margin: 0;
    padding: 0px;
    line-height: 1rem;
    display: block;
    border: none;
    font-weight: 300;
    background: none !important;
    color: inherit !important;
}

.corona-text {
    width: calc(100% - 40px);

    p {
        margin-bottom: 0;
        @include prim-font;
        font-size: 12px;

        @media screen and (min-width: 650px) {
            font-size: 15px;
        }

        span {
            @include prim-font;
        }
    }
}

.corona-container__content {
    position: relative;
    width: 100%;
}

.hide-corona {
    display: none !important;
}

.corona .nav__sub-nav {
    top: 160px !important;
}

.corona .sondervig .nav__sub-nav {
    top: 59px !important;
}

.nav-item--dropdown .nav__sub-nav {
    top: 52px !important;
}

.corona .lang-selector .nav__sub-nav {
    top: 62px !important;
}

.corona .small-nav {
    padding-bottom: 250px;
}

// Corona overlay/modal
.corona-modal {
    .modal-wrapper {
        @include small-only {
            height: calc(100% - 40px) !important;
            max-height: 500px !important;
        }

        .close-btn {
            top: 7px !important;
            right: 7px !important;
            color: $black !important;
        }

        .modal-container {
            max-height: calc(100% - 60px) !important;



            @include small-only {
                padding: 0 10px 40px 10px !important;
            }

            @include medium-up {
                height: 100% !important;
            }

            .article-block > .row {
                @include medium-up {
                    padding-top: 30px !important;
                }

                h4 {
                    @include medium-up {
                        margin-top: 0px !important;
                    }
                }
            }
        }
    }
}
