﻿.checkout {
    text-align: left;

    .sub-header {
        margin-top: 30px;
    }

    a:not(.button):hover {
        text-decoration: none;
    }

    .checkbox-wrapper {
        width: 100%;
    }

    .base {
        p {
            font-size: 1rem;
        }
    }

    @include small-only {
        .summary-container {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    .checkout-content {
        margin-top: 40px;

        @include medium-only {
            margin-top: 80px;
        }

        @include large-up {
            margin-top: 120px;
        }
    }

    > .overlay {
        &:not(.modal) {
            width: 100% !important;

            &.final {
                background: $white !important;
            }
        }

        .modal-content {
            text-align: center;

            p {
                text-align: center;
            }
        }

        &.modal {
            position: fixed !important;
            width: 50% !important;
            height: 100% !important;
            @include transform(translateX(100%), null);

            .modal-wrapper {
                position: absolute;
                width: calc(100% - 80px);
                height: calc(100% - 80px);
                max-height: 520px;
                max-width: 1024px;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                background-color: $white;
                border-radius: $radius;
                box-shadow: 0 0 40px rgba($black, 0.2);

                .modal-container {
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 100%;
                    padding: 40px;

                    section div {
                        padding-top: 0;
                    }
                }

                .close-btn {
                    position: absolute;
                    top: -40px;
                    font-size: 1.5rem;
                    right: 0;
                    cursor: pointer;
                }
            }
        }

        .loader {
            opacity: 0.3;
        }

        > p {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 0;
            bottom: 0;
            height: 1em;
            text-align: center;
        }
    }

    .summary-wrap {
        &.multi {
            > .row {
                margin-top: 2px;

                &:first-child {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    margin-bottom: 0 !important;
                    margin-top: 0;
                }

                &:last-child {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
    }

    .summary-container {
        position: relative;

        .summary-tools {
            position: absolute;
            bottom: -50px;
            right: 40px;
            height: 50px;
            z-index: 5;
            @include gradient($blue, 'linear');
            border-bottom-left-radius: $radius;
            border-bottom-right-radius: $radius;
            padding: 0.25em 0;

            > button {
                border: 0;
                padding: 0.1em 1.3em;
                border-left: 1px solid rgba($white, 0.3);
                border-radius: 0;

                &:first-child {
                    border: 0;
                }

                &:hover {
                    background: transparent;
                    color: $light-grey;
                }

                i {
                    vertical-align: middle;
                    font-size: 1.4em;
                    padding-right: 0.3em;
                }
            }

            .order-share {
                @extend .tool-tip;
                bottom: em-calc(-130);
                left: -60px;
                right: 0;
                width: em-calc(300);
                height: em-calc(120);
                padding: $column-gutter/2;
                text-align: center;

                > p {
                    text-align: center;
                    font-size: 1rem;
                    font-weight: 300;
                    margin: 0;
                }

                > input {
                    margin: 0;
                }

                &:after {
                    border-left: em-calc(5) solid transparent;
                    border-right: em-calc(5) solid transparent;
                    border-bottom: em-calc(5) solid $black;
                    top: em-calc(-4);
                    left: 0;
                    right: 0;
                }
            }
        }
    }

    .checkout-wrapper-row {
        margin-top: 30px;
        padding-top: 0;

        @include small-only {
            margin-top: 20px;
        }
    }

    .checkout-wrapper {
        max-width: 1170px;
        float: none;
        margin: auto;

        @include small-only {
            margin-bottom: 60px;
        }
    }

    .checkout-house-image {
        display: none !important;

        @include large-up {
            display: table-cell !important;
        }
    }

    .checkout-house-details {
        background-color: $white;
        color: $black !important;

        p {
            color: $black;
        }

        .subheader {
            font-size: 24px;
            font-weight: 300;
        }
    }

    .booking-customerinfo {
        min-height: 0 !important;

        > .row {
            padding-top: 0;
            padding-bottom: 0;
        }

        .medium-offset-4 {
            @include small-only {
                margin-top: 20px;
            }
        }
    }

    p {
        text-align: left;

        &.total {
            line-height: 2;
        }

        &.info {
            font-size: 0.8rem;
            font-weight: 500;
            text-align: center;
            margin: 1rem;
        }
    }

    a:not(.cancelButton) {
        color: $black;
        text-decoration: underline;
    }

    input[type=text],
    input[type=number],
    input[type=search],
    input[type=password],
    input[type=date],
    input[type=email],
    textarea {
        font-size: 0.85rem;
        font-weight: 500;
        width: calc(100% - 2px);
        background-color: $white;
    }

    .selectric {
        background-color: $white;
        box-shadow: none;
        margin-top: 8px;
    }

    select {
        background-color: $white;
        box-shadow: none;
    }

    .checkbox label {
        background: $white;
        border: 1px solid $light-grey;

        &:after {
            border: 3px solid #B9DF23;
            border-top: none;
            border-right: none;
        }
    }

    .selectricItems {
        &:before {
            border-color: transparent transparent darken($booking-grey, 3%) transparent;
        }

        .selectricScroll {
            background: darken($booking-grey, 3%);
        }

        li:not(.selected):hover {
            &:hover {
                background: darken($booking-grey, 6%);
            }
        }
    }

    label:not(.checkbox) {
        font-size: 1.2rem;
        text-align: right;
        display: inherit;
        font-weight: 100;

        @include small-only {
            font-size: 1rem;
            text-align: left;
            margin-top: 1rem;
        }
    }



    .checkbox-wrapper {
        margin: 0.5rem 0;

        &:first-child {
            margin-top: 0;
        }
    }

    .checkbox, .radio {
        + label {
            display: inline-block;
            text-align: left;
            padding-left: 20px;
            font-weight: 300;
            width: calc(100% - 20px);
            vertical-align: top;
            font-size: 0.9rem;
            line-height: 1.4;
            margin: 0.2rem 0;
        }
    }

    .payment-info {
        margin-bottom: $column-gutter;

        table.summary {
            background-color: $white;
        }
    }

    .phone-prefix-field {
        float: left;
        width: 55px;
    }

    .phone-field {
        float: left;
        width: calc(100% - 55px);
        max-width: 215px;
    }
}
