﻿.back_btn {
    color: $black !important;
    border: solid $black 2px;
    padding: rem-calc(10);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-right: rem-calc(20);

}

.back_text {
   display:inline;
   position:relative;
   top:3px;

}

.artikel-img {
     padding-top:rem-calc(30);
	@include large-up{
		padding-top:rem-calc(0);
	}
}

.artikel-img {
	.slider-inner {
		height: 250px;
		@include large-up{
			height: 350px;
		}
	}
}

.article__item-header {
    @include prim-font;
    @extend .manchet;
    font-weight: 600;
    text-transform: unset;
}

.article__item-text {
    p {
        @extend .manchet;
    }
}