@import "offcanvas";
@import "header";
@import "navigation";
@import "footer";
@import "booking";
@import "booking-choosecenter";
@import "booking-confirm";
@import "booking-dates";
@import "booking-stay";
@import "booking-summary";
@import "checkout";
@import "map";
@import "specialRateCalendar";
@import "cookie";
@import "corona";
@import "slider";
@import "artikel";
@import "tabs";
@import "offers";
@import "presse";
@import "praktiskinfo";
@import "specialoffer";
@import "kolonneinfo";
@import "information";
@import "ducksuite";
@import "aktivitetskalender";
@import "section-navigation";
@import "summary";
@import "quick-booking";
@import "fixed-price";
@import "sections/all-sections";
@import "langSelect";
@import "information-menu";