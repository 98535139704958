﻿.quick-book {

    header & {
        @include small-only {
            display: none;
        }
    }


    @include medium-up {
        text-align: left;

        .panel & {
            text-align: center;
        }

        .selectric {
            border-radius: 30px;

            i {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        select,
        .selectric .label,
        input.quick-input {
            padding: 10px;
            border-radius: 30px;
            font-size: 12px;
            line-height: 13px;
            font-weight: 400;

            @include large-up {
                padding: 12px 15px;
                font-size: 14px;
                line-height: 15px;
            }

            @include xxlarge-up {
                padding: 15px;
            }
        }
    }

    @include large-up {
        margin-left: rem-calc(125);
        margin-right: rem-calc(250);
        margin-top: rem-calc(60)
    }

    @include xlarge-up {
        margin-left: rem-calc(250);
    }

    text-align: center;
    z-index: 1;

    > div {

        @include medium-up {
            display: inline-block;
            max-width: 206px;
            flex-basis: 100px;
            padding-left: 10px;
            vertical-align: middle;
        }


        @include large-up {
            flex-basis: 120px;
        }

        @include xlarge-up {
            flex-basis: 175px;
        }

        @include xxlarge-up {
            flex-basis: 20%;
        }

        &:first-child {
            padding-left: 0;
            flex-grow: 1;

            @include xlarge-up {
                flex-grow: 0;
            }
        }

        &:nth-child(3),
        &:nth-child(4) {
            flex-grow: 1;
        }

        &:last-child {
            width: auto;
        }

        .button {
            font-size: 12px;
            padding: 10px 16px;
            line-height: 1.1;

            @include large-up {
                font-size: 18px;
                line-height: 18px;
                padding: 10px 24px;
            }

            @include xxlarge-up {
                font-size: 24px;
                line-height: 26px;
            }
        }
    }

    &.house-book {
        margin: 20px 10px;

        @include medium-up {
            margin: 40px auto;
        }

        > div {

            @include small-only {
                width: 100%;
                max-width: 20rem;
                margin-bottom: 1rem;
                margin-left: auto;
                margin-right: auto;
            }

            width: 25%;

            &.below {
                position: relative;
                display: block;
                margin: auto;
                top: 30px;
            }

            &:nth-child(4n) {
                margin-bottom: 0;
            }
        }

        .multi-select {
            .quick-input {
                display: block;
                font-size: 12px;
                line-height: 18px;
                min-height: 18px;
                font-weight: 500;
                -webkit-font-smoothing: antialiased;
                margin: 0 30px 0 0;
                padding: 9px 15px;
                height: auto;
                border-radius: 18px;
                color: #363636;
                width: 100%;
            }
        }
    }

    .button-inc-dec .button-group {
        height: 30px;

        .guest-group-amount {
            padding: 0 10px;
        }
    }
}
