﻿.ducksuit-block {
    margin-top: 60px;

    &__instagram {
        padding: 40px 0px;

        &.orange {
            @include gradient($orange, 'linear');
        }

        &.blue {
            @include gradient($blue, 'linear');
        }

        &.yellow {
            @include gradient($yellow, 'linear');
        }

        &.green {
            @include gradient($green, 'linear');
        }

        &.black {
            @include gradient($black-gradiant, 'linear');
        }

        &.white {
            @include gradient(($white, $white), "linear");
            @include dark;
        }

        &.turkis {
            @include gradient($turkis, 'linear');
        }

        &.purple {
            @include gradient($purple, 'linear');
        }

        &.light-orange {
            @include gradient($light-orange, 'linear');
        }

        &.dark-blue {
            @include gradient($dark-blue, 'linear');
        }

        &.dark-green {
            @include gradient($dark-green, 'linear');
        }

        &.medium-grey {
            @include gradient(($medium-gradient), 'linear');
        }

        &.coffee {
            @include gradient($coffee, 'linear');
        }

        &.relax {
            @include gradient($relax, 'linear');
        }

        &.grey {
            @include gradient($grey-gradient, 'linear');
        }

        h4 {
            @include small-only {
                margin-bottom: 30px;
            }
        }

        .pure-js-carousel-btn {
            z-index: 2 !important;
        }

        @include small-only {
            #ds-widget {
                padding: 0 !important;
            }

            .column.small-10.medium-12.small-centered {
                width: 100% !important;
            }
        }
        // Our code overvriting the styling
        .next, .prev {
            transform: none !important;

            @include small-only {
                display: none;
            }
        }

        p {
            width: 80%;
            margin-left: 10%;
            @extend .manchet;

            @include small-only {
                margin-top: 0;
            }
        }
    }
}


#baguetteBox-overlay {

    button {
        background-color: $white;

        svg polyline, svg g {
            stroke: $black;
        }
    }
}