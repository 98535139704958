.spot-carousel-block {
    position: relative;
    text-align: center;

    &-row {
        padding-top: 30px;

        @include medium-up {
            padding-top: 60px;
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }

    > h4, .bullet-wrapper {
        position: relative;
        z-index: 2;
    }
    .bullet-wrapper {
        bottom: -10px;
    }

    > h4 {
        margin-top: 0;
        margin-bottom: 15px;

        @include small-only {
            padding: 0 15px;
        }

        @include medium-up {
            margin-bottom: 30px;
        }
    }

    p {
        @extend .manchet;
    }

    .block-slider {
        position: relative;
    }

    .slider-item {
        > .row {
            display: flex;
            flex-grow: 1;
        }
    }
    .slider-inner {
        margin-bottom: 0;
        @include xxlarge-up {
            width: 70vw;
        }
    }
}

.spot-carousel__item-box {
    &.panel {
    }

    @include small-only {
        .panel-content {
            height: auto;
        }

        .open-content & {
            .panel-content {
                height: 100%;
            }
        }
    }
}

.spot-carousel__item {
    display: flex;
    flex-direction:column;
    padding: 0;

    @include medium-up {
        flex-direction: row;
        border-radius: 8px;
        overflow: hidden;
    }


}

@include small-only {
    .spot-carousel__item-box {
        position: relative;

        &.panel {
            @media (max-width: 374px) {
                padding: 20px 17px;
            }
        }

        .panel-content {
            height: 100%;
            justify-content: space-between;
        }
    }
  
    .spot-carousel__item-text-wrapper {
        .more-text {
        }

        .more-dots {
            display: inline-block;
        }

        .more-content {
            display: none;

            .less & {
                display: inline-block
            }
        }

        .more-link {
            display: inline-block;
            width: 20px;
            height: 20px;
            cursor: pointer;

            i {
                transition: transform 0.3s ease-in;
                display: block;
                font-size: 10px;
                line-height: 20px
            }
        }

        .less {
            .more-dots {
                display: none;
            }

            .more-content {
                display: inline;
            }

            .more-link {
                i {
                    transform: rotate(180deg);
                }
            }
        } 
    }

    .spot-carousel-block__cta {
        z-index: 2
    }
}

