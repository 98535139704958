.overlay, .local-overlay {
	@include transition(opacity .3s ease-in-out);
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	background: rgba(32, 32, 32, 0.7);
	z-index: 100;
	opacity: 0;
	pointer-events: none;
    .ie & {
        &:not(.modal){
            display: none;
        }
        
    }
	#bookingPanel & {
		pointer-events: all;
        
	}

    .ie #bookingPanel & {
        display: block;
    }

	.lock .content-container &  {
		opacity: 1;
		pointer-events: all;
        
		.loader {
			display: block;
		}
		
	}
    .ie.lock .content-container & {
        display: block;
    }

	@include small-only {
		.show-nav .content-container &  {
			opacity: 1;
		}
	}

	.loader {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 150px;
		height: 150px;
		margin: auto;
		border-radius: 50%;
		border: 3px solid transparent;
		border-top-color: #1b7dc0;
		-webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
		animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
		&:before {
			content: "";
			position: absolute;
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #e83941;
			-webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
			  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
		}
		&:after {
			content: "";
			position: absolute;
			top: 14px;
			left: 14px;
			right: 14px;
			bottom: 14px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #25aa5d;
			-webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
			animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
		}
	}
}
.local-overlay {
	position: absolute;
	opacity: 1;
	z-index: 4;
	#bookingPanel & {
		background: rgba(255, 255, 255, 0.6);
	}
	&.ng-hide {
		opacity: 0;
	}
}

.overlay {
    position: fixed;
    width: 100%;

    #bookingPanel & {
        position: absolute;
        background: rgba(255, 255, 255, 0.6);
        @include transition(opacity .30s ease-in-out);
        opacity: 1;
        width: 50%;

        /* IE10+ */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 100%;
        }

        .loader {
            display: block;
        }

        &.ng-hide {
            opacity: 0;
        }
    }

    #bookingPanel .fixed-price & {
        width: 100% !important;
        transform: none;
    }
}

@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}