﻿table.summary {
    background-color: $bg-grey;
    font-size: 0.85rem;
    border-radius: 8px;
    width: 100%;
    max-width: 340px;
    text-align: left;

    tr {
        &:first-child td {
            padding-top: 1.2em;
        }

        &:last-child td {
            padding-bottom: 1.7em;
        }

        td {
            padding: 0.3em 1.7em;
            line-height: 1;

            &:last-child {
                text-align: right;
                font-weight: 500;
            }
        }
    }
}

table.consumption {
    @extend table.summary;
    padding: 1em;
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
    @include medium-up {
        
        border-spacing: 3px;
    }

    th {
        padding: 0.3em;
        @include medium-up {
            padding: 0.3em 1.7em;
        }

        &:last-child {
            text-align: right;
        }
    }

    tr {
        
        td {
            padding: 0.3em;
            @include medium-up {
                padding: 0.3em 1.7em;
            }
        }

        &:last-child td {
            text-align: right;
        }

        &:last-child {
            td {
                padding: 0.5em 0.3em;
                @include medium-up {
                    padding: 1em 1.7em;
                }
            }
        }
    }
}
