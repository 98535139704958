.panel {
    padding: $column-gutter $column-gutter/2;
    text-align: center;
    @include light;

    a:not(.button):hover {
        text-decoration: none;
    }

    &:before {
        content: '';
        display: inline-block;
        min-height: 100%;
        vertical-align: middle;
        margin-right: -0.25em;
    }

    &.orange {
        @include gradient($orange, 'radial');
    }

    &.light-orange {
        @include gradient($light-orange, 'radial');
        @include dark;

        a:not(.button) {
            @include dark;
        }

        button, a.button {
            color: $black;
            border: 2px solid $black;

            .no-touch &:hover {
                background: $black;
                color: $white;
            }
        }
    }

    &.overflow {
        overflow: visible !important;
    }

    &.white {
        background: $white;
        @include dark;

        a:not(.button) {
            @include dark;
        }

        button, a.button {
            color: $black;
            border: 2px solid $black;

            .no-touch &:hover {
                background: $black;
                color: $white;
            }
        }
    }

    &.blue {
        @include gradient($blue, 'radial');
    }

    &.dark-blue {
        @include gradient($dark-blue, 'radial');
    }

    &.yellow {
        @include gradient($yellow, 'radial');
        /*p:not(.display) {
            @include dark;
        }*/
        @include dark;

        a:not(.button) {
            @include dark;
        }

        button, a.button {
            color: $black;
            border: 2px solid $black;

            .no-touch &:hover {
                background: $black;
                color: $white;
            }
        }
    }

    &.green {
        @include gradient($green, 'radial');
    }

    &.dark-green {
        @include gradient($dark-green, 'radial');
    }

    &.black {
        @include gradient($black-gradiant, 'radial');
    }

    &.turkis {
        @include gradient($turkis, 'radial');
    }

    &.purple {
        @include gradient($purple, 'radial');
    }

    &.medium-grey {
        @include gradient(($medium-gradient), 'radial');
    }

    &.coffee {
        @include gradient($coffee, 'radial');
    }

    &.relax {
        @include gradient($relax, 'radial');
        @include dark;

        a:not(.button) {
            @include dark;
        }

        button, a.button {
            color: $black;
            border: 2px solid $black;

            .no-touch &:hover {
                background: $black;
                color: $white;
            }
        }
    }

    &.grey {
        //@include gradient(($bg-grey, darken($bg-grey, 3%)), 'linear');
        @include gradient($grey-gradient, 'radial');

        .panel-content {
            @include medium-up {
                padding-left: $column-gutter !important;
                padding-right: $column-gutter !important;
            }
        }
    }

    &.dark-grey {
        background: $aktivitetskalender;
    }

    &.image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        .temp-bg {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
    }

    .panel-content {
        display: inline-block;
        vertical-align: middle;
        padding-left: rem-calc(5);
        padding-right: rem-calc(5);

        @include medium-only {
            padding-left: rem-calc(20);
            padding-right: rem-calc(20);
        }

        @include large-up {
            padding-left: rem-calc(30);
            padding-right: rem-calc(30);
        }
    }

    &.left {
        text-align: left;
    }

    .hero *:not(.columns) > & {
        width: rem-calc(360);
    }
}

.small-panel:before {
    min-height: 0 !important;
}

.small-panel {
}

.topblock-left {
    text-align: right;
}

.panel-wide {
    padding-left: 0 !important;
    padding-right: 0 !important;
}


.topblock-left {
    text-align: right;

    @include large-up {
        margin-right: calc(25% + 22px);
        padding-right: 0 !important;
    }

    p {
        text-align: right;
    }

    h2 {
        text-align: right;
    }
}

.topblock-right {
    text-align: left;

    @include large-up {
        margin-left: calc(25% + 22px);
        padding-left: 0 !important;
    }

    p {
        text-align: left;
    }

    h2 {
        text-align: left;
    }
}


.panel-small {
    padding-left: rem-calc(10) !important;
    padding-right: rem-calc(10) !important;
}

.panel-gradient {

    &.orange {
        //@include gradient($orange, 'radial-large');
        @include gradient($orange, 'linear');

        @include light;
    }

    &.blue {
        //@include gradient($blue, 'radial-large');
        @include gradient($blue, 'linear');

        @include light;
    }

    &.yellow {
        //@include gradient($yellow, 'radial-large');
        @include gradient($yellow, 'linear');

        @include light;

        p:not(.display) {
            @include dark;
        }
    }

    &.green {
        //@include gradient($green, 'radial-large');
        @include gradient($green, 'linear');

        @include light;
    }

    &.black {
        //@include gradient($black-gradiant, 'radial-large');
        @include gradient($black-gradiant, 'linear');

        @include light;
    }


    &.turkis {
        //@include gradient($turkis, 'radial-large');
        @include gradient($turkis, 'linear');

        @include light;
    }

    &.purple {
        //@include gradient($purple, 'radial-large');
        @include gradient($purple, 'linear');

        @include light;
    }

    &.light-orange {
        @include gradient($light-orange, 'linear');
    }

    &.dark-blue {
        @include gradient($dark-blue, 'linear');
    }

    &.dark-green {
        @include gradient($dark-green, 'linear');
    }


    &.medium-grey {
        @include gradient(($medium-gradient), 'linear');
    }

    &.coffee {
        @include gradient($coffee, 'linear');
    }

    &.relax {
        @include gradient($relax, 'linear');
    }

    &.grey {
        @include gradient($grey-gradient, 'linear');
    }
}


.panel-center {
    @include medium-up {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        @include transform(translate(-50%, -50%), null);
    }
}

.underline {
    border-bottom: 1px solid $light-grey;
    padding-bottom: 1.3em;
}

.height350 {
    min-height: rem-calc(200);

    li {
        min-height: rem-calc(200);
    }

    @include medium-up {
        height: rem-calc(350);

        li {
            height: rem-calc(350);
        }
    }
}

.height500 {
    min-height: rem-calc(200);

    li {
        min-height: rem-calc(200);
    }

    @include medium-up {
        height: rem-calc(500);

        li {
            height: rem-calc(500);
        }
    }
}
