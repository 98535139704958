.incrementor {
    background: $white;
    border-radius: $radius;
    width: em-calc(170);
    height: em-calc(320);
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.6rem;
    text-align: center;

    @media screen and (max-width: 650px) and (min-width: 850px) {
        height: em-calc(350);
    }

    @include medium-only {
        font-size: 0.7rem;
    }

    @include large-only {
        font-size: 0.8rem;
    }

    @include xlarge-up {
        font-size: 1rem;
    }

    .incrementor-input {
        padding: em-calc(22) em-calc(5);

        i {
            @extend %icon-font;
            font-size: em-calc(120);
            color: $black;
            vertical-align: middle;

            @include small-only {
                font-size: em-calc(120);
            }
        }

        input, span {
            background: transparent;
            border: none;
            border-radius: 0;
            width: 40%;
            font-size: em-calc(60);
            font-weight: 900;
            margin: 0;
            padding: 0;
            height: auto;
            text-align: center;
            vertical-align: middle;
        }
    }

    .guest-group-amount {
        background-color: white;
    }
}

.incrementor-input i {
	.adult & {
		@extend .icon-adult;
	}

	.child & {
		@extend .icon-child;
	}

	.infant & {
		@extend .icon-baby;
	}
}

.incrementor-label {
    margin-bottom: 20px;

    .sub-header {
        font-size: 1rem;
    }
}

.quick-book {
	.incrementor-wrapper {
		width: 180px;
		margin: 1rem 0;
		@include clearfix;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.incrementor-value {
			vertical-align: middle;
			float: left;
			font-size: 2.1rem;
			font-weight: 900;
			line-height: 2rem;
			margin-right: 0.7rem;
		}

		.incrementor-label {
			float: left;
			margin: 0;
			p {
				font-size: 0.9rem;
				text-align: left;
				span {
					display: block;
					font-size: 0.8em;
				}
			}
		}

		.incrementor-buttons {
			float: right;
			margin-top: 0.3rem;
		}
	}
}
