﻿.ak_bg {
    //overflow: visible;
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);
}

.ak_space {
    margin-bottom:rem-calc(10) !important;
}



.ak_text {
    display: inline;
}

.ak_price {
    float: right;
    margin-right: rem-calc(10) !important;
    background: rgba($dark-grey, 0.6);
    padding-top: rem-calc(5) !important;
    padding-bottom: rem-calc(5) !important;
    padding-left: rem-calc(15) !important;
    padding-right: rem-calc(15) !important;

    i {
        font-size: rem-calc(20) !important;
        float: left !important;
        padding-right: rem-calc(10);
    }
}
.ak_block{
    padding-left:rem-calc(20);
    padding-right:rem-calc(20);
}
.ak_panel:before{
    vertical-align:top !important;
   
}

.ak_icon_container {
    background: $dark-grey;
    float: right;
    padding: rem-calc(10);
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;


    .ak_first {
        i {
            float: left;
            font-size: rem-calc(30);
        }
    }

    .ak_second {
        display: none;
        float: left;



        button {
            float: left;
            overflow: hidden;
        }

        p {
            float: left;
            overflow: hidden;
            padding: rem-calc(10);
        }
    }

    &:hover {
        .ak_first {
        }

        .ak_second {
            display: inline;
        }
    }
}
//sticky button
.snav.en {
    left: 0;
    text-align: left;

    li {
        span {
            right:25px;
            
        }
    }
}

.snav.en li:hover span {
    right: 35px;

}

.snav.ar {
    right: 0;
    text-align: right;

    li {
        span {
           // left: -100px;
        }
    }
}

.snav.ar li:hover span {
    //left: 35px;
}


.snav {
    position: absolute;
    right: 10px;
    top: 14px;

    i {
        font-size: rem-calc(30);
        float: right;
    }

    ul {
        list-style: none;

        li {
            span {
                vertical-align: middle;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                padding-top: rem-calc(5);
                padding-bottom: rem-calc(5);
                padding-right: rem-calc(20);
                padding-left: rem-calc(20);
                position: absolute;
                top: 0;
                visibility: hidden;
                z-index: 3;
                border-radius: 5em;
            }

            a {
                text-decoration: none;
                color: #fff;
                display: block;
                position: relative;
            }
        }
    }
}


.snav * {
    margin: 0;
    padding: 0;
    outline: 0;
    transition: all .1s ease;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -o-transition: all .1s ease;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}



.snav .fa {
    z-index: 4; 
}



.snav li:hover .fa {
    transform: rotate(720deg);
}

.snav li:hover span {
    visibility: visible;
}

.oh_date{
    padding-bottom:rem-calc(20) !important;

}

.ak_orange{
    background:$ak_orange;
}
.ak_purple{
    background:$ak_purple;
}

.ak_orange{
    background:$ak_orange;
}

.ak_turkis{
    background:$ak_turkis;
}

.ak_blue{
    background:$ak_blue;
}

.ak_green{
    background:$ak_green;
}
.ak_yellow{
    background:$ak_yellow;
}

.ak_grey{
    background:$ak_grey;
}



