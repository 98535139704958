﻿
//Accordion
.presse-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.presse-accordion {
    &:last-of-type {
        border-bottom: 0;
    }

    .presse-accordion-head {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        padding-top: 0.7em;
        padding-bottom: 0.7em;

        .presse-accordion-text {
            margin-left: rem-calc(70);

            .borderbottom {
                border-bottom: solid rgba(0, 0, 0, 0.3) 1px;
            }
        }

        h3 {
            display: inline;
        }
    }

    .last {
        border: none;
    }

    .presse-accordion-body {
        height: auto;
        display: none;

        .presse-accordion-text {
            margin-left: rem-calc(70);
            padding-bottom: rem-calc(30);

            @include small-only {
                margin: 0;
                padding-top: rem-calc(20);
            }
        }
    }
}

.presse-arrow {


    .open & {


        &:after {
            content: "";
            @include prim-font;
            font-size: rem-calc(20);
            font-style: normal;
        }
    }

    &:before {
    }

    &:after {
        content: "";
        @include prim-font;
        font-size: rem-calc(20);
        font-style: normal;
    }
}

.see-less{
    //display:none;
}
