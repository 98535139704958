﻿.info-break {
    padding-top: rem-calc(80);
}

.info-minibreak {
    padding-top: rem-calc(60);
}

.info-borderbreak {
    padding-top: 20px;

    .open & {
        padding-top: 10px;
    }
}

.info-wrapper {
    text-align: center;
}

.accordian-area {
    a {
        color: $black;
        text-decoration: underline;
    }
}

.info-nav {
    background: $grey;
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    margin-top: rem-calc(20);
    display: inline-block;
    text-align: center;


    .nav {
        display: inline;
        text-transform: uppercase;

        @include small-only {
            display: block;
        }
    }

    .pad20right {
        padding-right: rem-calc(20);

        @include small-only {
            padding-right: 0;
            padding-bottom: rem-calc(10);
        }
    }

    .clicked {
        font-weight: bold;
    }
}

.info-nav-wrapper {
    text-align: center;
}

.info-section-wrapper {
    a {
        text-decoration: underline !important;
         @include dark;
        &:first-child {
            text-decoration: none !important;
            cursor: default;

            &:hover {
                text-decoration: none !important;
                cursor: default;
            }
        }
    }
}

.info-list {

    p {
        @include dark;
        text-align: left;
    }


    ul, ol {
        list-style: none;
        padding: 0;
        font-size: rem-calc(18);
        margin: 0.8em 0;
        font-weight: 300;

        @include large-up {
            font-size: rem-calc(24);
        }

        li {

            @include dark;
            text-align: left;
            padding-left: 1.65em;
            margin: 0.4em 0;
            @extend .manchet;

            &:before {
                content: "";
                line-height: 1em;
                width: rem-calc(13);
                height: rem-calc(13);
                background-color: $bullet-green;
                float: left;
                //margin-top: 0.325em;
                margin-top: 0.225em;
                margin-right: 0.625em;
                margin-left: -1.45em;
                border-radius: 50%;
            }
        }
    }

    ol {
        counter-reset: info-list-ol;

        li:before {
            content: counter(info-list-ol);
            counter-increment: info-list-ol;
            color: $bullet-green;
            background: none;
            margin-top: 0;
            margin-left: -1.25em;
            height: auto;
            line-height: 1;
            font-size: 1.3em;
            font-weight: 700;
        }
    }
}


//Accordion

.info-accordion {
    padding-top: rem-calc(10);

    @include medium-up {
        padding-top: 15px;
        padding-bottom: 0;
    }

    &:last-of-type {
        border-bottom: 0;
    }


    ul {
    }

    .info-accordion-head {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        padding-top: 0.7em;
        //padding-bottom: 0.7em;
        padding-bottom: 10px;

        @include medium-up {
            padding-bottom: 5px;
        }

        .info-accordion-text {
            margin-left: rem-calc(70);

            @include small-only {
                margin-left: rem-calc(52.5);
            }

            .borderbottom {
                border-bottom: solid rgba(0, 0, 0, 0.3) 1px;
            }
        }

        h3 {
            display: inline;
        }
    }

    .last {
        border: none;
    }

    .info-accordion-body {
        height: auto;
        display: none;

        .info-accordion-text {
            margin-left: rem-calc(70);
            //padding-bottom: rem-calc(30);
            p {
                @include dark;
                @extend .manchet;
                text-align: left;
            }

            @include small-only {
                margin: 0;
                //padding-top: rem-calc(20);
                //   padding-top: 10px;
            }
        }
    }
}

.info-arrow {
    @extend %icon-font;
    @extend .icon-plus;

    .open & {
        @extend .icon-minus;
    }

    &:before {
        color: $black !important;
        border: solid $black 2px;
        padding: rem-calc(10);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        margin-right: rem-calc(30);
        position: absolute;
        left: 0;
    }
}
