﻿@mixin gradient($color-range, $type) {
	@if $type == 'linear' {
		background-color: nth($color-range, 1);
		background: -moz-linear-gradient(top, #{nth($color-range, 1)} 0%, #{nth($color-range, 2)} 100%);
		background: -webkit-linear-gradient(top, #{nth($color-range, 1)} 0%, #{nth($color-range, 2)} 100%);
		background: -o-linear-gradient(top, #{nth($color-range, 1)} 0%, #{nth($color-range, 2)} 100%);
		background: -ms-linear-gradient(top, #{nth($color-range, 1)} 0%, #{nth($color-range, 2)} 100%);
		background: linear-gradient(to bottom, #{nth($color-range, 1)}  0%, #{nth($color-range, 2)}  100%);
		//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{nth($color-range, 1)}', endColorstr='#{nth($color-range, 2)}',GradientType=0 );
	} @else if $type  == 'radial' {
		background-color: nth($color-range, 1);
		background: -webkit-radial-gradient(33% 33%, circle closest-side, #{nth($color-range, 1)}, #{nth($color-range, 2)} 300%);
		background: -moz-radial-gradient(33% 33%, circle closest-side, #{nth($color-range, 1)}, #{nth($color-range, 2)} 300%);
		background: -ms-radial-gradient(33% 33%, circle closest-side, #{nth($color-range, 1)}, #{nth($color-range, 2)} 300%);
		background: radial-gradient(33% 33%, circle closest-side, #{nth($color-range, 1)}, #{nth($color-range, 2)} 300%);
	} 
      @else if $type  == 'radial-large' {
		background-color: nth($color-range, 1);
		background: -webkit-radial-gradient(33% 33%, circle closest-side, #{nth($color-range, 1)}, #{nth($color-range, 2)} 2000%);
		background: -moz-radial-gradient(33% 33%, circle closest-side, #{nth($color-range, 1)}, #{nth($color-range, 2)} 2000%);
		background: -ms-radial-gradient(33% 33%, circle closest-side, #{nth($color-range, 1)}, #{nth($color-range, 2)} 2000%);
		background: radial-gradient(33% 33%, circle closest-side, #{nth($color-range, 1)}, #{nth($color-range, 2)} 2000%);
	}
      
      @else {
	  background-color: nth($color-range, 1);
		background: -webkit-radial-gradient(#{$type}, #{nth($color-range, 1)}, #{nth($color-range, 2)} 100%);
		background: -moz-radial-gradient(#{$type}, #{nth($color-range, 1)}, #{nth($color-range, 2)} 100%);
		background: -ms-radial-gradient(#{$type}, #{nth($color-range, 1)}, #{nth($color-range, 2)} 100%);
		background: radial-gradient(#{$type}, #{nth($color-range, 1)}, #{nth($color-range, 2)} 100%);
	 }

}


