@font-face {
  font-family: "Lalandia";
  src: url('/css/fonts/Lalandia.eot');
  src: url('/css/fonts/Lalandia.eot?#iefix') format('eot'),
    url('/css/fonts/Lalandia.woff') format('woff'),
    url('/css/fonts/Lalandia.ttf') format('truetype'),
    url('/css/fonts/Lalandia.svg#Lalandia') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"], %icon-font {
	font-family: 'Lalandia';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-25procent:before { content: "\E001" }
.icon-adult:before { content: "\E002" }
.icon-arrow-down:before { content: "\E003" }
.icon-arrow-left:before { content: "\E004" }
.icon-arrow-right:before { content: "\E005" }
.icon-arrow-up:before { content: "\E006" }
.icon-baby:before { content: "\E007" }
.icon-booking-check:before { content: "\E008" }
.icon-booking-house:before { content: "\E009" }
.icon-booking-people:before { content: "\E00A" }
.icon-booking-pictures:before { content: "\E00B" }
.icon-booking-plan:before { content: "\E00C" }
.icon-bullet-arrow:before { content: "\E00D" }
.icon-calendar:before { content: "\E00E" }
.icon-check:before { content: "\E00F" }
.icon-child:before { content: "\E010" }
.icon-clean:before { content: "\E011" }
.icon-clock:before { content: "\E012" }
.icon-close:before { content: "\E013" }
.icon-closing:before { content: "\E014" }
.icon-cookie:before { content: "\E015" }
.icon-culture:before { content: "\E016" }
.icon-edit:before { content: "\E017" }
.icon-facebook:before { content: "\E018" }
.icon-handicap:before { content: "\E019" }
.icon-heart:before { content: "\E01A" }
.icon-house:before { content: "\E01B" }
.icon-info:before { content: "\E01C" }
.icon-infobaby:before { content: "\E01D" }
.icon-infohouse:before { content: "\E01E" }
.icon-instagram:before { content: "\E01F" }
.icon-label-hand:before { content: "\E020" }
.icon-link-left:before { content: "\E021" }
.icon-link-right:before { content: "\E022" }
.icon-mail:before { content: "\E023" }
.icon-menu:before { content: "\E024" }
.icon-minus:before { content: "\E025" }
.icon-money:before { content: "\E026" }
.icon-newsletter:before { content: "\E027" }
.icon-night:before { content: "\E028" }
.icon-phone:before { content: "\E029" }
.icon-pin:before { content: "\E02A" }
.icon-play:before { content: "\E02B" }
.icon-plus:before { content: "\E02C" }
.icon-rabat:before { content: "\E02D" }
.icon-ring:before { content: "\E02E" }
.icon-sad:before { content: "\E02F" }
.icon-search:before { content: "\E030" }
.icon-slide:before { content: "\E031" }
.icon-slidedown:before { content: "\E032" }
.icon-slideheight:before { content: "\E033" }
.icon-small-arrow-left:before { content: "\E034" }
.icon-small-arrow-right:before { content: "\E035" }
.icon-thumbs-up:before { content: "\E036" }
.icon-wifi:before { content: "\E037" }
.icon-youtube:before { content: "\E038" }
.icon-z-location:before { content: "\E039" }
