﻿input[type=text],
input[type=number],
input[type=search],
input[type=password],
input[type=date],
input[type=email],
textarea {
    font-weight: 300;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    border-radius: 1.25em;
    height: 2.375em;
    color: $black;
    padding: 0 0.9375em;
    outline: none;
    margin-top: 10px;

    &.large {
        font-size: 22px;
        width: auto;
    }

    &.grey {
        color: $dark-grey;
        // background: darken($bg-grey, 3%);
        background: darken($booking-grey, 3%);

        &::-webkit-input-placeholder {
            color: $dark-grey;
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: $dark-grey;
        }

        &::-moz-placeholder { /* Firefox 19+ */
            color: $dark-grey;
        }

        &:-ms-input-placeholder {
            color: $dark-grey;
        }
    }

    &.quick-input {
        display: block;
        font-size: 12px;
        line-height: 18px;
        min-height: 18px;
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
        margin: 0 30px 0 0;
        padding: 9px 15px;
        height: auto;
        border-radius: 18px;

        @include medium-up {
            font-size: 14px;
            padding: 15px;
            border-radius: 30px;
        }

        &::-webkit-input-placeholder {
            color: $dark-grey;
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: $dark-grey;
        }

        &::-moz-placeholder { /* Firefox 19+ */
            color: $dark-grey;
        }

        &:-ms-input-placeholder {
            color: $dark-grey;
        }
    }

    &.qty {
        border-radius: $radius;
        width: auto;
        display: inline-block;
        background: rgba($black, 0.2);
        color: $white;
        font-weight: 700;
        text-align: center;
        width: 50px;
        vertical-align: middle;
        margin: 0;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield;
}

.input-wrapper {
	position: relative;
	&.tight {
		display: inline-block;
	}
    &.fixed {
        width: 200px;
        margin: auto;
    }
	> i {
		position: absolute;
		right: 15px;
		top: 50%;
        transform: translateY(-50%);
		font-size: 11px;
		color: #363636;
		pointer-events: none;
	}
}


textarea {
	padding: 0.9375em;
	resize: none;
	height: 75px;
}

input[type=search] {
	padding-left: 40px;

	+ i {
		@include transition(color .3s ease-in-out);
		position: absolute;
		left: 35px;
		top: 15px;
		color: $black;
	}
}

select {
    background-color: white;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    min-height: 18px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    margin: 0 30px 0 0;
    padding: 9px 15px;
    height: auto;
    border-radius: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $black;
    outline: none;
    border: 0;
    box-shadow: 0 2px 0 darken($light-grey, 15%);
    background-image: url('/assets/gfx/arrow-down.svg');
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: calc(100% - 15px) center;

    &.grey {
        background-color: darken($booking-grey, 3%);
        box-shadow: none;
    }

    .quick-book & {
        @include medium-up {
            font-size: 15px;
            padding: 15px;
            border-radius: 30px;
        }
    }
}

@include medium-up {
	.collapse.inputs {
		input, .selectric {
			margin: 0;
		}
		margin-bottom: $column-gutter/2 !important;
		> .column, > .columns {
            padding-left: $column-gutter/3;
			&:first-child {
				padding-right: $column-gutter/3;
                padding-left: 0;
			}
		}
	}
}