.valign-center {
    display: table;
    position: absolute;
    height: 100%;
    padding-left: 70px;
    width: 95%;
    width: calc(100% - 2rem);

    &__inner {
        display: table-cell;
        vertical-align: middle;
    }
}


