﻿.specialRateCalendar {
    position: relative;

    .title {
        font-weight: 100 !important;
        font-size: rem-calc(18);
    }

    .calendar-wrapper {
        background-color: white;
        padding: rem-calc(20);

        h2{
            color: black;
            margin: rem-calc(20) 0;
        }

        @include medium-up {
            border-radius: $radius;
        }

        @include small-only {
            padding-top: rem-calc(0);
        }
    }

    ul {
        list-style: none;
        @include gradient($black-gradiant, 'radial');
        @extend .black;
        @extend .panel;
        //height: rem-calc(100);
        padding-left: 0;
        overflow: hidden;
        //margin:0;
        padding: 0;


        @include medium-up {
            border-radius: $radius;
        }

        @include small-only {
            margin: 0;
        }

        li {
            //width: 20%;
            margin-top: 0;

            @include small-only {
                width: 100%;
            }

            &:after {
                content: "";

                @include small-only {
                    background: $grey;
                    height: 1px;
                    display: block;
                }
            }

            button {
                @extend .panel;
                margin-top: 0;
                margin-bottom: 0;
                //background-color:white;
                color: $white;
                width: 100%;
                border: none;
                border-radius: 0;
                //margin-bottom: rem-calc(40);
                @include small-only {
                    padding-top: rem-calc(10);
                    padding-bottom: rem-calc(10);
                }

                &:hover {
                    @extend .panel;
                    @extend .yellow;

                    @include small-only {
                        padding-top: rem-calc(10);
                        padding-bottom: rem-calc(10);
                    }
                }
            }
        }
    }
}

.specialRateTabs5 {

    li {
        width: 20%;
    }
}
.specialRateTabs4 {

    li {
        width: 25%;
    }
}

.specialRateTabs3 {

    li {
        width: 33%;
    }
}




.specialRateCalendar li button.active {
    @extend .panel;
    @extend .yellow;

    @include small-only {
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(10);
    }
}

.specialRateCalendar .tool-tip {
    display: block;
    position: absolute;
    opacity: 1;
}

.sr-subtitle {

    @include medium-only {
        display: none;
    }
}

.fp_border{
  
        border-right:solid 1px $grey;
   
}

.specialRateCalendar_bodytext{
    margin-bottom: 20px !important; 
    @include medium-up {
        margin-bottom: 0 !important;
    }
}