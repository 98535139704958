﻿/*.gsc-input {
    input {
        background: none !important;
    }
}*/

.searchbar-hide {
    display: none;
}

.searchbar-show {
    display: block;
}

.searchbar-wrapper {
    display: inline-block;
    position: relative;

    @include small-only {
        width: 80%;
        margin-bottom: 5px;
    }

    @include medium-up {
       // display: none;
    }
}

.search-btn {
    display: inline;

    i {
        color: $white;
        background: $dark-grey;
        border-radius: 50%;
        padding: 10px;
        font-size: 12px;
        cursor: pointer;
        margin-left: rem-calc(20);
    }

    &.search-active {
        i {
            //display: inline-block;
            margin-left: 10px;
            //transform: scaleX(-1);
        }
    }
}

input.search-input {
    width: 100%;
    height: 30px;
    padding: 0 20px;
    margin-top: 0;
    background: #d2d2d2;


    @include medium-up {
        width: 22vw;
        background: #eff1f3;
    }

    @include large-up {
        width: 250px;
    }

    &::placeholder {
        text-align: center;
    }
}

.search-clear {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:before,
    &:after {
        content: "";
        height: 10px;
        width: 2px;
        background-color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }

    &:after {
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }
}

/*input.gsc-search-button-v2, input.gsc-search-button-v2:hover, input.gsc-search-button-v2:focus {
    background-color: $dark-grey !important;
    //background-image: url(/assets/gfx/search-icon.svg) !important;
    background-size: 100% 100%;
    border: 0 none !important;
    margin-top: 5px !important;
    border-radius: 18px !important;
    padding: 10px !important;
    height: 13px !important;
    width: 13px !important;
    box-sizing: content-box;
}
.gsc-search-button button {
    background: #363636;
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    width: 32px;
    height: 32px;
}


.gscb_a {
    position: relative;
    top: 5px;
    padding-right: rem-calc(10) !important;
    color: $dark-grey !important;
}

.gscb_a:hover, .gscb_a:focus {

    color: $grey !important;
}


.gsc-input-box {
    border: 0 none !important;
    border-radius: 2.25em !important;
    box-sizing: border-box !important;
    color: $black !important;
    font-weight: 300 !important;
    height: rem-calc(30) !important;
    outline: 0 none !important;
    //padding: 0 0.9375em !important;
    background: $lightest-grey !important;
    margin-left: rem-calc(10) !important;
    width: rem-calc(150) !important;


    @include large-up {
        width: rem-calc(250) !important;
    }
}



.gsc-selected-option-container {
    width: 100% !important;
    box-sizing: content-box;
}



.gsc-input {
    @include large-up {
        max-width: rem-calc(300) !important;
    }
}

input.gsc-input {
    padding: 0 0.9375em !important;
    width: 100% !important;
    max-width: rem-calc(300);
    //line-height: rem-calc(34) !important;
}

.gsc-input-box {
    border: 0 none !important;
    outline: 0 none !important;
}

input.gsc-input, .gsc-input-box, .gsc-input-box-hover, .gsc-input-box-focus {
    border: 0 none !important;
    outline: 0 none !important;
}


//results
.gs-result .gs-title, .gs-result .gs-title * {
    color: $black !important;
    text-decoration: none !important;
    text-align: left !important;
    
    @include header-font;
    margin-bottom: rem-calc(10) !important;
}

.gsc-webresult.gsc-result, .gsc-results .gsc-imageresult {
   margin-bottom: rem-calc(20) !important;
}


.gs-webresult.gs-result a.gs-title:link, .gs-webresult.gs-result a.gs-title:link b, .gs-imageresult a.gs-title:link, .gs-imageresult a.gs-title:link b {
    color: $black !important;
    @include header-font;
}


.gs-webresult div.gs-visibleurl, .gs-imageresult div.gs-visibleurl {
    color: $ak_blue !important;
    @include header-font;
}

.gsc-control-cse .gsc-table-result {
    @include prim-font;
    font-family: 'roboto', sans-serif !important;
}

.gsc-result .gs-title {
    height: 1.3em !important;
}

.gs-image-box.gs-web-image-box.gs-web-image-box-landscape {
    display: none !important;
}

.gs-image-box.gs-web-image-box.gs-web-image-box-portrait {
    display: none !important;
}

.gs-webresult .gs-snippet, .gs-imageresult .gs-snippet, .gs-fileformattype {
    color: $black !important;
    padding-left: rem-calc(10);
}

.gsc-cursor-current-page {
    border-color: none !important;
    background-color: $dark-grey !important;
    color: $white !important;
    //padding:rem-calc(6) rem-calc(10);
    //border-radius:50%;
}

.gsc-results .gsc-cursor-box .gsc-cursor-page {
    border-color: none !important;
    background-color: $light-grey !important;
    //background-color: $black !important;
    color: $black !important;
    //padding:rem-calc(6) rem-calc(10);
    border-radius: 50%;
    height: rem-calc(30);
    width: rem-calc(30);
    line-height: rem-calc(30);
    text-align: center;
    display: inline-block !important;
    text-decoration: none !important;
    margin-top: rem-calc(20) !important;
}

.gsc-results .gsc-cursor {
    display: block !important;
    text-align: center !important;
}

.cse .gsc-control-cse, .gsc-control-cse {
    background-color: rgba($white, 0.8) !important;
    border: 0 none !important;
}

.gsc-webresult.gsc-result, .gsc-results .gsc-imageresult {
    background-color: transparent !important;
    border: 0 none  !important;
}
//forslag
.gsc-completion-container {
    background: $light-grey !important;
    border: 0 none !important;
    color: $black !important;
    @include prim-font;
    font-size: rem-calc(12) !important;
}

//no results
.gs-no-results-result .gs-snippet, .gs-error-result .gs-snippet {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 20px 0px !important;
    padding-top: 20px 0px !important;
    @include header-font;
    font-weight: bold;
    font-size: rem-calc(20);
}

//page search
.inspiration-block {
    .gsc-input-box {
        background: $light-grey !important;
        width: rem-calc(250) !important;
    }

 

    td.gsc-search-button {
        left: 280px;
        position: absolute;
        top: 7px;
    }
}*/



//offcanvas search
.top-tools {
    .gsc-input-box {
        background: $light-grey !important;
        margin-left: 0 !important;
        width: rem-calc(200) !important;
    }
}
