﻿
.cookie-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: rem-calc(35);
    background: rgba($black, 0.75);
    text-align: left;
    text-decoration: none;

    @include small-only {
        z-index: 100;
    }

    .no-touch & {
        text-decoration: none;
    }

    p {
        font-weight: 100 !important;

        @include medium-up {
            margin: 0;
        }
    }
}

.lang-box {
    @include small-only {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 2000;
    }
}
.cookie-container__content {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
}
.cookie-icon {
    margin-right: rem-calc(60);

    .icon-cookie {
        &:before {
            font-size: 115px;
        }
    }

    @include small-only {
        margin-right: rem-calc(20);
        .icon-cookie {

            &:before {
                font-size: 75px;
            }
        }
    }
}

.cookie-button {
    margin-top: 20px;
    cursor: pointer;

    button {
        margin: 0;
    }

    @include small-only {
        button {
            width: 100%;
        }
    }
}

.hide-cookie {
    display: none !important;
}

.cookieconsent-container {
    background-color: $white;
    color: $black;
    padding: 30px;

    @include medium-up {
        padding: 50px;
    }

    @media screen and (min-width: 1230px) {
        border-radius: 8px;
    }

   
}

.cookieconsent-content {
    max-width: 800px;
    margin: 0 auto;

    button {

        @include medium-up {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.cookieconsent-succes,
.cookieconsent-succes__allCookies,
.cookieconsent-succes__functionalCookies {
    display: none;
    a {
        color: $black;
        text-decoration: underline;
    }
}

.cookie-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:before,
    &:after {
        content: "";
        display: block;
        height: 100%;
        width: 2px;
        position: absolute;
        top: 0;
        left: 50%;
        background-color: $white;
        transform: rotate(45deg);

    }
    &:after {
        transform: rotate(-45deg)
    }
}
